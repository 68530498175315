const string_utilities = {
    methods: {
        string_concat () {
            // (separator, component1, component2, ...)
            if(arguments.length < 2) return ""
            const separator = arguments[0]
            const components = Array.from(arguments).slice(1).flat()
            return components.filter(component => !!component).join(separator)
        },
        address_block_line (line, address) {
            if(line === 1) {
                return this.string_concat(", ", address.line1, address.line2)
            } else if(line === 2) {
                return this.string_concat(", ", address.city, this.string_concat(" ", address.state, address.zip), address.country)
            }
            return ""
        },
        address_one_line (address) {
            return this.string_concat(", ", this.address_block_line(1, address), this.address_block_line(2, address))
        },
        full_name (record) {
            return this.string_concat(" ", record.first_name, record.middle_name, record.last_name)
        }
    },
    computed: {
        app_version () {
            // eslint-disable-next-line
            if(process.env.VUE_APP_VERSION) return process.env.VUE_APP_VERSION
            return ""
        },
        current_year () {
            return this.$dayjs().format("YYYY")
        }
    }
}

export default string_utilities
