<template>
    <data-table
        id="tasks-list"
        entity-name="task:::tasks"
        :columns="visible_columns"
        :rows="filtered_rows"
        :sort-fields="state.sort_by || []"
        :sort-directions="state.sort_dir || []"
        :search="state.search"
        :total="state.total"
        :found="state.found"
        :current-page="state.page"
        :total-pages="1"
        :can-add="canAdd"
        :searchable="false"
        :show-totals="false"
        @sort="sort_by"
        @add="$emit('add')"
        @click="navigate"
    >
        <template
            v-slot:summary="{ row }"
        >
            <h6>{{ row.property_name }}</h6>
        </template>
        <template
            v-slot:tbody="{ row, field }"
        >
            <div v-if="field === 'scheduled_at'">
                <form-toggle-input
                    :id="`deleted_at-${row.id}`"
                    name="deleted_at"
                    type="checkbox"
                    :value="!!row.deleted_at"
                    :required="false"
                    size="sm"
                    :class="{ 'text-error': task_is_overdue(row), 'text-gray': !!row.deleted_at }"
                    class="d-inline-block"
                    @input="toggle_task(row)"
                    @click.native.stop
                >
                    {{ row.scheduled_at | nibnut.date("MM/DD/YYYY @ hh:mm A", "", true) }}
                    <open-icon
                        v-if="task_is_overdue(row)"
                        glyph="exclamation-triangle"
                        class="ml-2"
                    />
                </form-toggle-input>
            </div>
            <div
                v-else-if="field === 'computed_initials'"
            >
                <figure
                    :title="row.name"
                    :data-initial="row.computed_initials"
                    :style="{ backgroundColor: row.color, color: color_for_background(row.color) }"
                    class="avatar avatar-sm mr-2"
                ></figure>
            </div>
            <div
                v-else-if="field === 'task_action_id'"
                :class="{ 'text-error': task_is_overdue(row), 'text-gray': !!row.deleted_at }"
            >
                {{ row.description || row.property_name }}
                <div
                    v-if="!!task_action_by_id(row.task_action_id).name"
                    class="text-small text-gray"
                >
                    {{ task_action_by_id(row.task_action_id).name }}
                </div>
            </div>
            <base-link
                v-else-if="(field === 'property_name') && !!row.property_name"
                :id="task_proxy_link_id(row)"
                :href="{ name: 'property.edit', params: { id: row.property_uuid } }"
                :class="{ 'text-error': task_is_overdue(row), 'text-gray': !!row.deleted_at }"
                @click.native.stop
            >
                {{ row.property_name }}
                <open-icon v-if="row.is_crucial" glyph="exclamation" class="ml-4" />
            </base-link>
            <div
                v-else-if="field === '_buttons'"
                class="columns d-flex-no-wrap"
            >
                <quick-delete-button
                    v-if="!row.deleted_at"
                    class="column ml-2"
                    @confirmed="delete_task(row)"
                />
                <default-button
                    flavor="link"
                    size="sm"
                    class="column"
                    @click.prevent.stop="$emit('edit', row)"
                >
                    <open-icon glyph="pencil-alt" />
                </default-button>
            </div>
            <span
                v-else
                :class="{ 'text-error': task_is_overdue(row) }"
            >
                {{ row[field] }}
            </span>
        </template>
    </data-table>
</template>

<script>
import { is_data_table_source, ui_utilities } from "@/nibnut/mixins"
import { addl_misc_utilities } from "@/custom/mixins"

import {
    FormToggleInput,
    BaseLink,
    DefaultButton,
    OpenIcon
} from "@/nibnut/components"
import {
    QuickDeleteButton
} from "@/custom/components"

export default {
    name: "TaskList",
    mixins: [is_data_table_source, ui_utilities, addl_misc_utilities],
    components: {
        FormToggleInput,
        BaseLink,
        DefaultButton,
        OpenIcon,
        QuickDeleteButton
    },
    watch: {
        tasks: "refresh"
    },
    methods: {
        refresh () {
            if(!this.showProperty || !this.showContact) {
                this.set_state_values({
                    ...this.providedState,
                    total: this.rows.length,
                    found: this.rows.length
                })
            } else {
                this.set_state_values({
                    total: this.rows.length,
                    found: this.rows.length
                })
            }
        },
        sort_by (field, sort) {
            const sort_by = []
            const sort_dir = []
            const fields = Array.isArray(field) ? field : [field]
            const directions = Array.isArray(sort) ? sort : [sort]
            fields.forEach((field, index) => {
                const columns = this.columns[field] ? this.columns : this.visible_columns
                const column = columns[field]
                if(!column || (column.sort === false)) return // false is not sortable
                const direction = (index < directions.length) ? directions[index] : directions[directions.length - 1]

                Object.keys(columns).forEach(column_id => {
                    if(column_id === field) {
                        columns[column_id].sort = direction || "asc"
                        sort_by.push(field)
                        sort_dir.push(columns[column_id].sort)
                    } else if(columns[column_id].sort !== false) columns[column_id].sort = null
                })
            })

            this.$emit("sort", sort_by, sort_dir)
        },
        task_proxy_link_id (task) {
            return `task-property-link-${task.id}`
        },
        task_is_overdue (task) {
            return !!task && !task.deleted_at && !!task.scheduled_at && !this.$dayjs().isBefore(this.$dayjs(task.scheduled_at))
        },
        toggle_task (task) {
            if(!task) return
            if(task.deleted_at) {
                this.$store.dispatch(
                    "RECORD_RESTORE",
                    {
                        entity: "task",
                        id: task.id
                    }
                ).then(() => {
                    this.refresh()
                }).catch(this.receive_error)
            } else {
                this.$store.dispatch(
                    "RECORD_DELETE",
                    {
                        entity: "task",
                        id: task.id
                    }
                ).then(() => {
                    this.refresh()
                }).catch(this.receive_error)
            }
        },
        delete_task (task) {
            this.$store.dispatch(
                "RECORD_DELETE",
                {
                    entity: "task",
                    id: task.id,
                    data: { force: true }
                }
            ).then(() => {
                this.refresh()
            }).catch(this.receive_error)
        },
        navigate (task) {
            const link = document.getElementById(this.task_proxy_link_id(task))
            if(link && link.click) link.click()
        }
    },
    computed: {
        state_identifier () {
            return this.id || this.$route.name
        },
        fields () {
            return ["fieldset::list"]
        },
        visible_columns () {
            const columns = { ...this.columns }
            if(!this.showProperty) delete columns.property_name
            if(!this.showContact) delete columns.contact_name
            return columns
        },
        rows () {
            return this.tasks
        },
        filtered_rows () {
            return this.rows
        }
    },
    props: {
        id: {
            type: String,
            default: ""
        },
        providedState: {
            type: Object,
            required: true
        },
        tasks: {
            type: Array,
            default () {
                return []
            }
        },
        canAdd: {
            type: Boolean,
            default: false
        },
        showProperty: {
            type: Boolean,
            default: true
        },
        showContact: {
            type: Boolean,
            default: true
        }
    },
    data () {
        return {
            columns: {
                scheduled_at: { label: "Complete By", sort: "asc", type: "amount", head_class: "col-date", cell_class: "col-date" },
                computed_initials: { label: " ", sort: null, type: "alpha", head_class: "col-avatar", cell_class: "col-avatar" },
                task_action_id: { label: "Action", sort: null, type: "alpha" },
                property_name: { label: "Property", sort: false },
                contact_name: { label: "Contact", sort: null, type: "alpha", head_class: "col-auto no-wrap", cell_class: "col-auto no-wrap" },
                _buttons: { label: " ", sort: false, head_class: "col-auto no-wrap", cell_class: "col-auto no-wrap" }
            }
        }
    }
}
</script>
