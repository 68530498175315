<template>
    <div
        v-if="!isRoot && (!!back_info.title || (!!forced && !!forced.title) || (!!fallback && !!fallback.title))"
        class="text-gray page-back"
    >
        <base-link
            v-if="(!!forced && !!forced.title)"
            :href="forced.href"
        >
            <open-icon
                glyph="angle-left"
                class="mr-2"
            />
            {{ forced.title }}
        </base-link>
        <base-link
            v-else-if="!!back_info.title"
            :href="back_info.path || back_info.id"
            @click.native.prevent.stop="pop(true)"
        >
            <open-icon
                glyph="angle-left"
                class="mr-2"
            />
            {{ back_info.title }}
        </base-link>
        <base-link
            v-else-if="(!!fallback && !!fallback.title)"
            :href="fallback.href"
        >
            <open-icon
                glyph="angle-left"
                class="mr-2"
            />
            {{ fallback.title }}
        </base-link>
    </div>
</template>

<script>
import { mapGetters } from "vuex"

import BaseLink from "@/nibnut/components/Links/BaseLink"
import OpenIcon from "@/nibnut/components/OpenIcon"

export default {
    name: "PageBack",
    components: {
        BaseLink,
        OpenIcon
    },
    mounted () {
        // window.addEventListener("popstate", this.pop_history)
        this.reset()
    },
    beforeDestroy () {
        // window.removeEventListener("popstate", this.pop_history)
    },
    watch: {
        title: "title_changed"
    },
    methods: {
        reset () {
            // if(this.isRoot) this.$store.dispatch("HISTORY_POP", { all: true })
            this.title_changed()
        },
        title_changed () {
            if(this.$router._router_history.length && (this.$router._router_history[this.$router._router_history.length - 1].path === this.$route.path)) {
                this.$set(this.$router._router_history[this.$router._router_history.length - 1], "title", this.title)
                // console.log("BACK", this.$router._router_history)
            }
            // if(this.title) this.$store.dispatch("HISTORY_PUSH", { title: this.title })
        },
        pop (go_back) {
            // this.$store.dispatch("HISTORY_POP", {})
            if(go_back) this.$router.go(-1)
        }
        /*
        pop_history () {
            console.log("pop_history")
        }
        */
    },
    computed: {
        ...mapGetters(["history_back_info"]),
        back_info () {
            return (this.$router._router_history.length > 1) ? this.$router._router_history[this.$router._router_history.length - 2] : { path: "", title: "" }
            // return this.history_back_info()
        }
    },
    props: {
        title: {
            type: String,
            required: true
        },
        isRoot: {
            type: Boolean,
            default: false
        },
        forced: {
            type: Object, // { title, href: (string | object) }
            default: null
        },
        fallback: {
            type: Object, // { title, href: (string | object) }
            default: null
        }
    }
}
</script>
