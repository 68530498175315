<template>
    <button
        :type="type"
        role="button"
        :disabled="disabled"
        :aria-label="label||false"
        v-on="listeners"
        :class="{ [`btn-${flavor}`]: true, [`btn-${color}`]: true, [`btn-${size}`]: true, 'btn-block': block, active }"
        class="btn"
        @click="clicked"
    >
        <slot></slot>
    </button>
</template>

<script>
export default {
    name: "BaseButton",
    methods: {
        clicked (event) {
            event.target.closest("button").blur()
            this.$emit("click", event)
        }
    },
    computed: {
        listeners () {
            const { click, ...listeners } = this.$listeners
            return listeners
        }
    },
    props: {
        label: {
            type: String,
            validator: prop => !!prop
        },
        type: {
            type: String,
            default: "button"
        },
        flavor: {
            type: String,
            validator: prop => !prop || prop.match(/^(link|normal)$/i),
            default: "normal"
        },
        color: {
            type: String,
            validator: prop => !prop || prop.match(/^(primary|secondary|regular|error|warning|success|clear|light|gray|text|chameleon)$/i),
            default: "regular"
        },
        size: {
            type: String,
            validator: prop => !prop || prop.match(/^(xs|sm|md|lg)$/i),
            default: "md"
        },
        block: {
            type: Boolean,
            default: false
        },
        active: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        }
    }
}
</script>
