<template>
    <div class="reminders">
        <label class="form-label">
            <slot name="label">{{ translate("Reminder(s)") }}</slot>
            <default-button
                flavor="link"
                @click.prevent.stop="add_reminder"
            >
                <open-icon glyph="plus-circle" />
            </default-button>
        </label>
        <table class="table">
            <tbody>
                <tr
                    v-for="(reminder_id, index) in value"
                    :key="reminder_id"
                >
                    <td class="column">
                        <div class="d-flex">
                            <form-date-time-input
                                v-if="all_reminder_ids.indexOf(reminder_id) < 0"
                                :id="`custom-reminder-${index}`"
                                name="reminder_id"
                                :value="reminder_id"
                                position="right"
                                :required="false"
                                @input="save_reminder_at_index(index, $event)"
                            />
                            <form-dropdown
                                v-else
                                :id="reminder_id"
                                name="reminder_id"
                                :value="reminder_id"
                                :options="available_reminders(reminder_id)"
                                :empty-value="null"
                                :required="true"
                                @input="save_reminder_at_index(index, $event)"
                            />
                            <label v-if="all_reminder_ids.indexOf(reminder_id) >= 0" class="form-label ml-2">{{ translate("before") }}<span v-if="scheduled_time(reminder_id)" class="text-gray text-small ml-4">({{ scheduled_time(reminder_id) | nibnut.date("MM/DD/YYYY @ hh:mm A") }})</span></label>
                        </div>
                    </td>
                    <td class="column col-auto text-right">
                        <default-button
                            flavor="link"
                            color="error"
                            @click.prevent.stop="remove_reminder_at_index(index)"
                        >
                            <open-icon glyph="times" />
                        </default-button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import addl_misc_utilities from "@/custom/mixins/AddlMiscUtilities"

import FormDropdown from "@/nibnut/components/Inputs/FormDropdown"
import FormDateTimeInput from "@/nibnut/components/Inputs/FormDateTimeInput"
import DefaultButton from "@/nibnut/components/Buttons/DefaultButton"
import OpenIcon from "@/nibnut/components/OpenIcon"

const presets = {
    "72h": { id: "72h", name: "72 hours" },
    "24h": { id: "24h", name: "24 hours" },
    "2h": { id: "2h", name: "2 hours" },
    "30m": { id: "30m", name: "30 minutes" },
    custom: { id: "custom", name: "Custom..." }
}

export default {
    name: "TaskRemindersInput",
    mixins: [addl_misc_utilities],
    components: {
        FormDropdown,
        FormDateTimeInput,
        DefaultButton,
        OpenIcon
    },
    methods: {
        save (value, field) {
            this.$emit("input", value, field)
        },
        available_reminders (reminder_id = null) {
            const selected = this.value
            return this.all_reminders.filter(reminder => {
                return (selected.indexOf(reminder.id) < 0) || (!!reminder_id && (reminder.id === reminder_id))
            })
        },
        add_reminder () {
            const available_reminders = this.available_reminders()
            this.save_reminder_at_index(this.value.length, available_reminders.length ? available_reminders[0].id : "custom")
        },
        save_reminder_at_index (index, reminder_id) {
            const reminders = [...this.value]
            if(reminder_id === "custom") reminder_id = ""
            else if(this.$dayjs.isDayjs(reminder_id)) reminder_id = reminder_id.format("YYYY-MM-DD HH:mm:ss")
            reminders[index] = reminder_id
            this.save(reminders, "reminders")
        },
        remove_reminder_at_index (index) {
            const reminders = [...this.value]
            reminders.splice(index, 1)
            this.save(reminders, "reminders")
        },
        scheduled_time (reminder_id) {
            if(!this.rootDate) return ""
            const date = this.$dayjs(this.rootDate)
            const matches = reminder_id.match(/^(\d+)(d|h|m)$/)
            if(matches) {
                const amount = parseInt(matches[1]) || 0
                let units = matches[2]
                switch (matches[2]) {
                case "d":
                    units = "days"
                    break
                case "h":
                    units = "hours"
                    break
                case "m":
                    units = "minutes"
                    break
                }
                return date.subtract(amount, units)
            }
            return ""
        }
    },
    computed: {
        all_reminder_ids () {
            return Object.keys(presets)
        },
        all_reminders () {
            return Object.values(presets)
        }
    },
    props: {
        value: {
            type: Array,
            default () {
                return []
            }
        },
        rootDate: {
            default: null
        }
    }
}
</script>
