import AdHocLetterDialog from "@/custom/dialogs/AdHocLetterDialog"
/*
<ad-hoc-letter-dialog
    :show.sync="composing_letter"
    :topic-type="composing_letter_topic_type"
    :topic="composing_letter_topic"
    :address-id="composing_letter_address_id"
    :address-one-liner="composing_letter_address"
/>
*/

const sends_letters = {
    components: {
        AdHocLetterDialog
    },
    methods: {
        adhoc_letter_compose (topic_type, topic, address_id, one_liner = "") {
            this.composing_letter_topic_type = topic_type
            this.composing_letter_topic = topic
            this.composing_letter_address_id = address_id
            this.composing_letter_address = one_liner
            this.composing_letter = true
        }
    },
    computed: {
    },
    data () {
        return {
            composing_letter_topic_type: "",
            composing_letter_topic: {},
            composing_letter_address_id: 0,
            composing_letter_address: "",
            composing_letter: false
        }
    }
}

export default sends_letters
