<template>
    <component :is="block ? 'div' : 'span'">
        <default-button
            :id="`${topicClass}-journal-btn`"
            :color="!loading && !!state.total ? 'primary' : 'regular'"
            :title="translate('Journal')"
            :size="size"
            :block="block"
            :waiting="loading"
            :disabled="disabled"
            @click.prevent.stop="displayed = !displayed"
        >
            <open-icon glyph="th-list" />
            {{ translate("Journal") }}
        </default-button>

        <modal-dialog
            :id="`${topicClass}-journal-dialog`"
            :show.sync="displayed"
            class="journal-dialog modal-lg"
        >
            <template v-slot:title>
                <span class="h5">{{ translate("Journal for {name}", { name: topicName || topic.name }) }}</span>
            </template>

            <div
                class="journal-master-detail"
                :class="{ active: !edited_record }"
            >
                <div class="journal-master">
                    <data-table
                        id="journal"
                        :columns="visible_columns"
                        :rows="filtered_rows"
                        :sort-fields="state.sort_by"
                        :sort-directions="state.sort_dir"
                        :search="state.search"
                        :total="state.total"
                        :found="state.found"
                        :current-page="state.page"
                        :total-pages="nb_pages"
                        :can-add="true"
                        :show-head="false"
                        :searchable="!!state.total"
                        :search-placeholder="translate('Search...')"
                        :export-url="!!state.total ? export_url : ''"
                        @search="search"
                        @sort="sort_by"
                        @page="goto_page"
                        @add="add_log"
                        @click="toggle_edited_record"
                    >
                        <template
                            v-slot:controls
                        >
                            <form-dropdown
                                v-if="!!state.total"
                                id="filter"
                                name="filter"
                                :value="state.filter"
                                :options="filters"
                                :empty-value="0"
                                :empty-label="translate('All Entries')"
                                :required="false"
                                @input="filter('action', $event)"
                            />
                        </template>
                        <template
                            v-slot:tbody="{ row }"
                        >
                            <div
                                :class="{ active: !!edited_record && (edited_record.id === row.id) }"
                                class="tile tile-centered"
                            >
                                <div class="tile-content">
                                    <div class="tile-title">{{ (row.description || translate("New Note")) | nibnut.truncate(!!edited_record ? 90 : 180) }}</div>
                                    <small class="tile-subtitle d-flex">
                                        <span class="flex-variable">
                                            {{ row.acted_at | nibnut.date }}&nbsp;@&nbsp;{{ row.acted_at | nibnut.date("hh:mm A") }}&nbsp;&bull;&nbsp;{{ row.computed_actor_name }}
                                            <span v-if="icon_for_row(row) || (!!row.category_ids && !!row.category_ids.length)">
                                                &nbsp;&bull;&nbsp;
                                                <open-icon
                                                    v-if="icon_for_row(row)"
                                                    :glyph="icon_for_row(row)"
                                                    class="mr-2"
                                                />
                                                <span
                                                    v-for="category_id in row.category_ids"
                                                    :key="category_id"
                                                    class="chip"
                                                >
                                                    {{ term_name_by_id(category_id) }}
                                                </span>
                                            </span>
                                        </span>
                                        <span
                                            v-if="!!row.pinned_until"
                                            class="flex-static"
                                        >
                                            <default-button
                                                v-if="row.pinned_forever"
                                                flavor="link"
                                                size="sm"
                                                @click.prevent.stop="change_pin(row)"
                                                @click.native.stop
                                            >
                                                {{ translate("pinned forever") }}
                                            </default-button>
                                            <default-button
                                                v-else
                                                flavor="link"
                                                size="sm"
                                                @click.prevent.stop="change_pin(row)"
                                                @click.native.stop
                                            >
                                                {{ translate("pinned until") }} {{ row.pinned_until | nibnut.date("MM/DD/YYYY") }}
                                            </default-button>
                                        </span>
                                    </small>
                                </div>
                                <div
                                    :id="`popover-${row.id}`"
                                    :class="{ popover: quick_pin === row.id, ['popover-bottom']: quick_pin === row.id, active: quick_pin === row.id }"
                                    class="tile-action hover-disabled"
                                >
                                    <default-button
                                        :flavor="!!row.pinned_until ? 'normal' : 'link'"
                                        :color="!!row.pinned_until ? 'success' : 'regular'"
                                        @click.prevent="toggle_quick_pin(row)"
                                        @click.native.stop
                                    >
                                        <open-icon glyph="thumbtack" />
                                    </default-button>
                                    <div
                                        v-if="quick_pin === row.id"
                                        class="popover-container"
                                    >
                                        <ul
                                            v-if="!editing_pin"
                                            class="menu"
                                        >
                                            <li
                                                v-for="quick_pin in quick_pins"
                                                :key="quick_pin.value"
                                                class="menu-item"
                                            >
                                                <a
                                                    href="#"
                                                    @click.prevent.stop="pin_row(row, quick_pin.value)"
                                                >
                                                    {{ quick_pin.label }}
                                                </a>
                                            </li>
                                        </ul>
                                        <div v-else class="card">
                                            <div class="card-header">
                                                <div class="card-title h6 text-gray">{{ translate("Pin Until") }}</div>
                                            </div>
                                            <div class="card-body">
                                                <base-calendar
                                                    :min="$dayjs()"
                                                    :selection="edited_pinned_date"
                                                    size="sm"
                                                    @click="pin_row(edited_pin, $event)"
                                                    @click.native.stop
                                                />
                                            </div>
                                            <div class="card-footer">
                                                <default-button
                                                    flavor="link"
                                                    size="sm"
                                                    @click.prevent.stop="editing_pin = false"
                                                    @click.native.stop
                                                >
                                                    <open-icon glyph="arrow-left" /> {{ translate("Back") }}
                                                </default-button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </data-table>
                </div>
                <div class="journal-detail">
                    <div v-if="edited_record">
                        <div v-if="!!edited_record.read_only" v-html="edited_record.description" style="position: fixed;"></div>
                        <form-textbox
                            v-else
                            ref="editor"
                            id="description"
                            name="description"
                            v-model="edited_record.description"
                            size="full"
                            :editable="edited_record.action === constants('actions', 'MANUAL_NOTE').id"
                            :required="false"
                            :show-on-ready="true"
                            :saving="saving('description')"
                            :error="has_error('description')"
                            class="flex-variable"
                            @input="save"
                        >
                            <template v-slot:label>
                                <div class="columns">
                                    <div class="column flex-static">
                                        <div class="form-label">
                                            {{ translate("Categories") }}
                                        </div>
                                    </div>
                                    <div class="column flex-variable">
                                        <form-tag-input
                                            id="category_ids"
                                            name="category_ids"
                                            :value="edited_record.category_ids"
                                            data-source="term"
                                            :data-source-additional-data="term_autosuggest_data"
                                            :ad-hoc="true"
                                            :required="false"
                                            @input="save"
                                            @create="add_term"
                                        />
                                    </div>
                                </div>
                            </template>
                        </form-textbox>
                        <h6 v-if="!!edited_record.id" class="form-label label-sm text-center">
                            {{ edited_record.updated_at | nibnut.date }}&nbsp;@&nbsp;{{ edited_record.updated_at | nibnut.date("hh:mm A") }}&nbsp;&bull;&nbsp;{{ edited_record.computed_actor_name }}
                        </h6>
                        <div v-if="deletable" class="columns mt-2 flex-static">
                            <div v-if="deletable" class="column text-center">
                                <div v-if="confirming_deletion">
                                    {{ translate("Are you sure?") }}
                                    <default-button
                                        color="success"
                                        size="sm"
                                        class="mx-2"
                                        @click.prevent.stop="confirming_deletion = false"
                                    >
                                        {{ translate("Cancel") }}
                                    </default-button>
                                    <default-button
                                        color="error"
                                        size="sm"
                                        class="mx-2"
                                        @click.prevent.stop="delete_edited_record"
                                    >
                                        {{ translate("Delete") }}
                                    </default-button>
                                </div>
                                <default-button
                                    v-else
                                    flavor="link"
                                    color="error"
                                    :block="true"
                                    @click.prevent.stop="confirming_deletion = true"
                                >
                                    {{ translate("Delete") }}
                                </default-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </modal-dialog>
    </component>
</template>

<script>
import { mapGetters } from "vuex"
import orderBy from "lodash/orderBy"

import is_remote_data_table_source from "@/nibnut/mixins/IsRemoteDataTableSource"
import handles_saving from "@/nibnut/mixins/HandlesSaving"

import FormTextbox from "@/nibnut/components/Inputs/FormTextbox"
import FormTagInput from "@/nibnut/components/Inputs/FormTagInput"
import FormDropdown from "@/nibnut/components/Inputs/FormDropdown"
import DefaultButton from "@/nibnut/components/Buttons/DefaultButton"
import OpenIcon from "@/nibnut/components/OpenIcon"

export default {
    name: "JournalButton",
    mixins: [is_remote_data_table_source, handles_saving],
    components: {
        FormTextbox,
        FormTagInput,
        FormDropdown,
        DefaultButton,
        OpenIcon,
        ModalDialog: () => import("@/nibnut/components/ModalDialog/ModalDialog")
    },
    watch: {
        displayed: "reset",
        "topic.id": "refresh",
        topicClass: "refresh"
    },
    methods: {
        reset () {
            this.$store.dispatch(
                "FETCH_RECORDS",
                {
                    entity: "user",
                    query: {
                        sort_by: ["first_name", "last_name"],
                        sort_dir: ["asc", "asc"],
                        fields: ["fieldset::picker"]
                    }
                }
            ).then(response => {
                this.users = this.entity_records("user", response.record_ids)
            }).catch(error => {
                this.$error(error.message)
            })

            this.reset_state(true)
            this.refresh()
        },
        reset_state (force) {
            if(force || !this.stored_state) {
                this.edited_record = null
                this.set_state_values({})
            }
        },
        pre_load () {
            const state = {
                topic: this.topicClass,
                topic_id: this.topic.id,
                sort_by: ["pinned_until", "acted_at"],
                sort_dir: ["asc", "desc"],
                per_page: this.displayed ? 10 : 1
            }
            if(this.state.filter === null) state.filter = this.constants("actions", "MANUAL_NOTE").id
            this.set_state_values(state)
        },
        sort_rows (rows) {
            if(this.state.sort_by) {
                if(Array.isArray(this.state.sort_by)) return orderBy(rows, this.state.sort_by, this.state.sort_dir)
                return orderBy(rows, (row) => {
                    if(!row[this.state.sort_by]) {
                        if(this.state.sort_dir === "asc") return "999999999"
                        return "000000000"
                    }
                    return row[this.state.sort_by]
                }, this.state.sort_dir)
            }
            return rows
        },
        add_log () {
            this.$store.dispatch(
                "CREATE_RECORD",
                {
                    entity: this.entity,
                    data: {
                        topic_type: `App\\${this.topicClass}`,
                        topic_id: this.topic.id
                    }
                }
            ).then(record => {
                this.toggle_edited_record(record)
                this.refresh()
            }).catch(error => {
                this.$error(error.message)
            })
        },
        toggle_edited_record (row) {
            if(!!this.edited_record && !!row && (this.edited_record.id === row.id)) this.edited_record = null
            else this.edited_record = row
        },
        save_row (row, value, field) {
            if(row) {
                if(row[field] !== value) row[field] = value
                if(row.id) return this.save_field_for_record_id(this.entity, row.id, row[field], field)
            }
            return Promise.resolve()
        },
        save (value, field) {
            if(this.edited_record) return this.save_row(this.edited_record, value, field)
            return Promise.resolve()
        },
        add_term (label, field) {
            if(!label) return
            return this.$store.dispatch("CREATE_RECORD", {
                entity: "term",
                data: {
                    vocabulary: this.constants("vocabularies", "VOCABULARY_LOG_CATEGORIES").id,
                    name: label
                }
            }).then(term => {
                const ids = [...this.edited_record[field]]
                ids.push(term.id)
                this.save(ids, field)
            }).catch(error => {
                this.receive_error(error)
            })
        },
        term_name_by_id (id) {
            return (this.entity_record("term", id) || {}).name
        },
        toggle_quick_pin (row) {
            if(row.pinned_until) {
                this.quick_pin = false
                this.save_row(row, null, "pinned_until")
            } else this.change_pin(row)
        },
        change_pin (row) {
            if(this.quick_pin) this.quick_pin = false
            else this.quick_pin = row.id
        },
        pin_row (row, until) {
            if(until) this.save_row(row, until, "pinned_until").then(() => { this.quick_pin = false })
            else this.edit_pin(row)
        },
        edit_pin (row) {
            this.edited_pin = row
            this.edited_pinned_date = (row.pinned_until && !row.pinned_forever) ? this.$dayjs(row.pinned_until) : this.$dayjs()
            this.editing_pin = true
        },
        delete_edited_record () {
            if(this.edited_record && !!this.edited_record.id) {
                this.$store.dispatch(
                    "RECORD_DELETE",
                    {
                        entity: this.entity,
                        id: this.edited_record.id
                    }
                ).then(() => {
                    this.toggle_edited_record(null)
                    this.refresh()
                }).catch(this.receive_error).then(() => {
                    this.confirming_deletion = false
                })
            }
        },
        icon_for_row (row) {
            if(row.topic_type === "App\\Property") return "sign"
            if(row.topic_type === "App\\Person") return "address-book"
            if(row.topic_type === "App\\Task") return "calendar"
            return ""
        }
    },
    computed: {
        ...mapGetters(["entity_record"]),
        can_reload () {
            return !!this.profile_id && !!this.topic && !!this.topic.id
        },
        state_identifier () {
            return `${this.topicClass}-${this.topic.id}-journal`
        },
        fields () {
            return ["fieldset::default"]
        },
        filters () {
            const note_action_id = this.constants("actions", "MANUAL_NOTE").id
            return [
                { id: 0 - note_action_id, name: this.translate("Actions") },
                { id: note_action_id, name: this.translate("Notes") }
            ]
        },
        quick_pins () {
            const today = this.$dayjs()
            return [
                { label: this.translate("Forever"), value: this.constants("MAX_DATE") },
                { label: this.translate("1 week"), value: today.add(1, "week").format("YYYY-MM-DD") },
                { label: this.translate("2 weeks"), value: today.add(2, "weeks").format("YYYY-MM-DD") },
                { label: this.translate("3 weeks"), value: today.add(3, "weeks").format("YYYY-MM-DD") },
                { label: this.translate("1 month"), value: today.add(1, "month").format("YYYY-MM-DD") },
                { label: this.translate("Until..."), value: null }
            ]
        },
        deletable () {
            return this.edited_record && this.edited_record.id && !this.edited_record.read_only && ((this.edited_record.author_id === this.profile.id) || this.is_at_least_administrator)
        },
        term_autosuggest_data () {
            return { fields: ["id", "name"], vocabulary: this.constants("vocabularies", "VOCABULARY_LOG_CATEGORIES").id }
        },
        export_url () {
            const url = new URL(`https://${window.document.location.hostname}/export/${this.entity}/txt`)

            const search = []
            const state = {
                ...this.state,
                ...{
                    sort_by: ["acted_at"],
                    sort_dir: ["desc"]
                }
            }
            Object.keys(state).forEach((key) => {
                if((!key.match(/^(?:per_?)page$/i)) && state[key]) {
                    let value = state[key]
                    if(typeof value !== "string") value = JSON.stringify(value)
                    search.push(`${key}=${encodeURIComponent(value)}`)
                }
            })

            url.search = search.join("&")
            return url.href
        }
    },
    props: {
        topicClass: {
            type: String,
            required: true
        },
        topic: {
            type: Object,
            required: true
        },
        topicName: {
            type: String,
            default: ""
        },
        size: {
            type: String,
            validator: prop => !prop || prop.match(/^(xs|sm|md|lg)$/i),
            default: "md"
        },
        block: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            displayed: false,
            entity: "action_log",
            relation_ids: ["term"],
            columns: {
                updated_at: { label: "Date", sort: "desc", type: "amount" }
            },
            default_state: {
                per_page: 0,
                page: 1,
                sort_by: ["pinned_until", "acted_at"],
                sort_dir: ["asc", "desc"],
                filter_on: "action",
                filter: null,
                archived: false,
                search: "",
                total: 0,
                found: 0,
                topic: null,
                topic_id: 0,
                acted_by: 0
            },
            users: [],
            edited_record: null,
            confirming_deletion: false,
            quick_pin: false,
            edited_pin: null,
            edited_pinned_date: null,
            editing_pin: false
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.journal-dialog.modal-lg {
    & > .modal-container {
        min-width: 80vw;
        min-height: 70vh;

        & > .modal-body {
            /*
            flex: 1 0 auto;
            display: flex;
            flex-direction: column;
            */

            .journal-master-detail {
                flex: 1;
                display: flex;
                flex-wrap: wrap;
                margin-left: -$layout-spacing;
                margin-right: -$layout-spacing;

                & > .journal-master,
                & > .journal-detail {
                    flex: 1 1 50%;
                    max-width: 100%;
                    min-height: 100%;
                    padding-left: $layout-spacing;
                    padding-right: $layout-spacing;
                    transition: all 0.3s ease-in-out;
                }
                & > .journal-master {
                    .empty {
                        background: white;
                    }
                    .table {
                        table-layout:fixed;
                        td {
                            padding: 0;
                            .tile {
                                padding: $unit-2;
                                .tile-title {
                                    white-space: nowrap;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                }
                                .tile-subtitle {
                                    color: $gray-color;

                                    .chip {
                                        line-height: normal;
                                        height: auto;
                                        padding: 0.1rem 0.4rem;
                                    }
                                }
                                .tile-content {
                                    .btn.btn-sm {
                                        height: auto;
                                        padding-top: 0;
                                        padding-bottom: 0;
                                        line-height: normal;
                                        font-size: inherit;
                                    }
                                }
                                .tile-action {
                                    .btn-normal {
                                        i.la-thumbtack {
                                            transition: transform 0.3s ease-in-out
                                        }
                                        &.btn-success {
                                            i.la-thumbtack {
                                                transform: rotate(-45deg);
                                            }
                                        }
                                    }
                                    .popover-container {
                                        width: auto;
                                    }
                                }
                                &.active {
                                    position: relative;
                                    background: $bg-color;
                                    &::after {
                                        content: " ";
                                        position: absolute;
                                        top: 0;
                                        left: 100%;
                                        width: 0;
                                        height: 0;
                                        border-style: solid;
                                        border-width: 1.6rem 0 1.6rem 0.6rem;
                                        border-color: transparent transparent transparent $bg-color;
                                    }

                                    .tile-subtitle {
                                        color: $dark-color;

                                        .chip {
                                            background: $dark-color;
                                            color: $light-color;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                & > .journal-detail {
                    white-space: nowrap;

                    & > div {
                        height: 100%;
                        display: flex;
                        flex-direction: column;

                        .form-textbox.full-height {
                            height: auto;

                            & > .form-label {
                                padding-top: 0;
                            }
                        }
                    }
                }
                &.active {
                    & > .journal-detail {
                        flex: 0 1 0;
                        width: 0;
                        padding: 0;
                        overflow: hidden;
                        opacity: 0;
                    }
                }
            }
        }
    }
}
</style>
