<template>
    <form-dropdown
        :id="`${morphTo}_assignment`"
        :name="`${morphTo}_assignment`"
        :value="selection"
        :options="options"
        :size="size"
        :required="required"
        :disabled="disabled"
        :editable="editable"
        :saving="saving"
        :error="error"
        @input="save"
    >
        <template v-slot:read_only><slot name="read_only"></slot></template>
        <template v-slot:empty-value><slot name="empty-value"></slot></template>
        <template v-slot:label><slot name="label"></slot></template>
        <template v-slot:hint><slot name="hint"></slot></template>
         <template v-slot:option="{ option }"><slot name="option" :option="option"></slot></template>
    </form-dropdown>
</template>

<script>
import { mapGetters, mapState } from "vuex"
import orderBy from "lodash/orderBy"

import FormDropdown from "@/nibnut/components/Inputs/FormDropdown"

const build_id = (type, id) => {
    if(type) type = type.replace(/[^a-zA-Z0-9]+/, "")
    return `${type}:${id}`
}

export default {
    name: "AssignmentInput",
    components: {
        FormDropdown
    },
    mounted () {
        this.load()
    },
    watch: {
        profile_id: "load"
    },
    methods: {
        load () {
            if(this.profile_id) {
                this.$store.dispatch(
                    "FETCH_RECORDS",
                    {
                        entity: "user",
                        query: { fields: ["fieldset::picker"] }
                    }
                ).then(() => {
                    this.$store.dispatch(
                        "FETCH_RECORDS",
                        {
                            entity: "team",
                            query: { fields: ["fieldset::picker"] }
                        }
                    ).then(() => {
                        const options = this.options
                        if(!!options.length && !!options[0].options) this.$emit("loaded", options[0].options[0])
                    }).catch(error => {
                        this.$error(error.message)
                    })
                }).catch(error => {
                    this.$error(error.message)
                })
            }
        },
        options_from_records (entity, type) {
            const type_field = `${this.morphTo}_type`
            const id_field = `${this.morphTo}_id`
            let records = this.entity_records(entity)
            if(this.optionFilter) {
                records = records.filter(record => {
                    return this.optionFilter(entity, record)
                })
            }
            return orderBy(
                records,
                "name",
                "asc"
            ).map(record => {
                return {
                    id: build_id(type, record.id),
                    name: record.name,
                    data: {
                        [type_field]: type,
                        [id_field]: record.id
                    }
                }
            })
        },
        save (id, field, option) {
            if(!id) {
                const type_field = `${this.morphTo}_type`
                const id_field = `${this.morphTo}_id`
                this.$emit("input", { [type_field]: null, [id_field]: 0 }, this.morphTo)
            } else this.$emit("input", option.data, this.morphTo)
        }
    },
    computed: {
        ...mapState(["profile_id"]),
        ...mapGetters(["entity_records"]),
        options () {
            return [
                {
                    name: this.translate("Users"),
                    options: this.options_from_records("user", "App\\User")
                },
                {
                    name: this.translate("Teams"),
                    options: this.options_from_records("team", "App\\Team")
                }
            ]
        },
        selection () {
            if(!this.value || !this.morphTo) return null
            const type_field = `${this.morphTo}_type`
            const id_field = `${this.morphTo}_id`
            return build_id(this.value[type_field], this.value[id_field])
        }
    },
    props: {
        value: {
            type: Object,
            required: true
        },
        morphTo: {
            type: String,
            default: "assigned_to"
        },
        size: {
            type: String,
            validator: prop => !!prop && !!prop.match(/^(sm|md|lg)$/i),
            default: "md"
        },
        required: {
            type: Boolean,
            required: true
        },
        disabled: { // disable input field
            type: Boolean,
            default: false
        },
        editable: { // read-only
            type: Boolean,
            default: true
        },
        saving: {
            type: Boolean,
            default: false
        },
        error: {
            type: String,
            default: ""
        },
        optionFilter: {
            default: null
        }
    }
}
</script>
