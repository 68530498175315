<template>
    <div
        :id="id"
        class="task-lists"
    >
        <div class="columns align-items-center">
            <div class="column text-bold">
                <div class="columns align-items-center">
                    <div class="column col-auto text-bold">
                        <label class="form-label disabled-field">{{ translate('Checklists') }}</label>
                    </div>
                    <div class="column col-auto">
                        <default-button
                            v-if="showAddButton"
                            size="xs"
                            class="s-circle ml-2"
                            @click.prevent="assigning_task_list = true"
                        >
                            <open-icon glyph="plus" size="1x" />
                        </default-button>
                    </div>
                </div>
            </div>
            <div class="column col-auto">
                <div class="btn-group">
                    <default-button
                        size="sm"
                        :active="!state.filter"
                        @click.prevent="filter('is_crucial', false)"
                    >
                        {{ translate('All') }}
                    </default-button>
                    <default-button
                        size="sm"
                        :active="!!state.filter"
                        @click.prevent="filter('is_crucial', true)"
                    >
                        {{ translate('Important') }}
                    </default-button>
                </div>
            </div>
        </div>
        <div class="task-lists-list">
            <ul
                class="menu"
                v-if="!!menu_items.length"
            >
                <li
                    v-for="menu_item in menu_items"
                    :key="menu_item.id"
                    :class="{ divider: !!menu_item.is_task_list, 'menu-item': !menu_item.is_task_list }"
                    :data-content="menu_item.name"
                >
                    <a v-if="!menu_item.is_task_list" class="columns">
                        <form-toggle-input
                            :id="`deleted_at-${menu_item.id}`"
                            name="deleted_at"
                            type="checkbox"
                            :value="!!menu_item.deleted_at"
                            :required="false"
                            size="sm"
                            class="column col-auto"
                            @input="toggle(menu_item)"
                            @click.native.stop
                        />
                        <label class="column">
                            <div class="task-description">
                                {{ menu_item.description || menu_item.property_name }}
                                <div
                                    v-if="!!task_action_by_id(menu_item.task_action_id).name && (task_action_by_id(menu_item.task_action_id).name !== menu_item.description)"
                                    class="text-small text-gray"
                                >
                                    {{ task_action_by_id(menu_item.task_action_id).name }}
                                </div>
                            </div>
                            <div
                                :class="{ 'text-error': task_is_overdue(menu_item), 'text-gray': !task_is_overdue(menu_item) }"
                                class="text-small"
                            >
                                {{ (menu_item.deleted_at ? menu_item.deleted_at : menu_item.scheduled_at) | nibnut.date("MM/DD/YYYY", "", true) }} @ {{ (menu_item.deleted_at ? menu_item.deleted_at : menu_item.scheduled_at) | nibnut.date("hh:mm A", "", true) }}
                            </div>
                        </label>
                        <figure
                            :title="menu_item.name"
                            :data-initial="menu_item.computed_initials"
                            :style="{ borderColor: menu_item.color, backgroundColor: is_assigned_to_team(menu_item) ? 'white' : menu_item.color, color: is_assigned_to_team(menu_item) ? menu_item.color : color_for_background(menu_item.color) }"
                            class="avatar"
                        ></figure>
                    </a>
                </li>
            </ul>
        </div>
        <assign-task-list-dialog
            :property-id="(topic === 'property_id') ? topicId : propertyId"
            :contact-id="(topic === 'contact_id') ? topicId : 0"
            :show.sync="assigning_task_list"
            @created="refresh"
        />
    </div>
</template>

<script>
import orderBy from "lodash/orderBy"

import is_remote_data_table_source from "@/nibnut/mixins/IsRemoteDataTableSource"
import addl_misc_utilities from "@/custom/mixins/AddlMiscUtilities"

import FormToggleInput from "@/nibnut/components/Inputs/FormToggleInput"
import DefaultButton from "@/nibnut/components/Buttons/DefaultButton"
import OpenIcon from "@/nibnut/components/OpenIcon"
import AssignTaskListDialog from "@/custom/dialogs/AssignTaskListDialog"

export default {
    name: "TaskLists",
    mixins: [is_remote_data_table_source, addl_misc_utilities],
    components: {
        FormToggleInput,
        DefaultButton,
        OpenIcon,
        AssignTaskListDialog
    },
    watch: {
        topic: "refresh",
        topicId: "refresh"
    },
    methods: {
        pre_load () {
            this.set_state_values({
                topic: this.topic,
                topic_id: this.topicId
            })
        },
        post_load () {
            this.$emit("refreshed")
        },
        tasks_by_task_list_id (task_list_id) {
            return this.filtered_rows.filter(task => task.task_list_id === task_list_id)
        },
        is_assigned_to_team (menu_item) {
            return (menu_item.assigned_to_type === "App\\Team")
        },
        task_is_overdue (menu_item) {
            return !!menu_item && !menu_item.deleted_at && !!menu_item.scheduled_at && !this.$dayjs().isBefore(this.$dayjs(menu_item.scheduled_at))
        },
        toggle (menu_item) {
            if(!menu_item.deleted_at) {
                this.$store.dispatch(
                    "RECORD_DELETE",
                    {
                        entity: "task",
                        id: menu_item.id
                    }
                ).catch(this.receive_error)
            } else {
                this.$store.dispatch(
                    "RECORD_RESTORE",
                    {
                        entity: "task",
                        id: menu_item.id
                    }
                ).catch(this.receive_error)
            }
        }
    },
    computed: {
        state_identifier () {
            return `tasks-lists-${this.id}`
        },
        fields () {
            return ["fieldset::task-list"]
        },
        can_refresh () {
            return !!this.profile_id && !!this.topic && !!this.topicId
        },
        rows () {
            return this.entity_records(this.entity).filter(task => {
                return (!this.state.filter || task.is_crucial) && (task[this.topic] === this.topicId) && ((this.topic === "contact_id") || (task.contact_id === 0))
            })
        },
        filtered_rows () {
            let rows = this.rows.slice()

            if(!this.state.archived && !this.filtered_by_selection) rows = rows.filter(record => !record.deleted_at)

            rows = this.sort_rows(rows)
            rows = this.filter_rows(rows)
            if(!this.filtered_by_selection) rows = this.search_rows(rows)

            rows = this.page_rows(rows)
            return rows
        },
        menu_items () {
            let task_lists = []
            this.filtered_rows.forEach(task => {
                if(!!task.task_list_id && !task_lists.find(task_list => task_list.id === task.task_list_id)) task_lists.push({ id: task.task_list_id, name: task.task_list_name })
            })
            task_lists = orderBy(task_lists, task_list => task_list.name, "asc")
            const menu_items = []
            task_lists.forEach(task_list => {
                menu_items.push({ id: `task-list-${task_list.id}`, name: task_list.name, is_task_list: true })
                this.tasks_by_task_list_id(task_list.id).forEach(task => {
                    menu_items.push(task)
                })
            })
            return menu_items
        }
    },
    props: {
        id: {
            type: String,
            required: true
        },
        propertyId: {
            type: Number,
            default: 0
        },
        topic: {
            type: String,
            validator: prop => !!prop && !!prop.match(/^(property|contact)_id$/)
        },
        topicId: {
            type: Number,
            default: 0
        },
        showAddButton: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            entity: "task",
            default_state: {
                per_page: 0,
                page: 1,
                sort_by: ["scheduled_at"],
                sort_dir: ["asc"],
                filter_on: "is_crucial",
                filter: true,
                archived: true,
                search: "",
                total: 0,
                found: 0,
                topic: null,
                topic_id: 0
            },
            assigning_task_list: false
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.task-lists {
    min-height: 220px;

    .task-lists-list {
        overflow: auto;
        max-height: 100%;

        & > .menu {
            box-shadow: none;

            & > li {
                font-size: 0.7rem;
                padding-left: 0.4rem;
                &.divider[data-content]::after {
                    text-transform: uppercase;
                    padding-left: 0;
                }
                &.menu-item > a {
                    display: flex;
                    .form-checkbox {
                        padding: 0.1rem 0.3rem 0.1rem 0.3rem;
                    }
                }
            }
        }
    }
}
</style>
