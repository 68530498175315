<template>
    <form-select
        v-bind="select_props"
        data-source="person"
        :data-source-additional-data="{ property_id: propertyId, fields: ['id', 'name'] }"
        :ad-hoc="true"
        :ad-hoc-choice-prefix="translate('Create ')"
        v-on="$listeners"
    >
        <template v-slot:read_only><slot name="read_only"></slot></template>
        <template v-slot:label><slot name="label"></slot></template>
        <template v-slot:hint><slot name="hint"></slot></template>
        <template v-slot:option="{ option }"><slot name="option" :option="option"></slot></template>
        <template v-slot:right_addon="{ option }"><slot name="right_addon" :option="option"></slot></template>
    </form-select>
</template>

<script>
import FormSelect from "@/nibnut/components/Inputs/FormSelect"

export default {
    name: "PersonPicker",
    components: {
        FormSelect
    },
    computed: {
        select_props () {
            const { dataSource, dataSourceAdditionalData, adHoc, adHocChoicePrefix, ...select_props } = this.$props
            return select_props
        }
    },
    props: {
        ...FormSelect.props,
        propertyId: {
            type: Number,
            required: true
        }
    }
}
</script>
