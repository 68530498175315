import { mapGetters } from "vuex"
import orderBy from "lodash/orderBy"

import misc_utilities from "@/nibnut/mixins/MiscUtilities"

const addl_misc_utilities = {
    mixins: [misc_utilities],
    methods: {
        task_type_by_id (id) {
            return this.entity_record("task_type", id) || {}
        },
        task_action_by_id (id) {
            return this.entity_record("task_action", id) || {}
        },
        task_actions_for_type (task_type_id) {
            return this.task_actions.filter(task_action => task_action.task_type_id === task_type_id)
        },
        is_unknown_dod_date (date) {
            const dod_date = this.constants("UNKNOWN_DOD_DATE")
            return !!date && !!dod_date && this.$dayjs(dod_date).isSame(this.$dayjs(date), "day")
        }
    },
    computed: {
        ...mapGetters(["entity_records", "entity_record"]),
        task_types () {
            return this.entity_records("task_type")
        },
        sorted_task_types () {
            return orderBy(this.task_types, ["sort_order", "name"], "asc")
        },
        default_task_type_id () {
            const task_types = this.sorted_task_types
            if(!task_types.length) return 0
            return task_types[0].id
        },
        task_actions () {
            return this.entity_records("task_action")
        },
        sorted_task_actions () {
            return orderBy(this.task_actions, ["sort_order", "name"], "asc")
        },
        default_task_action_id () {
            const task_actions = this.sorted_task_actions
            if(!task_actions.length) return 0
            return task_actions[0].id
        }
    }
}

export default addl_misc_utilities
