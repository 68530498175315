<template>
    <base-toggle-input
        v-bind="base_toggle_input"
        :indeterminate="is_indeterminate"
        :checked="is_checked"
        @change.prevent="toggle"
    >
        <slot></slot>
    </base-toggle-input>
</template>

<script>
import is_nibnut_component from "@/nibnut/mixins/IsNibnutComponent"

import BaseToggleInput from "./BaseToggleInput"

export default {
    name: "DefaultToggleInput",
    mixins: [is_nibnut_component],
    components: {
        BaseToggleInput
    },
    methods: {
        toggle () {
            if(this.unknownValue !== undefined) {
                if(this.value === this.unknownValue) this.$emit("input", this.trueValue, this.name)
                else if(this.is_checked) this.$emit("input", this.falseValue, this.name)
                else this.$emit("input", this.unknownValue, this.name)
            } else {
                if(this.is_checked) this.$emit("input", this.falseValue, this.name)
                else this.$emit("input", this.trueValue, this.name)
            }
        }
    },
    computed: {
        base_toggle_input () {
            const { value, trueValue, falseValue, unknownValue, ...base_toggle_input } = this.$props
            return base_toggle_input
        },
        is_indeterminate () {
            return (this.unknownValue !== undefined) && (this.value === this.unknownValue)
        },
        is_checked () {
            return (this.value === this.trueValue)
        }
    },
    props: {
        ...BaseToggleInput.props,
        value: {
            default: false
        },
        trueValue: {
            default: true
        },
        falseValue: {
            default: false
        },
        unknownValue: {
            default: undefined
        }
    }
}
</script>
