<template>
    <div class="nibnut-header-slider">
        <head>
            <slot name="header"></slot>
        </head>
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: "HeadedSlider"
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.nibnut-header-slider {
    display: flex;
    align-items: stretch;
    overflow-x: auto;

    & > * {
        display: block;
    }

    & > head {
        position: sticky;
        left: 0;
        background-color: white;

        & > div {
            height: 100%;
        }
    }
    .table.table-scroll {
        padding-bottom: 0;
    }
}
</style>
