<template>
    <note-pad-new
        v-if="is_beta_testing(profile, 'NOTEPAD')"
        v-bind="$props"
        v-on="$listeners"
    />
    <div v-else class="columns notepad">
        <div v-if="state.total" class="column col-sm-12">
            <data-table
                id="notepad"
                :columns="visible_columns"
                :rows="filtered_rows"
                :sort-fields="state.sort_by"
                :sort-directions="state.sort_dir"
                :search="state.search"
                :total="state.total"
                :found="state.found"
                :current-page="state.page"
                :total-pages="nb_pages"
                :can-add="editable"
                :show-head="false"
                :search-placeholder="translate('Search...')"
                @search="search"
                @sort="sort_by"
                @page="goto_page"
                @click="maybe_select_record"
                @add="new_note"
            >
                <template
                    v-slot:controls
                >
                    <div>
                        <form-dropdown
                            id="filter"
                            name="filter"
                            :value="state.filter"
                            :options="filters"
                            :empty-label="translate('All Categories')"
                            :required="false"
                            @input="filter('category_id', $event)"
                        />
                    </div>
                </template>
                <template
                    v-slot:summary="{ row }"
                >
                    <h6>{{ row.name }}</h6>
                </template>
                <template
                    v-slot:tbody="{ row }"
                >
                    <div
                        :class="{ active: !!edited_record && (edited_record.id === row.id) }"
                        class="tile tile-centered"
                    >
                        <div class="tile-content">
                            <div class="tile-title">{{ (row.name || row.content) | nibnut.truncate(90) }}</div>
                            <small class="tile-subtitle d-flex">
                                <span class="flex-variable">
                                    {{ row.updated_at | nibnut.date }}&nbsp;@&nbsp;{{ row.updated_at | nibnut.date("hh:mm A") }}&nbsp;&bull;&nbsp;{{ row.computed_author_name }}
                                    <span v-if="!!row.category_ids && !!row.category_ids.length">
                                        &nbsp;&bull;&nbsp;
                                        <span
                                            v-for="category_id in row.category_ids"
                                            :key="category_id"
                                            class="chip"
                                        >
                                            {{ term_name_by_id(category_id) }}
                                        </span>
                                    </span>
                                </span>
                                <span
                                    v-if="!!row.pinned_until"
                                    class="flex-static"
                                >
                                    <default-button
                                        v-if="row.pinned_forever"
                                        flavor="link"
                                        size="sm"
                                        @click.prevent.stop="change_pin(row)"
                                    >
                                        {{ translate("pinned forever") }}
                                    </default-button>
                                    <default-button
                                        v-else
                                        flavor="link"
                                        size="sm"
                                        @click.prevent.stop="change_pin(row)"
                                    >
                                        {{ translate("pinned until") }} {{ row.pinned_until | nibnut.date("MM/DD/YYYY") }}
                                    </default-button>
                                </span>
                            </small>
                        </div>
                        <div
                            :id="`popover-${row.id}`"
                            :class="{ popover: quick_pin === row.id, ['popover-bottom']: quick_pin === row.id, active: quick_pin === row.id }"
                            class="tile-action hover-disabled"
                        >
                            <default-button
                                :flavor="!!row.pinned_until ? 'normal' : 'link'"
                                :color="!!row.pinned_until ? 'success' : 'regular'"
                                @click.prevent="toggle_quick_pin(row)"
                            >
                                <open-icon glyph="thumbtack" />
                            </default-button>
                            <div
                                v-if="quick_pin === row.id"
                                class="popover-container"
                            >
                                <ul
                                    v-if="!editing_pin"
                                    class="menu"
                                >
                                    <li
                                        v-for="quick_pin in quick_pins"
                                        :key="quick_pin.value"
                                        class="menu-item"
                                    >
                                        <a
                                            href="#"
                                            @click.prevent.stop="pin_note(row, quick_pin.value)"
                                        >
                                            {{ quick_pin.label }}
                                        </a>
                                    </li>
                                </ul>
                                <div v-else class="card">
                                    <div class="card-header">
                                        <div class="card-title h6 text-gray">{{ translate("Pin Until") }}</div>
                                    </div>
                                    <div class="card-body">
                                        <base-calendar
                                            :min="$dayjs()"
                                            :selection="edited_pinned_date"
                                            size="sm"
                                            @click="pin_note(edited_pin, $event)"
                                        />
                                    </div>
                                    <div class="card-footer">
                                        <default-button
                                            flavor="link"
                                            size="sm"
                                            @click.prevent.stop="editing_pin = false"
                                        >
                                            <open-icon glyph="arrow-left" /> {{ translate("Back") }}
                                        </default-button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </data-table>
        </div>
        <div v-if="edited_record" class="column col-sm-12 d-flex d-flex-columns">
            <div v-if="!!edited_record.read_only" v-html="edited_record.content" style="position: fixed;"></div>
            <form-textbox
                v-else
                ref="editor"
                id="content"
                name="content"
                v-model="edited_record.content"
                size="full"
                :editable="editable"
                :required="false"
                :show-on-ready="true"
                :saving="saving('content')"
                :error="has_error('content')"
                class="flex-variable"
                @input="save"
            >
                <template v-slot:label>
                    <div class="columns">
                        <div class="column flex-static">
                            <h6 class="form-label label-sm">
                                <span v-if="!!edited_record.id">{{ edited_record.updated_at | nibnut.date }}&nbsp;@&nbsp;{{ edited_record.updated_at | nibnut.date("hh:mm A") }}&nbsp;&bull;&nbsp;{{ edited_record.computed_author_name }}</span>
                                <span v-else>{{ translate("New Note") }}</span>
                            </h6>
                        </div>
                        <div class="column flex-static">
                            <div class="form-label label-sm">
                                {{ translate("Categories") }}
                            </div>
                        </div>
                        <div class="column flex-variable">
                            <form-tag-input
                                id="category_ids"
                                name="category_ids"
                                :value="edited_record.category_ids"
                                data-source="term"
                                :data-source-additional-data="term_autosuggest_data"
                                size="sm"
                                :ad-hoc="true"
                                :required="false"
                                @input="save"
                                @create="add_term"
                           />
                        </div>
                    </div>
                </template>
            </form-textbox>
            <div v-if="!edited_record.id || deletable" class="columns mt-2 flex-static">
                <div v-if="deletable" class="column text-center">
                    <div v-if="confirming_deletion">
                        {{ translate("Are you sure?") }}
                        <default-button
                            color="success"
                            size="sm"
                            class="mx-2"
                            @click.prevent.stop="confirming_deletion = false"
                        >
                            {{ translate("Cancel") }}
                        </default-button>
                        <default-button
                            color="error"
                            size="sm"
                            class="mx-2"
                            @click.prevent.stop="delete_note"
                        >
                            {{ translate("Delete") }}
                        </default-button>
                    </div>
                    <default-button
                        v-else-if="editable"
                        flavor="link"
                        color="error"
                        :block="true"
                        @click.prevent.stop="confirming_deletion = true"
                    >
                        {{ translate("Delete") }}
                    </default-button>
                </div>
                <div v-if="!edited_record.id && !!state.found" class="column">
                    <default-button
                        :block="true"
                        :disabled="creating"
                        @click.prevent.stop="refresh()"
                    >
                        {{ translate("Cancel") }}
                    </default-button>
                </div>
                <!--
                <div v-if="!edited_record.id" class="column">
                    <default-button
                        color="primary"
                        :block="true"
                        :waiting="creating"
                        :disabled="creating"
                        @click.prevent.stop="create_note"
                    >
                        {{ translate("Save") }}
                    </default-button>
                </div>
                //-->
            </div>
        </div>
    </div>
</template>

<script type="text/javascript">
import { mapGetters } from "vuex"
import orderBy from "lodash/orderBy"

import { is_remote_data_table_source, handles_saving } from "@/nibnut/mixins"

import {
    FormTextbox,
    FormTagInput,
    FormDropdown,
    BaseCalendar,
    DefaultButton,
    OpenIcon
} from "@/nibnut/components"
import NotePadNew from "./NotePadNew"

export default {
    name: "NotePad",
    mixins: [is_remote_data_table_source, handles_saving],
    components: {
        FormTextbox,
        FormTagInput,
        FormDropdown,
        BaseCalendar,
        DefaultButton,
        OpenIcon,
        NotePadNew
    },
    watch: {
        topicEntity: "refresh",
        topicEntityId: "refresh",
        quick_pin: "toggle_click_listener"
    },
    methods: {
        autoclose (event) {
            const popover = document.getElementById(`popover-${this.quick_pin}`)
            if(this.quick_pin && event && event.target && popover && (!popover.contains(event.target))) {
                this.quick_pin = false
            }
        },
        toggle_click_listener () {
            if(this.quick_pin) document.addEventListener("click", this.autoclose)
            else {
                this.edited_pin = null
                this.edited_pinned_date = null
                this.editing_pin = false
                document.removeEventListener("click", this.autoclose)
            }
        },
        pre_load () {
            this.set_state_value("computed_property_id", this.propertyId)
            this.confirming_deletion = false
            if((this.topicEntity !== this.state.topic_entity) || (this.topicEntityId !== this.state.topic_id)) {
                this.set_state_values({
                    topic_entity: this.topicEntity,
                    topic_id: this.topicEntityId
                })
            }
        },
        post_load () {
            if(!this.state.total) this.new_note()
            else {
                const notes = this.entity_records(this.entity, this.current_records_ids)
                this.edited_record = notes.length ? notes[0] : null
            }
            this.$emit("loaded", this.state.found)
        },
        sort_rows (rows) {
            return orderBy(rows, ["pinned_until", "updated_at"], ["desc", "desc"])
        },
        filter_rows (rows) {
            if(this.state.computed_property_id) return rows.filter(row => row.computed_property_id === this.state.computed_property_id)
            return rows
        },
        maybe_select_record (record) {
            this.edited_record = record
        },
        new_note () {
            this.$store.dispatch(
                "FETCH_RECORD_SHELL",
                { entity: this.entity }
            ).then(record => {
                this.edited_record = record
                this.edited_record.topic_type = this.topicEntity
                this.edited_record.topic_id = this.topicEntityId
                if(this.$refs.editor && this.$refs.editor.$el) {
                    setTimeout(() => {
                        const node = this.$refs.editor.$el.querySelector("textarea")
                        if(node) {
                            node.focus()
                            node.select()
                        }
                    }, 200)
                }
            }).catch(this.receive_error)
        },
        save (value, field) {
            if(this.edited_record) {
                if(this.edited_record[field] !== value) this.edited_record[field] = value
                if(this.edited_record.id) {
                    this.save_field_for_record_id(this.entity, this.edited_record.id, value, field).then(record => {
                        if(record) this.edited_record = record
                        this.quick_pin = false
                    })
                } else if(this.edited_record.content) {
                    const data = { ...this.edited_record }
                    this.$store.dispatch("CREATE_RECORD", {
                        entity: this.entity,
                        data
                    }).then(record => {
                        this.refresh().then(() => {
                            this.edited_record = this.entity_record(this.entity, record.id)
                        })
                    }).catch(this.receive_error)
                }
            }
            /*
            if(this.edited_record && !!this.edited_record.id) {
                this.save_field_for_record_id(this.entity, this.edited_record.id, value, field).then(record => {
                    if(record) this.edited_record = record
                    this.quick_pin = false
                })
            }
            */
        },
        add_term (label, field) {
            if(!label) return
            return this.$store.dispatch("CREATE_RECORD", {
                entity: "term",
                data: {
                    vocabulary: this.constants("vocabularies", "VOCABULARY_LOG_CATEGORIES").id,
                    name: label
                }
            }).then(term => {
                const ids = [...this.edited_record[field]]
                ids.push(term.id)
                this.save(ids, field)
            }).catch(error => {
                this.receive_error(error)
            })
        },
        term_name_by_id (id) {
            return (this.entity_record("term", id) || {}).name
        },
        toggle_quick_pin (note) {
            if(note.pinned_until) {
                this.quick_pin = false
                this.save_field_for_record_id(this.entity, note.id, null, "pinned_until")
            } else this.change_pin(note)
        },
        change_pin (note) {
            if(this.quick_pin) this.quick_pin = false
            else {
                this.quick_pin = note.id
            }
        },
        pin_note (note, until) {
            if(until) this.save_field_for_record_id(this.entity, note.id, until, "pinned_until").then(() => { this.quick_pin = false })
            else this.edit_pin(note)
        },
        edit_pin (note) {
            this.edited_pin = note
            this.edited_pinned_date = (note.pinned_until && !note.pinned_forever) ? this.$dayjs(note.pinned_until) : this.$dayjs()
            this.editing_pin = true
        },
        create_note () {
            if(this.edited_record && !this.edited_record.id) {
                const data = { ...this.edited_record }
                this.$store.dispatch("CREATE_RECORD", {
                    entity: this.entity,
                    data
                }).then(() => {
                    this.refresh()
                }).catch(this.receive_error)
            }
        },
        delete_note () {
            if(this.edited_record && !!this.edited_record.id) {
                this.$store.dispatch(
                    "RECORD_DELETE",
                    {
                        entity: this.entity,
                        id: this.edited_record.id
                    }
                ).then(() => {
                    this.refresh()
                }).catch(this.receive_error)
            }
        }
    },
    computed: {
        ...mapGetters(["entity_record"]),
        state_identifier () {
            return `notepad-${this.topicEntity}-${this.topicEntityId}`
        },
        can_refresh () {
            return !!this.profile_id && !!this.topicEntity && !!this.topicEntityId
        },
        rows () {
            return [
                ...this.entity_records(this.entity, this.current_records_ids),
                ...this.additionalRows
            ]
        },
        fields () {
            return ["fieldset::default"]
        },
        filters () {
            const vocabulary = this.constants("vocabularies", "VOCABULARY_LOG_CATEGORIES").id
            return orderBy(
                this.entity_records("term").filter(term => term.vocabulary === vocabulary),
                "name",
                "asc"
            )
        },
        quick_pins () {
            const today = this.$dayjs()
            return [
                { label: this.translate("Forever"), value: this.constants("MAX_DATE") },
                { label: this.translate("1 week"), value: today.add(1, "week").format("YYYY-MM-DD") },
                { label: this.translate("2 weeks"), value: today.add(2, "weeks").format("YYYY-MM-DD") },
                { label: this.translate("3 weeks"), value: today.add(3, "weeks").format("YYYY-MM-DD") },
                { label: this.translate("1 month"), value: today.add(1, "month").format("YYYY-MM-DD") },
                { label: this.translate("Until..."), value: null }
            ]
        },
        deletable () {
            return this.edited_record && this.edited_record.id && !this.edited_record.read_only && ((this.edited_record.author_id === this.profile.id) || this.is_at_least_administrator)
        },
        term_autosuggest_data () {
            return { fields: ["id", "name"], vocabulary: this.constants("vocabularies", "VOCABULARY_LOG_CATEGORIES").id }
        }
    },
    props: {
        topicEntity: {
            type: String,
            required: true
        },
        topicEntityId: {
            type: Number,
            required: true
        },
        propertyId: {
            type: Number,
            default: 0
        },
        additionalRows: {
            type: Array,
            default () {
                return []
            }
        },
        editable: {
            type: Boolean,
            default: true
        }
    },
    data () {
        return {
            entity: "note",
            relation_ids: ["term"],
            columns: {
                updated_at: { label: "Date", sort: "desc", type: "amount" }
            },
            default_state: {
                per_page: 8,
                page: 1,
                sort_by: ["pinned_until", "updated_at"],
                sort_dir: ["asc", "desc"],
                filter_on: "category_id",
                filter: null,
                archived: false,
                search: "",
                total: 0,
                found: 0,
                topic_entity: null,
                topci_id: 0,
                computed_property_id: 0
            },
            edited_record: null,
            creating: false,
            confirming_deletion: false,
            quick_pin: false,
            edited_pin: null,
            edited_pinned_date: null,
            editing_pin: false
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.notepad {
    min-height: 250px;

    .column {
        min-width: 0;
    }
    .table td {
        padding: 0;
        .tile {
            padding: $unit-2;
            width: 42.7vw;
            .tile-title {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            .tile-subtitle {
                color: $gray-color;
            }
            .tile-content {
                .btn.btn-sm {
                    height: auto;
                    padding-top: 0;
                    padding-bottom: 0;
                    line-height: normal;
                    font-size: inherit;
                }
            }
            .tile-action {
                .btn-normal {
                    i.la-thumbtack {
                        transition: transform 0.3s ease-in-out
                    }
                    &.btn-success {
                        i.la-thumbtack {
                            transform: rotate(-45deg);
                        }
                    }
                }
                .popover-container {
                    width: auto;
                }
            }
            &.active {
                background: $bg-color;

                .tile-subtitle {
                    color: $dark-color;

                    .chip {
                        background: $dark-color;
                        color: $light-color;
                    }
                }
            }
        }
    }
}
</style>
