const routes = [
    { path: "/", name: "home", component: () => import("@/views/dashboard/View"), meta: { login_required: true } },
    { path: "/settings", name: "settings", component: () => import("@/views/Settings"), meta: { admin_route: true } },
    { path: "/profile", name: "profile", component: () => import("@/views/user/Edit"), meta: { login_required: true } },
    { path: "/users", name: "user.list", component: () => import("@/views/user/List"), meta: { admin_route: true, scroll_position: true } },
    { path: "/user/:id?", name: "user.edit", component: () => import("@/views/user/Edit"), meta: { admin_route: true } },
    { path: "/properties", name: "property.list", component: () => import("@/views/property/List"), meta: { login_required: true } },
    { path: "/property/:id", name: "property.edit", component: () => import("@/views/property/Edit"), meta: { login_required: true } },
    { path: "/property/:id/new-editor", name: "property.edit-new-editor", component: () => import("@/views/property/EditNew"), meta: { login_required: true } },
    { path: "/tasks", name: "task.list", component: () => import("@/views/task/List"), meta: { login_required: true, scroll_position: true } }
]

export default routes
