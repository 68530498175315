<template>
    <div
        v-if="!!note"
        class="d-flex d-flex-columns"
    >
        <form-textbox
            :id="`content-${note.id}`"
            name="content"
            :value="note.content"
            :placeholder="placeholder"
            :size="size"
            :disabled="disabled"
            :editable="editable"
            :required="false"
            :live="live"
            class="flex-variable"
            @input="save"
        />
        <div
            class="columns"
        >
            <label
                v-if="!!note.id"
                class="column form-label label-sm text-gray"
            >
                {{ note.created_at | nibnut.date }}&nbsp;@&nbsp;{{ note.created_at | nibnut.date("hh:mm A") }}&nbsp;&bull;&nbsp;{{ note.computed_author_name }}
            </label>
            <div
                v-if="!hideControls && (!note.id || deletable)"
                class="column col-auto columns flex-static text-right"
            >
                <div v-if="deletable" class="column text-center">
                    <div v-if="confirming_deletion">
                        {{ translate("Are you sure?") }}
                        <default-button
                            color="success"
                            size="sm"
                            class="mx-2"
                            @click.prevent.stop="confirming_deletion = false"
                        >
                            {{ translate("Cancel") }}
                        </default-button>
                        <default-button
                            color="error"
                            size="sm"
                            class="mx-2"
                            @click.prevent.stop="delete_note"
                        >
                            {{ translate("Delete") }}
                        </default-button>
                    </div>
                    <default-button
                        v-else-if="editable"
                        flavor="link"
                        color="error"
                        :block="true"
                        @click.prevent.stop="confirming_deletion = true"
                    >
                        {{ translate("Delete") }}
                    </default-button>
                </div>
                <div v-if="!note.id" class="column">
                    <default-button
                        :block="true"
                        :disabled="creating"
                        @click.prevent.stop="new_note()"
                    >
                        {{ translate("Cancel") }}
                    </default-button>
                </div>
                <div v-if="!note.id" class="column">
                    <default-button
                        color="primary"
                        :block="true"
                        :waiting="creating"
                        :disabled="creating"
                        @click.prevent.stop="create_note"
                    >
                        {{ translate("Save") }}
                    </default-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import FormTextbox from "@/nibnut/components/Inputs/FormTextbox"
import DefaultButton from "@/nibnut/components/Buttons/DefaultButton"

export default {
    name: "NoteInput",
    components: {
        FormTextbox,
        DefaultButton
    },
    methods: {
        save (value, field) {
            if(this.note) {
                if(this.note[field] !== value) this.$set(this.note, field, value)
                if(this.note.id) return this.save_field(this.note[field], field)
            }
        },
        create_note () {
            if(this.note && !this.note.id) {
                const data = { ...this.note }
                this.$store.dispatch("CREATE_RECORD", {
                    entity: this.entity,
                    data
                }).then(note => {
                    this.$emit("created", note)
                }).catch(this.receive_error)
            }
        },
        delete_note () {
            if(this.note && !!this.note.id) {
                this.$store.dispatch(
                    "RECORD_DELETE",
                    {
                        entity: this.entity,
                        id: this.note.id
                    }
                ).then(() => {
                    this.$emit("deleted", this.note)
                }).catch(this.receive_error)
            }
        }
    },
    props: {
        note: {
            type: Object,
            required: true
        },
        placeholder: {
            type: String,
            default: ""
        },
        size: {
            type: String,
            validator: prop => !prop || prop.match(/^(sm|md|lg|full)$/i),
            default: "md"
        },
        disabled: {
            type: Boolean,
            default: false
        },
        editable: {
            type: Boolean,
            default: true
        },
        deletable: {
            type: Boolean,
            default: true
        },
        live: {
            type: Boolean,
            default: false
        },
        hideControls: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            entity: "note",
            creating: false,
            confirming_deletion: false
        }
    }
}
</script>
