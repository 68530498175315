<template>
    <modal-dialog
        id="assign-task-list-dialog"
        :show.sync="shown"
    >
        <template v-slot:title>
            <div class="h5">
                {{ translate("Assign Checklist") }}
                <span v-if="contactId"> to {{ contact.name }}</span>
            </div>
        </template>

        <form-dropdown
            v-if="shown"
            id="assign-task-list-task_list_id"
            name="task_list_id"
            v-model="task_list_id"
            :options="task_lists"
            :required="true"
        >
            <template v-slot:hint>
                <span v-if="!!assignable_task_names">{{ assignable_task_names }}</span>
            </template>
        </form-dropdown>

        <h6
            v-if="has_contact_assignable_tasks || task_list_contact_ids.length"
            class="mt-4"
        >
            {{ translate("Assign Tasks To:") }}
        </h6>
        <div
            v-if="task_list_contact_ids.length"
            class="mb-2"
        >
            <span
                v-for="selected_contact in selected_contacts"
                :key="selected_contact.id"
                class="chip"
            >
                {{ selected_contact.name }}
                <a
                    v-if="!contactId"
                    href
                    class="btn btn-clear"
                    aria-label="Close"
                    role="button"
                    @click.prevent="contact_toggle(selected_contact.id)"
                ></a>
            </span>
        </div>
        <form-dropdown
            ref="contact_picker"
            v-if="has_contact_assignable_tasks"
            name="contact_id"
            :options="available_contacts"
            size="sm"
            :required="false"
            @input="contact_toggle"
        />
        <!--
        <ul>
            <li
                v-for="assignable_task in assignable_task_lists"
                :key="assignable_task.index"
            >
                {{ assignable_task.description }}
                <div class="text-small text-gray text-uppercase">{{ assignable_task.name }}</div>
                <form-dropdown
                    :id="`${assignable_task.index}-contact_id`"
                    name="contact_id"
                    :value="task_list_contact_ids[assignable_task.index]"
                    :options="contacts"
                    size="sm"
                    :required="true"
                    @input="assign_task(assignable_task, $event)"
                />
            </li>
        </ul>
        //-->

        <template v-slot:footer>
            <default-button
                :disabled="creating"
                class="mx-1"
                @click.prevent="shown = false"
            >
                {{ translate("Cancel") }}
            </default-button>
            <default-button
                :disabled="creating"
                :waiting="creating"
                class="mx-1"
                @click.prevent.stop="create"
            >
                {{ translate("Assign") }}
            </default-button>
        </template>
    </modal-dialog>
</template>

<script>
import { mapGetters } from "vuex"
import orderBy from "lodash/orderBy"

import ModalDialog from "@/nibnut/components/ModalDialog/ModalDialog"
import FormDropdown from "@/nibnut/components/Inputs/FormDropdown"
import DefaultButton from "@/nibnut/components/Buttons/DefaultButton"

export default {
    name: "AssignTaskListDialog",
    components: {
        ModalDialog,
        FormDropdown,
        DefaultButton
    },
    watch: {
        show: "reset",
        propertyId: "reset",
        contactId: "reset",
        task_list_id: "reset_ui"
    },
    methods: {
        reset_ui () {
            this.task_list_contact_ids = []
            if(this.contactId) this.task_list_contact_ids.push(this.contactId)
            /*
            const task_list = this.task_list
            if(!task_list || !task_list.tasks) return
            */
        },
        reset () {
            if(!this.show || (!this.propertyId && !this.contactId)) return
            this.task_list_id = 0
            this.$store.dispatch(
                "FETCH_RECORDS",
                {
                    entity: "person",
                    query: { property_id: this.propertyId, fields: ["fieldset::picker"] }
                }
            ).then(response => {
                this.contact_ids = response.record_ids
                if(this.contactId && !!this.contact_ids.length) this.task_list_contact_ids.push(this.contact_ids[0])
                this.$store.dispatch(
                    "FETCH_RECORDS",
                    {
                        entity: "task_list"
                    }
                ).then(response => {
                    const task_lists = this.task_lists
                    this.task_list_id = task_lists.length ? task_lists[0].id : 0
                })
            })
        },
        /*
        assign_task (assignable_task, contact_id) {
            this.task_list_contact_ids[assignable_task.index] = contact_id
        },
        */
        contact_toggle (contact_id) {
            const index = this.task_list_contact_ids.indexOf(contact_id)
            if(index >= 0) this.task_list_contact_ids.splice(index, 1)
            else this.task_list_contact_ids.push(contact_id)
            const menu = this.$refs.contact_picker.$el.querySelector("select")
            menu.selectedIndex = -1
        },
        create () {
            this.$store.dispatch("RECORD_SAVE", {
                entity: "property",
                id: this.propertyId,
                data: {
                    task_list_id: this.task_list_id,
                    contact_ids: this.contactId ? [this.contactId] : this.task_list_contact_ids
                }
            }).then(() => {
                this.shown = false
                this.$emit("created")
            }).catch(error => {
                this.$error(error.message)
            })
        }
    },
    computed: {
        ...mapGetters(["entity_record", "entity_records"]),
        task_lists () {
            /*
            const task_lists = this.entity_records("task_list").filter(task_list => {
                return !!task_list.tasks && !!task_list.tasks.find(task => {
                    return (!this.contactId && (task.topic === "property_id")) || (!!this.contactId && (task.topic === "contact_id"))
                })
            })
            console.log({ property: this.propertyId, contact: this.contactId, task_lists: task_lists.map(task_list => task_list.name).join(", ") })
            */
            return orderBy(this.entity_records("task_list"), "name", "asc")
        },
        task_list () {
            return this.entity_record("task_list", this.task_list_id) || { id: 0, tasks: [] }
        },
        has_contact_assignable_tasks () {
            return !this.contactId && !!this.task_list_id && !!this.task_list.tasks.find(task => task.topic === "contact_id")
        },
        contacts () {
            const contacts = this.entity_records("person", this.contact_ids)
            return [
                {
                    name: this.translate("Main Contacts"),
                    options: orderBy(contacts.filter(contact => !!contact.is_of_interest), "name", "asc")
                },
                {
                    name: this.translate("Other Contacts"),
                    options: orderBy(contacts.filter(contact => !contact.is_of_interest), "name", "asc")
                }
            ]
        },
        contact () {
            return this.entity_record("contact", this.contactId) || {}
        },
        selected_contacts () {
            if(!this.task_list_contact_ids.length) return []
            return this.entity_records("person", this.task_list_contact_ids)
        },
        available_contacts () {
            const contacts = this.contacts
            if(this.task_list_contact_ids.length) {
                contacts.forEach(section => {
                    section.options = section.options.filter(contact => this.task_list_contact_ids.indexOf(contact.id) < 0)
                })
            }
            return contacts
        },
        assignable_task_names () {
            /*
            task_list.tasks.forEach((task, index) => {
                // this.task_list_contact_ids.push(contacts[0].options.length ? contacts[0].options[0].id : contacts[1].options[0].id)
                if(task.topic === "contact_id") {
                    this.assignable_task_lists.push({
                        index,
                        name: task.name,
                        description: task.description
                    })
                }
            })
            return this.assignable_task_lists.map(assignable_task => assignable_task.description).join(", ")
            */
            return this.task_list.tasks.map(task => task.description).join(", ")
        },
        shown: {
            get () {
                return this.show
            },
            set (show) {
                this.$emit("update:show", show)
            }
        }
    },
    props: {
        propertyId: {
            type: Number,
            default: 0
        },
        contactId: {
            type: Number,
            default: 0
        },
        show: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            task_list_id: 0,
            contact_ids: [],
            assignable_task_lists: [],
            task_list_contact_ids: [],
            creating: false
        }
    }
}
</script>
