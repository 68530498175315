<template>
    <modal-dialog
        id="new-property-dialog"
        :show.sync="shown"
        :dismissable="!creating"
    >
        <template v-slot:title>
            <span class="h5">{{ translate("New Property") }}</span>
        </template>

        <form-input
            v-if="property"
            id="number"
            name="number"
            v-model="property.number"
            :required="true"
        >
            <template v-slot:label>{{ translate("Property ID / Folio") }}</template>
        </form-input>
        <county-address-input
            v-if="address"
            id="address"
            name="address"
            v-model="address"
            default-country="US"
            :required="true"
        >
            <template v-slot:label>{{ $root.translate("Address") }}</template>
        </county-address-input>

        <table class="table mt-4">
            <thead>
                <tr>
                    <th>{{ translate("Owner(s)") }}</th>
                </tr>
            </thead>
            <tbody>
                <tr
                    v-for="(owner, index) in owners"
                    :key="index"
                >
                    <td class="columns">
                        <form-input
                            :id="`owner-first-name-${index}`"
                            name="first_name"
                            v-model="owner.first_name"
                            :placeholder="translate('First Name')"
                            :required="false"
                            class="column"
                        />
                        <form-input
                            :id="`owner-middle-name-${index}`"
                            name="middle_name"
                            v-model="owner.middle_name"
                            :placeholder="translate('Middle Name')"
                            :required="false"
                            class="column"
                        />
                        <form-input
                            :id="`owner-last-name-${index}`"
                            name="last_name"
                            v-model="owner.last_name"
                            :placeholder="translate('Last Name')"
                            :required="false"
                            class="column"
                        />
                        <form-input
                            :id="`owner-name-suffix-${index}`"
                            name="name_suffix"
                            v-model="owner.name_suffix"
                            :placeholder="translate('Name Suffix')"
                            :required="false"
                            class="column"
                        />
                        <default-button
                            v-if="owners.length > 1"
                            flavor="link"
                            color="error"
                            class="column flex-static"
                            @click.prevent.stop="delete_owner(index)"
                        >
                            <open-icon glyph="trash" />
                        </default-button>
                    </td>
                </tr>
            </tbody>
            <thead>
                <tr>
                    <td>
                        <default-button
                            flavor="link"
                            :block="true"
                            @click.prevent.stop="add_owner(true)"
                        >
                            <open-icon glyph="plus" />
                            {{ translate("Add another owner...") }}
                        </default-button>
                    </td>
                </tr>
            </thead>
        </table>

        <template v-slot:footer>
            <default-button
                :disabled="creating"
                class="mr-2"
                @click.prevent="shown = false"
            >
                {{ translate("Cancel") }}
            </default-button>
            <default-button
                :disabled="creating"
                :waiting="creating"
                class="ml-2"
                @click.prevent="create"
            >
                {{ translate("Create") }}
            </default-button>
        </template>
    </modal-dialog>
</template>

<script>
import profile_utilities from "@/nibnut/mixins/ProfileUtilities"
import misc_utilities from "@/nibnut/mixins/MiscUtilities"

import ModalDialog from "@/nibnut/components/ModalDialog/ModalDialog"
import FormInput from "@/nibnut/components/Inputs/FormInput"
import CountyAddressInput from "@/custom/components/CountyAddressInput"
import DefaultButton from "@/nibnut/components/Buttons/DefaultButton"
import OpenIcon from "@/nibnut/components/OpenIcon"

export default {
    name: "NewPropertyDialog",
    mixins: [profile_utilities, misc_utilities],
    components: {
        ModalDialog,
        FormInput,
        CountyAddressInput,
        DefaultButton,
        OpenIcon
    },
    mounted () {
        this.reset()
    },
    watch: {
        show: "reset"
    },
    methods: {
        reset () {
            if(!this.profile_id) return
            this.creating = false
            this.$store.dispatch(
                "FETCH_RECORD_SHELL",
                { entity: "property" }
            ).then(property => {
                this.property = property
                this.$store.dispatch(
                    "FETCH_RECORD_SHELL",
                    { entity: "address" }
                ).then(address => {
                    this.address = address
                    if(!this.owners.length) this.add_owner()
                })
            })
        },
        add_owner (focus = false) {
            this.owners.push(
                {
                    id: 0,
                    property_id: this.property.id,
                    first_name: null,
                    middle_name: null,
                    last_name: null,
                    name_suffix: null
                }
            )
            if(focus) {
                this.$nextTick(() => {
                    const node = document.getElementById(`owner-first-name-${this.owners.length - 1}`)
                    if(node) node.focus()
                })
            }
        },
        delete_owner (index) {
            this.owners.splice(index, 1)
        },
        create () {
            if(!this.owners.length || (!this.owners[0].first_name && !this.owners[0].last_name)) {
                this.$error(this.translate("Please provide at least one owner..."))
                return
            }
            const save_next_owner = () => {
                if(!this.owners.length) {
                    this.$router.push({ name: "property.edit", params: { id: this.property.id } })
                    this.shown = false
                } else {
                    const owner = this.owners.shift()
                    if(!owner || (!owner.first_name && !owner.last_name)) save_next_owner()
                    else {
                        owner.property_id = this.property.id
                        this.$store.dispatch("CREATE_RECORD", {
                            entity: "person",
                            data: owner
                        }).then(person => {
                            this.$store.dispatch("CREATE_RECORD", {
                                entity: "connection",
                                data: {
                                    person_id: person.id,
                                    topic_type: "App\\Property",
                                    topic_id: this.property.id,
                                    type: this.constants("connection_types", "TYPE_OWNER").id
                                }
                            }).then(() => {
                                save_next_owner()
                            }).catch(error => {
                                this.$error(error.message)
                                this.creating = false
                            })
                        }).catch(error => {
                            this.$error(error.message)
                            this.creating = false
                        })
                    }
                }
            }
            this.creating = true
            this.$store.dispatch("CREATE_RECORD", {
                entity: "address",
                data: this.address
            }).then(address => {
                this.property.address_id = address.id
                this.$store.dispatch("CREATE_RECORD", {
                    entity: "property",
                    data: this.property
                }).then(property => {
                    this.property.id = property.id
                    save_next_owner()
                }).catch(error => {
                    this.$error(error.message)
                    this.creating = false
                })
            }).catch(error => {
                this.$error(error.message)
                this.creating = false
            })
        }
    },
    computed: {
        shown: {
            get () {
                return this.show
            },
            set (show) {
                this.$emit("update:show", show)
            }
        }
    },
    props: {
        show: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            creating: false,
            property: null,
            address: null,
            owners: []
        }
    }
}
</script>
