<template>
    <div class="assignment-buttons" @click.stop>
        <default-button
            :title="translate('Assign To')"
            flavor="link"
            :size="buttonSize"
            :disabled="!assignable"
            class="btn-assign"
            @click.prevent.stop="assign(null)"
        >
            <figure
                v-for="assignment in current_assignments"
                :key="assignment.id"
                class="avatar avatar-sm"
            >
                <open-icon v-if="!assignment.user_id || !user_for_assignment(assignment).avatar" glyph="user" size="2x" />
                <img v-else :src="user_for_assignment(assignment).avatar" :alt="user_for_assignment(assignment).name">
            </figure>
            <span
                v-if="!current_assignments.length"
                class="text-small text-gray"
            >
                <open-icon
                    glyph="user-plus"
                />
                {{ translate("Unassigned") }}
            </span>
        </default-button>
        <default-button
            v-if="assigned_to_me"
            :title="translate('Remind Me')"
            flavor="link"
            :size="buttonSize"
            class="btn-remind"
            @click.prevent.stop="assign($dayjs().add(app_context.settings.default_watch_delay, 'days'))"
        >
            <open-icon glyph="clock" size="lg" />
        </default-button>

        <assignment-dialog
            v-if="assigning"
            :show.sync="assigning"
            :entity="entity"
            :topic-id="topic.id"
            :type="assign_type"
            :user-id="assign_to"
            :schedule-at="assign_at"
            @click.stop
        />
    </div>
</template>

<script>
// OBSOLETE!
import { assignment_utilities } from "@/custom/mixins"

import {
    DefaultButton,
    OpenIcon
} from "@/nibnut/components"

export default {
    name: "AssignmentButtons",
    mixins: [assignment_utilities],
    components: {
        DefaultButton,
        OpenIcon,
        AssignmentDialog: () => import("@/custom/dialogs/AssignmentDialog")
    },
    methods: {
        assign (date) {
            this.assign_type = 0
            this.assign_to = 0
            if(date) {
                const current_assignment = this.current_assignments.find(assignment => assignment.user_id === this.profile.id)
                this.assign_type = current_assignment ? current_assignment.type : 0
                this.assign_to = current_assignment ? current_assignment.user_id : 0
            }
            this.assign_at = date
            this.assigning = true
        }
    },
    computed: {
        assignments () {
            if(!this.topic || !this.topic.id) return []
            return this.assignments_by_topic_type_and_id(this.assignment_topic_type_for_entity(this.entity), this.topic.id)
        },
        current_assignments () {
            if(!this.topic || !this.topic.id) return []
            return this.current_assignments_by_topic_type_and_id(this.assignment_topic_type_for_entity(this.entity), this.topic.id)
        },
        assigned_to_me () {
            return this.topic_assigned_to_me(this.topic)
        },
        assignable () {
            return this.topic_editable(this.topic)
        }
    },
    props: {
        entity: {
            type: String,
            required: true
        },
        topic: {
            type: Object,
            required: true
        },
        buttonSize: {
            type: String,
            validator: prop => !prop || prop.match(/^(xs|sm|md|lg)$/i),
            default: "lg"
        }
    },
    data () {
        return {
            assigning: false,
            assign_type: 0,
            assign_to: 0,
            assign_at: null
        }
    }
}
</script>

<style lang="scss">
.assignment-buttons {
    white-space: nowrap;
    .btn.btn-lg.btn-assign {
        padding-top: 0;
        padding-bottom: 0;
        height: auto;
    }
    .btn-remind .las.la-lg {
        line-height: 1.2rem;
        font-size: 1.6rem;
    }
}
</style>
