<template>
    <form-group
        v-bind="form_group_props"
        class="form-dod-input"
    >
        <template v-slot:read_only><slot name="read_only"></slot></template>
        <template v-slot:empty-value>
            <slot name="empty-value">
                <span v-if="is_unknown_dod_date(value)">{{ translate("Unknown Date") }}</span>
                <span v-else-if="!!value">{{ value | nibnut.date }}</span>
            </slot>
        </template>
        <template v-slot:label>
            <form-toggle-input
                :id="`${id}-toggle`"
                :name="`${id}-toggle`"
                type="checkbox"
                :value="!!value"
                :size="size"
                :required="false"
                :horizontal="horizontal"
                :disabled="!editable || disabled"
                @input="toggle"
            >
                <slot name="label"></slot>
                <slot></slot>
            </form-toggle-input>
        </template>
        <template v-slot:hint><slot name="hint"></slot></template>
        <input-group v-if="!!value">
            <base-date-input
                v-if="!is_unknown_dod_date(value)"
                v-bind="input_props"
                :disabled="!editable || disabled"
                v-on="$listeners"
            >
                <template v-slot:left_addon><slot name="left_addon"></slot></template>
                <template v-slot:right_addon>
                    <default-button
                        :size="size"
                        :title="translate('Unknown Date')"
                        class="input-group-btn"
                        @click.prevent.stop="set_unknown"
                    >
                        {{ translate("No Date") }}
                    </default-button>
                </template>
            </base-date-input>
            <div
                v-else
                class="columns"
            >
                <div class="column">
                    <label
                        :class="{ [`label-${size}`]: true  }"
                        class="form-label"
                    >
                        {{ translate("Unknown Date") }}
                    </label>
                </div>
                <div class="column flex-static">
                    <default-button
                        :size="size"
                        :title="translate('Set DOD Date...')"
                        class="input-group-btn"
                        @click.prevent.stop="set_date"
                    >
                        <open-icon glyph="calendar" />
                    </default-button>
                </div>
            </div>
        </input-group>
    </form-group>
</template>

<script>
import is_nibnut_component from "@/nibnut/mixins/IsNibnutComponent"
import is_alpha_numerical_input from "@/nibnut/mixins/IsAlphaNumericalInput"
import addl_misc_utilities from "@/custom/mixins/AddlMiscUtilities"

import FormGroup from "@/nibnut/components/Inputs/FormGroup"
import InputGroup from "@/nibnut/components/Inputs/InputGroup"
import FormToggleInput from "@/nibnut/components/Inputs/FormToggleInput"
import BaseDateInput from "@/nibnut/components/Inputs/BaseDateInput"
import DefaultButton from "@/nibnut/components/Buttons/DefaultButton"
import OpenIcon from "@/nibnut/components/OpenIcon"

export default {
    name: "FormDodInput",
    mixins: [is_nibnut_component, is_alpha_numerical_input, addl_misc_utilities],
    components: {
        FormGroup,
        InputGroup,
        FormToggleInput,
        BaseDateInput,
        DefaultButton,
        OpenIcon
    },
    methods: {
        set_date () {
            this.$emit("input", this.$dayjs(), this.name)
        },
        set_unknown () {
            this.$emit("input", this.$dayjs(this.constants("UNKNOWN_DOD_DATE")), this.name)
        },
        toggle () {
            if(this.value) this.$emit("input", null, this.name)
            else this.set_unknown()
        }
    },
    computed: {
        form_group_props () {
            return {
                id: this.id,
                name: this.name,
                value: this.value,
                size: this.size,
                required: this.required,
                editable: this.editable,
                error: this.error,
                waiting: this.saving,
                horizontal: this.horizontal
            }
        },
        input_props () {
            const { editable, ...input_props } = this.$props
            return input_props
        }
    },
    props: {
        ...BaseDateInput.props,
        disabled: { // disable input field
            type: Boolean,
            default: false
        },
        editable: { // read-only
            type: Boolean,
            default: true
        },
        horizontal: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.form-dod-input {
    & > .form-label,
    & > .form-label.label-sm {
        padding: 0;
    }
}
</style>
