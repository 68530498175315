<template>
    <default-button
        :label="label"
        :flavor="flavor"
        :color="color"
        :size="size"
        :block="block"
        :waiting="verifying"
        :disabled="disabled || verifying"
        :loader-size="loaderSize"
        :title="title"
        @click.prevent.stop="verify"
    >
        <open-icon glyph="spell-check" />
    </default-button>
</template>

<script>
import DefaultButton from "@/nibnut/components/Buttons/DefaultButton"
import OpenIcon from "@/nibnut/components/OpenIcon"

export default {
    name: "VerifyButton",
    components: {
        DefaultButton,
        OpenIcon
    },
    methods: {
        verify () {
            this.verifying = true
            this.$store.dispatch(
                "RECORD_ACTION",
                {
                    entity: this.entity,
                    id: this.topic.id,
                    action: "verify",
                    method: "patch"
                }
            ).catch(error => {
                this.$error(error.message)
            }).then(() => {
                this.verifying = false
            })
        }
    },
    props: {
        topic: {
            type: Object,
            required: true
        },
        entity: {
            type: String,
            default: "contact_number"
        },
        label: {
            type: String,
            validator: prop => !!prop
        },
        flavor: {
            type: String,
            validator: prop => !prop || prop.match(/^(link|normal)$/i),
            default: "link"
        },
        color: {
            type: String,
            validator: prop => !prop || prop.match(/^(primary|secondary|regular|error|warning|success|clear|light|gray|text|chameleon)$/i),
            default: "regular"
        },
        size: {
            type: String,
            validator: prop => !prop || prop.match(/^(xs|sm|md|lg)$/i),
            default: "md"
        },
        block: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        loaderSize: {
            type: String,
            validator: prop => !prop || prop.match(/^(xs|sm)$/i),
            default: "sm"
        },
        title: {
            type: String,
            default: "Verify..."
        }
    },
    data () {
        return {
            verifying: false
        }
    }
}
</script>
