<template>
    <div
        v-if="field.type !== 'bool'"
        class="query-builder-criterion-value d-flex"
    >
        <form-toggle-input
            v-for="toggle in toggles"
            :key="toggle.id"
            :id="`value-${index}-${toggle.id}`"
            :name="field.id"
            :type="field.type"
            :value="value_from"
            :horizontal="true"
            :required="false"
            @input="input($event)"
        >
            {{ toggle.name }}
        </form-toggle-input>
        <property-status-dropdown
            v-if="criterion.field === 'status_id'"
            :id="`value-${index}-from`"
            :name="field.id"
            :value="value_from"
            :required="false"
            @input="input($event)"
        />
        <form-dropdown
            v-else-if="field.type === 'dropdown'"
            :id="`value-${index}-from`"
            :name="field.id"
            :value="value_from"
            :options="field.choices"
            :required="false"
            @input="input($event)"
        />
        <form-date-input
            v-else-if="field.type === 'date'"
            :id="`value-${index}-from`"
            :name="field.id"
            :value="value_from"
            :min="field.min || null"
            :max="field.max || null"
            :date-format="field.format || 'MM/DD/YYYY'"
            position="left"
            calendar-size="sm"
            :required="false"
            @focus="$emit('focus')"
            @blur="$emit('blur')"
            @input="input($event)"
        />
        <form-input
            v-else
            :id="`value-${index}-from`"
            :name="field.id"
            :type="field.type"
            :value="value_from"
            :autocomplete="field.autocomplete || ''"
            :autocapitalize="field.autocapitalize || 'sentences'"
            :glyph="field.glyph || ''"
            :glyph-library="field.glyph_library || 'las'"
            :glyph-position="field.glyph_position || 'right'"
            :glyph-size="field.glyph_size || '1x'"
            :min="field.min || null"
            :max="field.max || null"
            :float-step="field.step || 0.5"
            :max-length="field.maxlength || null"
            :date-format="field.format || 'MM/DD/YYYY'"
            :position="field.position || 'top'"
            :required="false"
            @input="input($event)"
        />
        <label
            v-if="is_ranged"
            class="form-label mx-1"
        >
            {{ translate("and") }}
        </label>
        <div
            v-if="is_ranged"
        >
            <form-dropdown
                v-if="field.type === 'dropdown'"
                :id="`value-${index}-to`"
                :name="field.id"
                :value="value_to"
                :options="field.choices"
                :required="false"
                @input="input($event, 1)"
            />
            <form-date-input
                v-else-if="field.type === 'date'"
                :id="`value-${index}-to`"
                :name="field.id"
                :value="value_to"
                :min="field.min || null"
                :max="field.max || null"
                :date-format="field.format || 'MM/DD/YYYY'"
                :position="top"
                :required="false"
                @input="input($event, 1)"
            />
            <form-input
                v-else
                :id="`value-${index}-to`"
                :name="field.id"
                :type="field.type"
                :value="value_to"
                :autocomplete="field.autocomplete || ''"
                :autocapitalize="field.autocapitalize || 'sentences'"
                :glyph="field.glyph || ''"
                :glyph-library="field.glyph_library || 'las'"
                :glyph-position="field.glyph_position || 'right'"
                :glyph-size="field.glyph_size || '1x'"
                :min="field.min || null"
                :max="field.max || null"
                :float-step="field.step || 0.5"
                :max-length="field.maxlength || null"
                :date-format="field.format || 'MM/DD/YYYY'"
                :position="field.position || 'top'"
                :required="false"
                @input="input($event, 1)"
            />
        </div>
    </div>
</template>

<script>
import FormDropdown from "@/nibnut/components/Inputs/FormDropdown"
import FormToggleInput from "@/nibnut/components/Inputs/FormToggleInput"
import FormDateInput from "@/nibnut/components/Inputs/FormDateInput"
import FormInput from "@/nibnut/components/Inputs/FormInput"
import PropertyStatusDropdown from "@/custom/components/PropertyStatusDropdown"

export default {
    name: "QueryBuilderValue",
    components: {
        FormDropdown,
        FormToggleInput,
        FormDateInput,
        FormInput,
        PropertyStatusDropdown
    },
    watch: {
        "criterion.operator": "focus_value"
    },
    methods: {
        input (value, range_index = 0) {
            let criterion_value = value
            if(this.is_ranged) {
                criterion_value = [...this.criterion.value]
                criterion_value[range_index] = value
            }
            this.$emit("input", criterion_value)
        },
        focus_value () {
            setTimeout(() => {
                const node = document.querySelector(`#value-${this.index}-from`)
                if(node) {
                    node.focus()
                    node.select()
                }
            }, 300)
        }
    },
    computed: {
        toggles () {
            if((this.field.type === "radio") || (this.field.type === "checkbox")) return this.field.options
            return []
        },
        is_ranged () {
            return Array.isArray(this.criterion.value)
        },
        value_from () {
            if(this.is_ranged) return this.criterion.value.length ? this.criterion.value[0] : null
            return this.criterion.value
        },
        value_to () {
            if(this.is_ranged) return (this.criterion.value.length > 1) ? this.criterion.value[1] : null
            return this.criterion.value
        }
    },
    props: {
        field: {
            type: Object,
            required: true
        },
        criterion: {
            type: Object,
            required: true
        },
        index: {
            type: Number,
            required: true
        }
    }
}
</script>
