const color_utilities = {
    methods: {
        color_name_to_hex (color_name) {
            const matches = color_name.match(/^(.+?)(?:::(light))?$/)
            color_name = matches ? matches[1] : color_name
            const light = matches ? (matches[2] === "light") : false
            // UICatalog Generate code: Paste here
            let hex = "#91c440"
            switch (color_name) {
            case "secondary":
                hex = "#6b6b6e"
                break
            case "tertiary":
                hex = "#d58a72"
                break
            case "dark":
                hex = "#333333"
                break
            case "gray":
                hex = "#f7f8f9"
                break
            case "light":
                hex = "#00000000"
                break
            case "success":
                hex = "#32b643"
                break
            case "warning":
                hex = "#ffb700"
                break
            case "error":
                hex = "#e85600"
                break
            }
            return light ? `${hex}40` : hex
        }
    }
}

export default color_utilities
