<template>
    <form-group
        v-bind="form_group_props"
    >
        <template v-slot:read_only><slot name="read_only"></slot></template>
        <template v-slot:empty-value>
            <span
                v-for="single_value in value"
                :key="single_value"
                class="chip chip-sm"
            >
                {{ option_name(single_value) }}
            </span>
        </template>
        <template v-slot:label><slot name="label"></slot></template>
        <template v-slot:hint><slot name="hint"></slot></template>
        <default-tag-input
            ref="field"
            :id="id"
            :name="name"
            :value="value"
            :id-field="idField"
            :label-field="labelField"
            :empty-label="emptyLabel"
            :empty-value="emptyValue"
            :data-source="dataSource"
            :data-source-additional-data="dataSourceAdditionalData"
            :stored-data-source="storedDataSource"
            :show-all="showAll"
            :ad-hoc="adHoc"
            :glyph="glyph"
            :size="size"
            :max-picks="maxPicks"
            :required="required"
            :disabled="disabled"
            @input="pick"
            @create="create"
        >
            <template v-slot:selection-label="{ option }"><slot name="selection-label" :option="option"></slot></template>
        </default-tag-input>
    </form-group>
</template>

<script>
import is_nibnut_component from "@/nibnut/mixins/IsNibnutComponent"
import handles_choices from "@/nibnut/mixins/HandlesChoices"

import FormGroup from "./FormGroup"
import DefaultTagInput from "./DefaultTagInput"

export default {
    name: "FormTagInput",
    mixins: [is_nibnut_component, handles_choices],
    components: {
        FormGroup,
        DefaultTagInput
    },
    methods: {
        load_options () {
            if(!this.editable && this.is_remote_source && (this.value || this.showAll || (this.option_query.length >= this.minOptionQueryLength))) {
                this.loading_options = true
                return this.$store.dispatch("AUTOSUGGEST", {
                    entity: this.dataSource,
                    context: this.name,
                    passthru: !this.storedDataSource,
                    data: {
                        value: this.value,
                        query: this.showAll ? "" : this.option_query,
                        context_id: this.contextId,
                        ...this.dataSourceAdditionalData
                    } // value is either one id or an array of ids
                }).then(options => {
                    if(this.storedDataSource) this.options = (!!options && !!options.length) ? this.entity_records(this.dataSource, options) : []
                    else this.options = options
                    this.$emit("loaded", this.options)
                }).catch(error => {
                    this.$error(error.message)
                }).then(() => {
                    this.loading_options = false
                })
            }
            return Promise.resolve()
        },
        pick (value, field, option) {
            if(this.editable) this.$emit("input", value, field, option)
        },
        create (label, field) {
            if(this.editable) this.$emit("create", label, field)
        },
        option_name (id) {
            const option = this.options.find(option => option[this.idField] === id)
            return option ? option[this.labelField] : id
        }
    },
    computed: {
        form_group_props () {
            return {
                id: this.id,
                name: this.name,
                value: this.value,
                required: this.required,
                editable: this.editable,
                error: this.error,
                waiting: this.saving,
                horizontal: this.horizontal
            }
        }
    },
    props: {
        ...DefaultTagInput.props,
        idField: {
            type: String,
            default: "id"
        },
        labelField: {
            type: String,
            default: "name"
        },
        emptyValue: {
            default: 0
        },
        emptyLabel: {
            type: String,
            default: ""
        },
        dataSource: { // either an array of options (locally sourced) OR an entity name (remote source ; api endpoint /<entity>/autosuggest/<name>)
            validator: prop => !!prop && (Array.isArray(prop) || (typeof prop === "string")),
            required: true,
            default () {
                return []
            }
        },
        dataSourceAdditionalData: {
            type: Object,
            default () {
                return { fields: ["id", "name"] }
            }
        },
        storedDataSource: {
            type: Boolean,
            default: false
        },
        adHoc: {
            type: Boolean,
            default: false
        },
        showAll: {
            type: Boolean,
            default: true
        },
        minOptionQueryLength: {
            type: Number,
            default: 3
        },
        editable: {
            type: Boolean,
            default: true
        },
        saving: {
            type: Boolean,
            default: false
        },
        error: {
            type: String,
            default: ""
        }
    }
}
</script>
