<template>
    <label
        :class="{ [`form-${type}`]: true, [`input-${size}`]: true }"
        class="nibnut-toggle-input"
    >
        <input
            v-bind="$props"
            v-on="$listeners"
        />
        <i class="form-icon"></i><slot></slot>
    </label>
</template>

<script>
export default {
    name: "BaseToggleInput",
    mounted () {
        this.toggle_indeterminate()
    },
    watch: {
        indeterminate: "toggle_indeterminate"
    },
    methods: {
        toggle_indeterminate () {
            const node = this.$el.querySelector("input[type='checkbox']")
            if(node) node.indeterminate = this.indeterminate
        }
    },
    props: {
        id: {
            type: String,
            validator: prop => !!prop
        },
        name: {
            type: String,
            validator: prop => !!prop,
            required: true
        },
        indeterminate: {
            default: false
        },
        checked: {
            default: false
        },
        type: {
            type: String,
            validator: prop => !!prop && !!prop.match(/^(radio|checkbox)$/i),
            default: "radio"
        },
        size: {
            type: String,
            validator: prop => !!prop && !!prop.match(/^(sm|md|lg)$/i),
            default: "md"
        },
        disabled: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/app";

.form-checkbox.nibnut-toggle-input {
    input:disabled + .form-icon,
    input.disabled + .form-icon,
    &.three-states input + .form-icon {
        background: transparent;
        border-color: transparent;
        border: 0;
        &::before {
            content: "\f05e";
            font-family: Line Awesome Free;
            font-weight: 900;
            -webkit-font-smoothing: antialiased;
            display: inline-block;
            font-style: normal;
            font-variant: normal;
            text-rendering: auto;
            height: auto;
            left: 50%;
            margin-left: 0;
            margin-top: 0;
            position: absolute;
            top: 50%;
            width: auto;
            transform: translate(-50%, -50%);
        }
    }
    input:disabled + .form-icon,
    input.disabled + .form-icon {
        color: $link-color;
        cursor: normal;
    }

    &.three-states {
        input:checked + .form-icon {
            background: transparent;
            border-color: transparent;
            border: 0;
            &::before {
                content: "\f058";
                border: 0;
            }
        }
        input:indeterminate + .form-icon {
            &::before {
                content: "\f059";
            }
        }
    }
}
</style>
