<template>
    <form-group
        v-bind="form_group_props"
        class="form-url-or-file-input"
    >
        <template v-slot:read_only><slot name="read_only"></slot></template>
        <template v-slot:empty-value>
            <slot name="empty-value">
                <base-link
                    v-if="standardized_value && standardized_value.url"
                    :href="standardized_value.url"
                    target="_blank"
                >
                    {{ standardized_value.name || standardized_value.url }}
                </base-link>
            </slot>
        </template>
        <template v-slot:label><slot name="label"></slot></template>
        <template v-slot:hint><slot name="hint"></slot></template>
        <input-group>
            <template v-slot:left_addon>
                <base-dropdown
                    :id="`${identifier}-input-mode`"
                    :name="`${identifier}-input-mode`"
                    :value="input_mode"
                    :size="size"
                    :options="input_modes"
                    :disabled="!editable || disabled"
                    :required="true"
                    class="flex-static"
                    @input="change_input_mode"
                />
            </template>
            <template v-slot:right_addon>
                <default-button
                    v-if="standardized_value"
                    :size="size"
                    :title="translate('Clear Value')"
                    class="input-group-btn"
                    @click.prevent.stop
                >
                    <open-icon glyph="times" />
                </default-button>
                <base-link
                    v-if="standardized_value.url"
                    :href="standardized_value.url"
                    target="_blank"
                    :title="translate('View')"
                    :class="{ [`btn-${size}`]: true }"
                    class="btn input-group-btn"
                >
                    <open-icon glyph="external-link-alt" />
                </base-link>
            </template>
            <base-input
                ref="field"
                :id="identifier"
                :name="name"
                :value="standardized_input_value"
                :placeholder="standardized_placeholder"
                :aria-describedby="!!has_slot('hint')?`${identifier}-description`:false"
                :readonly="(input_mode === INPUT_MODE_FILE)"
                :disabled="!editable || disabled"
                :required="required && touched"
                :size="size"
                :autocomplete="autocomplete"
                :autocapitalize="autocapitalize"
                :class="{ autofilled: maybeAutofilled, focus: dragging }"
                @focus="focus"
                @touchstart="touchstart"
                @keyup="$emit('keyup', $event)"
                @keydown="keydown"
                @blur="blur"
                @click="clicked"
            />
        </input-group>
        <input
            v-if="file_field_ready"
            ref="file"
            :name="`${identifier}-proxy-file-input`"
            type="file"
            :accept="accepts"
            :disabled="disabled"
            class="nibnut-proxied-control"
            @change="upload_single_file"
        />
        <progress
            v-if="uploading"
            :value="uploaded"
            max="100"
            class="progress">
        </progress>
    </form-group>
</template>

<script>
import is_nibnut_component from "@/nibnut/mixins/IsNibnutComponent"
import is_alpha_numerical_input from "@/nibnut/mixins/IsAlphaNumericalInput"

import FormGroup from "@/nibnut/components/Inputs/FormGroup"
import InputGroup from "@/nibnut/components/Inputs/InputGroup"
import BaseInput from "@/nibnut/components/Inputs/BaseInput"
import BaseDropdown from "@/nibnut/components/Inputs/BaseDropdown"
import BaseLink from "@/nibnut/components/Links/BaseLink"
import DefaultButton from "@/nibnut/components/Buttons/DefaultButton"
import OpenIcon from "@/nibnut/components/OpenIcon"

const INPUT_MODE_URL = 0
const INPUT_MODE_FILE = 1

const can_drag_and_drop = () => {
    const div = document.createElement("div")
    return (("draggable" in div) || (("ondragstart" in div) && ("ondrop" in div))) && ("FormData" in window) && ("FileReader" in window)
}
const event_prevent_and_stop = event => {
    event.preventDefault()
    event.stopPropagation()
}
const noop_dnd_events = ["drag", "dragstart", "dragend", "dragover"]
let listening_to_dnd = false

export default {
    name: "FormUrlOrFileInput",
    mixins: [is_nibnut_component, is_alpha_numerical_input],
    components: {
        FormGroup,
        InputGroup,
        BaseInput,
        BaseDropdown,
        BaseLink,
        DefaultButton,
        OpenIcon
    },
    created () {
        this.INPUT_MODE_URL = INPUT_MODE_URL
        this.INPUT_MODE_FILE = INPUT_MODE_FILE
    },
    mounted () {
        this.reset_input_mode()
        this.reset_shell_value()
    },
    beforeDestroy () {
        this.remove_dnd_event_listeners()
    },
    watch: {
        editable: "maybe_focus_field",
        value: "reset_shell_value",
        "value.url": "reset_input_mode"
    },
    methods: {
        add_dnd_event_listeners () {
            if(!listening_to_dnd && can_drag_and_drop()) {
                listening_to_dnd = true
                noop_dnd_events.forEach(event_name => {
                    this.$refs.field.$el.addEventListener(event_name, event_prevent_and_stop)
                })

                this.$refs.field.$el.addEventListener("dragenter", this.drag_enter_handler)
                this.$refs.field.$el.addEventListener("dragleave", this.drag_leave_handler)
                this.$refs.field.$el.addEventListener("drop", this.drop_handler)
            }
        },
        remove_dnd_event_listeners () {
            if(listening_to_dnd && can_drag_and_drop()) {
                listening_to_dnd = false
                noop_dnd_events.forEach(event_name => {
                    this.$refs.field.$el.removeEventListener(event_name, event_prevent_and_stop)
                })

                this.$refs.field.$el.removeEventListener("dragenter", this.drag_enter_handler)
                this.$refs.field.$el.removeEventListener("dragleave", this.drag_leave_handler)
                this.$refs.field.$el.removeEventListener("drop", this.drop_handler)
            }
        },
        reset_shell_value () {
            this.shell_value = null
            if(!this.value) {
                this.$store.dispatch(
                    "FETCH_RECORD_SHELL",
                    { entity: "attachment" }
                ).then(record => {
                    this.shell_value = {
                        ...record,
                        ...this.shellData
                    }
                })
            }
        },
        set_input_mode (input_mode) {
            this.input_mode = input_mode

            if(this.input_mode === INPUT_MODE_FILE) this.add_dnd_event_listeners()
            else this.remove_dnd_event_listeners()
        },
        reset_input_mode () {
            const value = this.standardized_value
            if(value && value.url && value.url.match(/drive\.google\.com/i)) this.set_input_mode(INPUT_MODE_FILE)
            else this.set_input_mode(INPUT_MODE_URL)
        },
        change_input_mode (event) {
            const option = this.input_modes.find(option => (option.id === parseInt(event.target.value)))
            this.set_input_mode(option ? option.id : INPUT_MODE_URL)
        },
        focus_field () {
            this.touched = true
            if(this.$refs.field.$el && (this.input_mode === INPUT_MODE_URL)) {
                if(this.$refs.field.$el.focus) this.$refs.field.$el.focus()
                if(this.$refs.field.$el.select) this.$refs.field.$el.select()
            }
        },
        maybe_focus_field () {
            if(this.editable) setTimeout(this.focus_field, 150)
        },
        maybe_autoselect (event) {
            if(this.autoSelect && (this.input_mode === INPUT_MODE_URL)) event.target.select()
        },
        focus (event) {
            this.maybe_autoselect(event)
            this.$emit("focus")
        },
        blur (event) {
            if(this.alphaNumeric && this.iOS) this.touching = false
            const value = this.standardized_value
            value.path = event.target.value
            if(this.input_mode !== INPUT_MODE_FILE) this.$emit("input", value, this.name)
        },
        clicked (event) {
            if(this.input_mode === INPUT_MODE_FILE) this.$refs.file.click()
        },
        upload (file_list) {
            if(this.disabled || !file_list || !file_list.length) return
            const value = this.standardized_value
            value.path = file_list[0]
            value.name = value.path.name
            this.$emit("input", value, this.name)
        },
        drag_enter_handler (event) {
            event_prevent_and_stop(event)
            if(this.disabled) return
            this.height = this.$refs.field.$el.offsetHeight
            this.dragging = true
        },
        drag_leave_handler (event) {
            event_prevent_and_stop(event)
            this.dragging = false
        },
        drop_handler (event) {
            event_prevent_and_stop(event)
            if(this.disabled) return
            this.upload(event.dataTransfer.files)
            this.dragging = false
        },
        upload_single_file () {
            if(this.disabled) return
            this.upload(this.$refs.file.files)
            this.file_field_ready = false
            this.$nextTick(() => {
                this.file_field_ready = true
            })
        }
    },
    computed: {
        form_group_props () {
            return {
                id: this.id,
                name: this.name,
                value: this.value,
                required: this.required,
                editable: this.editable,
                error: this.error,
                waiting: this.saving,
                horizontal: this.horizontal
            }
        },
        standardized_value () {
            if(this.value) return this.value
            return this.shell_value || {}
        },
        standardized_input_value () {
            const value = this.standardized_value
            if(!value) return ""
            if(this.input_mode === INPUT_MODE_FILE) return value.name
            return value.url
        },
        standardized_placeholder () {
            if((this.input_mode === INPUT_MODE_FILE) && !this.placeholder) return this.translate("Drop file here...")
            return this.placeholder
        },
        input_modes () {
            return [
                { id: 0, name: this.translate("URL") },
                { id: 1, name: this.translate("File") }
            ]
        }
    },
    props: {
        id: {
            type: String,
            validator: prop => !!prop
        },
        name: {
            type: String,
            validator: prop => !!prop,
            required: true
        },
        value: {
            default: null
        },
        shellData: {
            type: Object,
            default () {
                return {}
            }
        },
        placeholder: {
            type: String,
            default: ""
        },
        autocomplete: {
            type: String,
            default: ""
        },
        autocapitalize: {
            type: String,
            validator: prop => !!prop && !!prop.match(/^(none|sentences|words|characters)$/i),
            default: "sentences"
        },
        accepts: { // mime types
            type: String,
            default: ""
        },
        size: {
            type: String,
            validator: prop => !!prop && !!prop.match(/^(sm|md|lg)$/i),
            default: "md"
        },
        required: {
            type: Boolean,
            required: true
        },
        disabled: { // disable input field
            type: Boolean,
            default: false
        },
        uploading: {
            type: Boolean,
            default: false
        },
        uploaded: {
            type: Number,
            default: 0
        },
        editable: { // read-only
            type: Boolean,
            default: true
        },
        saving: {
            type: Boolean,
            default: false
        },
        error: {
            type: String,
            default: ""
        },
        maybeAutofilled: {
            type: Boolean,
            default: false
        },
        horizontal: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            touched: false,
            input_mode: INPUT_MODE_URL,
            dragging: false,
            file_field_ready: true,
            shell_value: null
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.form-url-or-file-input {
    .form-input.focus {
        @include control-shadow();
        border-color: $primary-color;
    }
    .progress {
        vertical-align: top;
    }
}
</style>
