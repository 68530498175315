<template>
    <div
        :class="{ expanded: is_expanded, borderless }"
        class="card card-expandable"
    >
        <div class="card-header">
            <div
                class="card-title h5 d-flex align-items-center"
                @click.exact.stop="maybe_toggle"
                @click.shift.stop="maybe_toggle_all"
            >
                <div
                    class="flex-grow"
                >
                    <slot name="title" :expanded="is_expanded || !expandable"></slot>
                </div>
                <div v-if="expandable || has_slot('controls')" class="flex-static">
                    <slot name="controls" :expanded="is_expanded"></slot>
                    <default-button
                        v-if="expandable"
                        ref="button"
                        flavor="link"
                        size="sm"
                        class="expanded-indicator"
                        @click.exact.prevent.stop="maybe_toggle"
                        @click.shift.prevent.stop="maybe_toggle_all"
                    >
                        <open-icon glyph="angle-down" />
                    </default-button>
                </div>
            </div>
        </div>
        <div
            class="card-body"
        >
            <slot name="static-expandable-content"></slot>
            <div class="expandable-content">
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>
import is_nibnut_component from "@/nibnut/mixins/IsNibnutComponent"

import DefaultButton from "@/nibnut/components/Buttons/DefaultButton"
import OpenIcon from "@/nibnut/components/OpenIcon"

export default {
    name: "Expandable",
    mixins: [is_nibnut_component],
    components: {
        DefaultButton,
        OpenIcon
    },
    mounted () {
        this.apply_expansion()
    },
    watch: {
        scrolledTo: "expand",
        appliedExpansion: "apply_expansion",
        expanded: "set_expanded"
    },
    methods: {
        maybe_toggle (event) {
            if(event.target.closest(".form-checkbox")) event.stopPropagation()
            else if(this.expandable) {
                this.is_expanded = !this.is_expanded
                this.$emit("toggled", this.is_expanded)
            }
        },
        maybe_toggle_all () {
            if(this.expandable) this.$emit("toggle-all")
        },
        expand () {
            this.is_expanded = true
        },
        apply_expansion () {
            this.is_expanded = this.appliedExpansion || !this.expandable
        },
        set_expanded () {
            if(this.expanded !== null) this.is_expanded = this.expanded
        }
    },
    props: {
        expandable: {
            type: Boolean,
            default: true
        },
        borderless: {
            type: Boolean,
            default: false
        },
        scrolledTo: {
            type: Boolean,
            default: false
        },
        appliedExpansion: {
            type: Boolean,
            default: false
        },
        expanded: {
            validator: prop => (prop === null) || (prop === false) || (prop === true),
            default: null
        }
    },
    data () {
        return {
            is_expanded: false
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.card.card-expandable {
    & > .card-header {
        h5, .h5, h6, .h6 {
            margin-bottom: 0;

            .btn.btn-link {
                font-size: inherit;
                font-style: inherit;
                &.btn-regular {
                    color: inherit;
                }
            }
        }
        .btn.expanded-indicator {
            flex: 0 0 auto;

            i.las {
                transition: transform .3s ease;
            }
        }
        .form-label {
            display: inline-block;
        }
    }
    & > .card-body {
        display: flex;
        flex-direction: column;

        &:last-child {
            padding-bottom: 0;
            transition: padding-bottom .3s ease;
        }

        & > .expandable-content {
            flex: 0 1 0px;
            height: auto;
            overflow: hidden;
            transition: all .3s ease;
        }
    }
    &.borderless {
        border: 0;

        & > .card-header,
        & > .card-body {
            padding-left: 0;
            padding-right: 0;
        }
    }
    &.expanded {
        & > .card-header {
            .btn.expanded-indicator {
                i.las {
                    transform: rotate(180deg);
                }
            }
        }
        & > .card-body {
            &:last-child {
                padding-bottom: $layout-spacing-lg;
            }
            & > .expandable-content {
                flex: 1 0 auto;
                overflow: visible;
            }
        }
    }
}
</style>
