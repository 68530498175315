<template>
    <person-picker
        v-if="!person"
        v-bind="$props"
        @input="save"
        @create="edit_manually"
    >
        <template v-slot:read_only><slot name="read_only"></slot></template>
        <template v-slot:label><slot name="label"></slot></template>
        <template v-slot:hint><slot name="hint"></slot></template>
        <template v-slot:option="{ option }"><slot name="option" :option="option"></slot></template>
        <template v-slot:right_addon="{ option }"><slot name="right_addon" :option="option"></slot></template>
    </person-picker>
    <div
        v-else
        class="columns"
    >
        <form-input
            id="first_name"
            name="first_name"
            :value="person.first_name"
            :editable="editable"
            :required="false"
            :placeholder="translate('First Name')"
            class="column col-sm-12"
            @input="save"
        />
        <form-input
            id="middle_name"
            name="middle_name"
            :value="person.middle_name"
            :editable="editable"
            :required="false"
            :placeholder="translate('Middle Name')"
            class="column col-sm-12"
            @input="save"
        />
        <form-input
            id="last_name"
            name="last_name"
            :value="person.last_name"
            :editable="editable"
            :required="false"
            :placeholder="translate('Last Name')"
            class="column col-sm-12"
            @input="save"
        />
        <form-input
            id="name_suffix"
            name="name_suffix"
            :value="person.name_suffix"
            :editable="editable"
            :required="false"
            :placeholder="translate('Suffix')"
            class="column col-sm-12"
            @input="save"
        />
    </div>
</template>

<script>
import addl_string_utilities from "@/custom/mixins/AddlStringUtilities"

import PersonPicker from "./PersonPicker"
import FormInput from "@/nibnut/components/Inputs/FormInput"

export default {
    name: "PersonNameInput",
    mixins: [addl_string_utilities],
    components: {
        PersonPicker,
        FormInput
    },
    watch: {
        value: "value_changed"
    },
    methods: {
        value_changed () {
            if(this.value) this.person = null
        },
        edit_manually (name) {
            this.person = this.parse_person_name(name)
            this.$emit("input", this.person, "person")
        },
        save (value, field) {
            this.$emit("input", value, field)
        }
    },
    props: {
        ...PersonPicker.props
    },
    data () {
        return {
            person: null
        }
    }
}
</script>
