<template>
    <div
        class="calendar agenda"
    >
        <div class="calendar-nav navbar">
            <base-button
                flavor="link"
                size="lg"
                class="btn-action"
                tabindex="-1"
                @click.prevent="previous_month"
            >
                <open-icon
                    glyph="angle-left"
                    :title="$root.translate('Previous month')"
                />
            </base-button>
            <div
                v-if="current_page_timestamp"
                class="navbar-primary"
            >
                {{ current_page_date | nibnut.date("MMM YYYY") }}
            </div>
            <base-button
                flavor="link"
                size="lg"
                class="btn-action"
                tabindex="-1"
                @click.prevent="next_month"
            >
                <open-icon
                    glyph="angle-right"
                    :title="$root.translate('Next month')"
                />
            </base-button>
        </div>

        <div class="calendar-container">
            <div class="calendar-header">
                <div
                    v-for="weekday in weekdays"
                    :key="weekday"
                    class="calendar-date"
                >
                    {{ weekday }}
                </div>
            </div>

            <div class="calendar-body">
                <div
                    v-for="grid_data in grid"
                    :key="grid_data.id"
                    :class="grid_data.cell_classes"
                    class="calendar-date"
                    @click.prevent.stop="$emit('click', grid_data.date)"
                >
                    <div class="agenda-day text-small">{{ grid_data.date | nibnut.date("D") }}</div>
                    <ul class="agenda-items">
                        <li
                            v-for="item in items_for_date(grid_data.date)"
                            :key="item.id"
                        >
                            <slot name="item" :record="item"></slot>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script type="text/javascript">
import handles_dates from "@/nibnut/mixins/HandlesDates"

import BaseButton from "@/nibnut/components/Buttons/BaseButton"
import OpenIcon from "@/nibnut/components/OpenIcon"

export default {
    name: "Agenda",
    mixins: [handles_dates],
    components: {
        BaseButton,
        OpenIcon
    },
    mounted () {
        this.reset()
    },
    watch: {
        current_page_timestamp: "page_changed"
    },
    methods: {
        reset () {
            const first_selected_date = this.$dayjs().startOf("day")
            this.current_page_timestamp = this.$dayjs(first_selected_date).startOf("month").valueOf()
        },
        previous_month () {
            this.current_page_timestamp = this.current_page_date.subtract(1, "month").valueOf()
        },
        next_month () {
            this.current_page_timestamp = this.current_page_date.add(1, "month").valueOf()
        },
        page_changed () {
            this.$emit("input", { from: this.current_page_date, to: this.current_page_date.endOf("month") })
        },
        items_for_date (date) {
            return this.items.filter(item => {
                return this.$dayjs(item.scheduled_at).isSame(date, "day")
            })
        }
    },
    computed: {
        current_page_date () {
            return this.$dayjs.unix(this.current_page_timestamp / 1000)
        },
        weekdays () {
            return [
                this.$root.translate("Sun"),
                this.$root.translate("Mon"),
                this.$root.translate("Tue"),
                this.$root.translate("Wed"),
                this.$root.translate("Thu"),
                this.$root.translate("Fri"),
                this.$root.translate("Sat")
            ]
        },
        grid () {
            const today = this.$dayjs().startOf("day")

            const dates = []
            if(this.current_page_timestamp) {
                let date = this.current_page_date.subtract(this.current_page_date.day(), "days")
                const current_month = this.current_page_date.month()

                for(let loop = 0; loop < 35; loop++) {
                    const month = date.month()
                    const cell_classes = {}
                    if((month < current_month) || ((month === 11) && (current_month === 0))) cell_classes["prev-month"] = true
                    else if((month > current_month) || ((month === 0) && (current_month === 12))) cell_classes["next-month"] = true

                    const button_classes = {}
                    if(date.isSame(today, "day")) button_classes["date-today"] = true

                    const disabled = false

                    dates.push({
                        id: this.$dayjs(date).valueOf(),
                        date,
                        cell_classes,
                        button_classes,
                        disabled
                    })
                    date = this.$dayjs(date).add(1, "days")
                }
            }
            return dates
        }
    },
    props: {
        items: {
            type: Array,
            required: true
        }
    },
    data () {
        return {
            current_page_timestamp: null // stores the first day of the month being viewed, **as a timestamp*** so it is reactive
        }
    }
}
</script>

<style lang="scss">
@use "sass:math";
@import "@/assets/sass/variables";

.calendar.agenda {
    .calendar-nav.navbar {
        padding-bottom: 0;
    }
    .calendar-container {
        .calendar-header {
            padding-top: 0;
        }
        .calendar-body {
            padding: 0;
            background-color: $body-bg;

            & > .calendar-date {
                display: flex;
                flex-direction: column;
                border-right: $border-width solid $border-color;
                border-bottom: $border-width solid $border-color;
                height: 5rem;
                cursor: pointer;

                &.prev-month .agenda-day,
                &.next-month .agenda-day {
                    color: $gray-color;
                }

                ul.agenda-items {
                    overflow: auto;
                    flex: 1 1 auto;
                    padding-top: 2px;
                    padding-bottom: 2px;
                    margin: 0;

                    & > li {
                        &:first-child {
                            margin-top: 0;
                        }
                    }
                    .chip {
                        width: 100%;
                        cursor: pointer;
                        overflow: visible;

                        .avatar {
                            flex: 0 0 auto;
                            font-weight: bold;
                        }
                        abbr {
                            flex: 0 1 auto;
                            text-overflow: ellipsis;
                            overflow: hidden;
                        }
                    }
                }
            }
        }
    }
}
</style>
