<template>
    <div class="notepad-v2">
        <header>
            <div class="columns">
                <form-dropdown
                    id="author_id"
                    name="author_id"
                    :value="state.author_id"
                    :options="[]"
                    :empty-label="translate('All Authors')"
                    :required="false"
                    class="column col-auto"
                    @input="filter_by('author_id', $event)"
                />
                <form-dropdown
                    id="filter"
                    name="filter"
                    :value="state.filter"
                    :options="filters"
                    :empty-label="translate('All Categories')"
                    :required="false"
                    class="column col-auto"
                    @input="filter('category_id', $event)"
                />
                <form-input
                    id="search"
                    name="search"
                    v-model="query"
                    glyph="search"
                    glyph-position="left"
                    :live="true"
                    :required="false"
                    class="column"
                />
                <div class="column col-auto">
                    <div class="btn-group">
                        <default-button
                            @click.prevent.stop
                        >
                            {{ translate("Contact") }}
                        </default-button>
                        <default-button
                            @click.prevent.stop
                        >
                            {{ translate("File") }}
                        </default-button>
                        <default-button
                            :active="true"
                            @click.prevent.stop
                        >
                            {{ translate("All") }}
                        </default-button>
                    </div>
                </div>
            </div>
        </header>
        <div class="columns">
            <div v-if="state.total" class="column notepad-notes-list">
               <ul>
                    <note-pad-note
                        v-for="note in filtered_rows"
                        :key="note.id"
                        :topic-entity="topicEntity"
                        :topic-entity-id="topicEntityId"
                        :note="note"
                        :selected="!!edited_record && (note.id === edited_record.id)"
                        @click="edited_record = $event"
                    />
                </ul>
            </div>
            <div v-if="!!edited_record" class="column notepad-current-note-view">
                <div class="columns text-small mb-8">
                    <div class="column">
                        {{ edited_record.updated_at | nibnut.date }}&nbsp;@&nbsp;{{ edited_record.updated_at | nibnut.date("hh:mm A") }}&nbsp;&bull;&nbsp;{{ edited_record.computed_author_name }}
                        <span v-if="!!edited_record.category_ids && !!edited_record.category_ids.length">
                            &nbsp;&bull;&nbsp;
                            <span
                                v-for="category_id in edited_record.category_ids"
                                :key="category_id"
                                class="chip chip-sm"
                            >
                                {{ term_name_by_id(category_id) }}
                            </span>
                        </span>
                    </div>
                    <div v-if="!!edited_record.pinned_until" class="column col-auto">
                        <default-button
                            v-if="edited_record.pinned_forever"
                            flavor="link"
                            size="xs"
                            @click.prevent.stop="change_pin"
                        >
                            {{ translate("pinned forever") }}
                        </default-button>
                        <default-button
                            v-else
                            flavor="link"
                            size="xs"
                            @click.prevent.stop="change_pin"
                        >
                            {{ translate("pinned until") }} {{ edited_record.pinned_until | nibnut.date("MM/DD/YYYY") }}
                        </default-button>
                    </div>
                    <div class="column col-auto">
                        <default-button
                            size="xs"
                            @click.prevent.stop
                        >
                            <open-icon glyph="pencil-alt" />
                        </default-button>
                    </div>
                </div>
                <form-editor
                    v-if="editing"
                    ref="editor"
                    id="content"
                    name="content"
                    :value="edited_record.content"
                    size="lg"
                    :data-version="edited_record.id"
                    :editable="true"
                    :required="false"
                    :show-on-ready="true"
                    :saving="saving('content')"
                    :error="has_error('content')"
                    @input="save"
                />
                <div v-else class="notepad-note-view">
                    <div v-html="edited_record.content"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex"
import orderBy from "lodash/orderBy"
import debounce from "lodash/debounce"

import { is_remote_data_table_source, handles_saving } from "@/nibnut/mixins"

import {
    FormDropdown,
    FormInput,
    FormEditor,
    DefaultButton,
    OpenIcon
} from "@/nibnut/components"
import NotePadNote from "./NotePadNote"

export default {
    name: "NotePadNew",
    mixins: [is_remote_data_table_source, handles_saving],
    components: {
        FormDropdown,
        FormInput,
        FormEditor,
        DefaultButton,
        OpenIcon,
        NotePadNote
    },
    watch: {
        search: "update_query",
        query: "debounce_query",
        $route: "update_query"
    },
    methods: {
        pre_load () {
            this.set_state_value("computed_property_id", this.propertyId)
            this.confirming_deletion = false
            if((this.topicEntity !== this.state.topic_entity) || (this.topicEntityId !== this.state.topic_id)) {
                this.set_state_values({
                    topic_entity: this.topicEntity,
                    topic_id: this.topicEntityId
                })
            }
        },
        post_load () {
            if(!this.state.total) this.new_note()
            else {
                const notes = this.entity_records(this.entity, this.current_records_ids)
                this.edited_record = notes.length ? notes[0] : null
            }
            this.$emit("loaded", this.state.found)
        },
        sort_rows (rows) {
            return orderBy(rows, ["pinned_until", "updated_at"], ["desc", "desc"])
        },
        filter_rows (rows) {
            if(this.state.computed_property_id) return rows.filter(row => row.computed_property_id === this.state.computed_property_id)
            return rows
        },
        update_query () {
            if(this.query !== this.state.search) this.query = this.state.search
        },
        debounce_query: debounce(function () {
            this.search(this.query)
        }, 500),
        term_name_by_id (id) {
            return (this.entity_record("term", id) || {}).name
        }
    },
    computed: {
        ...mapGetters(["entity_record"]),
        state_identifier () {
            return `notepad-${this.topicEntity}-${this.topicEntityId}`
        },
        can_refresh () {
            return !!this.profile_id && !!this.topicEntity && !!this.topicEntityId
        },
        rows () {
            return [
                ...this.entity_records(this.entity, this.current_records_ids),
                ...this.additionalRows
            ]
        },
        fields () {
            return ["fieldset::default"]
        },
        filters () {
            const vocabulary = this.constants("vocabularies", "VOCABULARY_LOG_CATEGORIES").id
            return orderBy(
                this.entity_records("term").filter(term => term.vocabulary === vocabulary),
                "name",
                "asc"
            )
        },
        quick_pins () {
            const today = this.$dayjs()
            return [
                { label: this.translate("Forever"), value: this.constants("MAX_DATE") },
                { label: this.translate("1 week"), value: today.add(1, "week").format("YYYY-MM-DD") },
                { label: this.translate("2 weeks"), value: today.add(2, "weeks").format("YYYY-MM-DD") },
                { label: this.translate("3 weeks"), value: today.add(3, "weeks").format("YYYY-MM-DD") },
                { label: this.translate("1 month"), value: today.add(1, "month").format("YYYY-MM-DD") },
                { label: this.translate("Until..."), value: null }
            ]
        },
        deletable () {
            return this.edited_record && this.edited_record.id && !this.edited_record.read_only && ((this.edited_record.author_id === this.profile.id) || this.is_at_least_administrator)
        },
        term_autosuggest_data () {
            return { fields: ["id", "name"], vocabulary: this.constants("vocabularies", "VOCABULARY_LOG_CATEGORIES").id }
        }
    },
    props: {
        topicEntity: {
            type: String,
            required: true
        },
        topicEntityId: {
            type: Number,
            required: true
        },
        propertyId: {
            type: Number,
            default: 0
        },
        additionalRows: {
            type: Array,
            default () {
                return []
            }
        },
        editable: {
            type: Boolean,
            default: true
        }
    },
    data () {
        return {
            entity: "note",
            relation_ids: ["term"],
            columns: {
                updated_at: { label: "Date", sort: "desc", type: "amount" }
            },
            default_state: {
                per_page: 0,
                page: 1,
                sort_by: ["pinned_until", "updated_at"],
                sort_dir: ["asc", "desc"],
                filter_on: "category_id",
                filter: null,
                archived: false,
                search: "",
                total: 0,
                found: 0,
                author_id: 0,
                topic_entity: null,
                topic_id: 0,
                computed_property_id: 0
            },
            query: "",
            edited_record: null,
            editing: false,
            creating: false,
            confirming_deletion: false,
            quick_pin: false,
            edited_pin: null,
            edited_pinned_date: null,
            editing_pin: false
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.notepad-v2 {
    display: flex;
    flex-direction: column;

    & > header {
        flex: 0 0 auto;
        position: sticky;
        top: 0;
        background: white;
        padding: $unit-4 0;
        margin-bottom: $unit-6;
        z-index: 5;
    }
    & > .columns {
        flex: 1 1 auto;
        .column.notepad-notes-list {
            max-width: 50%;
            ul {
                margin: 0;
                & > li {
                    margin: 0;
                }
            }
        }
        .column.notepad-current-note-view {
            position: sticky;
            top: 0px;
            .card.notepad-note-view {
                // height: 100%;
            }
        }
    }
}
</style>
