<template>
    <div class="data-slider">
        <ul>
            <li
                v-for="record in records"
                :key="record.id"
            >
                <slot :record="record"></slot>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: "DataSlider",
    props: {
        records: {
            type: Array,
            default () {
                return []
            }
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.data-slider {
    & > ul {
        display: flex;
        overflow: auto;
        margin-left: -$unit-2;
        margin-right: -$unit-2;

        & > li {
            flex: 0 1 auto;
            min-width: 300px;
            margin: 0 $unit-2;

            & > .card {
                height: 100%;

                .card-title + .card-subtitle,
                .card-subtitle + .card-subtitle {
                    margin-top: $unit-1;
                }
                label:not(.form-checkbox) {
                    display: block;
                    font-size: 0.6em;
                    color: $gray-color;
                    text-transform: uppercase;

                    .btn {
                        padding-left: 0;
                        padding-right: 0;
                        line-height: normal;
                        height: auto;
                    }
                }
                .card-subtitle > .btn-feedback > .btn {
                    padding-left: 0;
                    padding-right: 0;
                    text-transform: uppercase;
                    text-align: left;
                    line-height: normal;
                    height: auto;

                    span {
                        display: inline-block;
                        width: 100%;
                    }
                }
            }
        }
    }
}
</style>
