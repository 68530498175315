<template>
    <div
        v-if="!editable"
        :class="{ horizontal }"
        class="form-group"
    >
        <slot name="read_only">
            <label
                v-if="has_label"
                :class="{ [`label-${size}`]: true }"
                class="form-label"
                :for="identifier"
            >
                <slot name="label"></slot>
            </label>
            <label
                :class="{ [`label-${size}`]: true }"
                class="form-label disabled-field"
            >
                <slot name="empty-value">{{ value }}</slot>
            </label>
        </slot>
    </div>
    <div
        v-else
        :class="{ horizontal, 'has-error': !!error, 'full-height': fullHeight }"
        class="form-group"
    >
        <label
            v-if="has_label"
            :class="{ required, [`label-${size}`]: true  }"
            class="form-label"
            :for="identifier"
        >
            <slot name="label">{{ name }}</slot>
            <sup v-if="required" class="ml-1">*</sup>
            <loader
                v-if="waiting"
                size="xs"
                color="gray"
                class="ml-2"
            />
        </label>
        <slot></slot>
        <div
            v-if="!!error"
            :id="`${identifier}-description`"
            aria-live="assertive"
            class="form-input-hint"
        >
            {{ error }}
        </div>
        <div
            v-else-if="has_slot('hint')"
            :id="`${identifier}-description`"
            aria-live="polite"
            class="form-input-hint"
        >
            <slot name="hint"></slot>
        </div>
    </div>
</template>

<script>
import is_nibnut_component from "@/nibnut/mixins/IsNibnutComponent"
import profile_utilities from "@/nibnut/mixins/ProfileUtilities"

import Loader from "@/custom/components/Loader"

export default {
    name: "FormGroup",
    mixins: [is_nibnut_component, profile_utilities],
    components: {
        Loader
    },
    computed: {
        has_label () {
            return this.has_slot("label")
        },
        has_hint () {
            return this.has_slot("hint")
        }
    },
    props: {
        id: {
            type: String,
            validator: prop => !!prop
        },
        name: {
            type: String,
            validator: prop => !!prop,
            required: true
        },
        value: {
            default: ""
        },
        required: {
            type: Boolean,
            required: true
        },
        size: {
            type: String,
            validator: prop => !!prop && !!prop.match(/^(sm|md|lg)$/i),
            default: "md"
        },
        editable: {
            type: Boolean,
            default: true
        },
        waiting: {
            type: Boolean,
            default: false
        },
        error: {
            type: String,
            default: ""
        },
        fullHeight: {
            type: Boolean,
            default: false
        },
        horizontal: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.form-group {
    & > label {
        & > .loader {
            display: inline-block;
        }
    }

    &.full-height {
        height: 100%;
        display: flex;
        flex-direction: column;

        & > label, & > div.form-input-hint {
            flex: 0 0 auto;
        }
        & > *:not(label):not(.form-input-hint) {
            flex: 1 0 auto;

        }
    }

    &.horizontal {
        display: flex;
        margin-left: -$layout-spacing;
        margin-right: -$layout-spacing;

        & > * {
            padding-left: $layout-spacing;
            padding-right: $layout-spacing;
            flex: 1 1 auto;
        }
        & > label.form-label:first-child {
            flex: 0 0 auto;
        }
        & > .form-input-hint {
            flex: 0 0 100%;
        }
    }
}
</style>
