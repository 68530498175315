<template>
    <div>
        <div
            v-if="confirming"
            class="columns"
        >
            <div class="column">
                <label class="form-label label-sm text-error text-right">
                    {{ translate("Are you sure?") }}
                </label>
            </div>
            <div class="column col-auto">
                <default-button
                    color="success"
                    size="sm"
                    @click.prevent.stop="confirming = false"
                >
                    {{ translate("No") }}
                </default-button>
                <default-button
                    color="error"
                    size="sm"
                    @click.prevent.stop="confirmed"
                >
                    {{ translate("Yes") }}
                </default-button>
            </div>
        </div>
        <default-button
            v-else
            flavor="link"
            color="error"
            size="sm"
            @click.prevent.stop="confirm_remove"
        >
            <slot>
                <open-icon glyph="times" />
            </slot>
        </default-button>
    </div>
</template>

<script>
import DefaultButton from "@/nibnut/components/Buttons/DefaultButton"
import OpenIcon from "@/nibnut/components/OpenIcon"

export default {
    name: "QuickDeleteButton",
    components: {
        DefaultButton,
        OpenIcon
    },
    methods: {
        confirm_remove () {
            this.confirming = true
        },
        confirmed () {
            this.$emit("confirmed")
            this.confirming = false
        }
    },
    data () {
        return {
            confirming: false
        }
    }
}
</script>
