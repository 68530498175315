const handles_dates = {
    methods: {
        standardized_range_object (date_data) {
            if((typeof date_data === "object") && (typeof date_data.from !== "undefined")) {
                date_data.from = this.$dayjs(date_data.from)
                date_data.to = this.$dayjs(date_data.to)
                return date_data
            }
            const date = this.$dayjs(date_data)
            return { from: date, to: date }
        },
        standardized_date (date_data) {
            if(!date_data) return null
            return this.$dayjs(date_data)
        },
        ceil_date (date, amount, units = "minutes") {
            date = this.$dayjs(date)
            return date.add(amount - (date.get(units) % amount), units).startOf(units)
        }
    }
}

export default handles_dates
