<template>
    <div>
        <h6 class="mb-0">
            {{ translate("Relationships") }}
            <default-button
                v-if="!!person.id && editable"
                flavor="link"
                class="ml-4"
                @click.prevent="add_relationship"
            >
                <open-icon glyph="plus" />
            </default-button>
        </h6>
        <form-token-input
            v-for="relationship_type in relationship_types"
            :key="relationship_type.id"
            :id="`relationship_type-${relationship_type.id}`"
            name="relationship_type"
            :value="relationships_by_type(relationship_type.id)"
            :editable="editable && !!person.id"
            :required="false"
            :can-add="false"
            :horizontal="true"
            class="mt-2 row-head"
        >
            <template v-slot:label>{{ relationship_type.title }}:</template>
            <template v-slot:selection>
                <div
                    v-for="record in relationships_by_type(relationship_type.id)"
                    :key="record.id"
                    class="chip clickable"
                    @click.prevent="edit_related('person_relationship', record)"
                >
                    {{ relation_name(record) }}
                    <default-button
                        @click.stop="$emit('remove', 'person_relationship', record)"
                    >
                        <open-icon glyph="times" />
                    </default-button>
                    <default-button
                        @click.stop="edit_related_contact(record)"
                    >
                        <open-icon glyph="arrow-circle-right" />
                    </default-button>
                </div>
            </template>
            <template v-slot:right-addon>
                <default-button
                    v-if="!reviewing"
                    @click.prevent
                >
                    <open-icon glyph="plus" />
                </default-button>
            </template>
            <template v-slot:read_only>
                <label class="form-label">{{ relationship_type.title }}:</label>
                <label
                    class="form-label disabled-field"
                >
                    <span
                        v-for="record in relationships_by_type(relationship_type.id)"
                        :key="record.id"
                        class="chip"
                    >
                        {{ relation_name(record) }}
                        <default-button
                            v-if="reviewing && is_fake_record_id(record.id)"
                            @click.stop="$emit('remove', 'person_relationship', record)"
                        >
                            <open-icon glyph="times" />
                        </default-button>
                        <default-button
                            v-if="!reviewing"
                            @click.stop="edit_related_contact(record)"
                        >
                            <open-icon glyph="arrow-circle-right" />
                        </default-button>
                    </span>
                </label>
            </template>
        </form-token-input>
    </div>
</template>

<script>
import { mapGetters } from "vuex"

import misc_utilities from "@/nibnut/mixins/MiscUtilities"

import FormTokenInput from "@/nibnut/components/Inputs/FormTokenInput"
import DefaultButton from "@/nibnut/components/Buttons/DefaultButton"
import OpenIcon from "@/nibnut/components/OpenIcon"

export default {
    name: "RelationshipsInput",
    mixins: [misc_utilities],
    components: {
        FormTokenInput,
        DefaultButton,
        OpenIcon
    },
    methods: {
        is_fake_record_id (id) {
            if(typeof id === "number") return false
            const id_as_integer = parseInt(id)
            return isNaN(id_as_integer) || (id !== `${id_as_integer}`)
        },
        add_relationship () {
            this.$emit(
                "add",
                "person_relationship",
                {
                    person_A_relationship: this.constants("person_relationship_types", "RELATIONSHIP_UNKNOWN").id
                }
            )
        },
        relation (person_relation) {
            const person_id = (person_relation.person_A_id === this.person.id) ? person_relation.person_B_id : person_relation.person_A_id
            return this.entity_record("person", person_id)
        },
        relation_name (person_relation) {
            let relation = null
            if(this.person._person_relationship) {
                relation = this.person._person_relationship.find(relation => {
                    return relation === person_relation
                })
                if(relation && relation._person_B_name) return relation._person_B_name
            }
            relation = this.relation(person_relation)
            return relation ? relation.name : ""
        },
        edit_related (entity, record) {
            if(this.editable) this.$emit("edit", entity, record)
        },
        relationships_by_type (type) {
            return this.relationships.filter(person_relation => {
                return (
                    ((person_relation.person_A_id === this.person.id) && (person_relation.person_B_relationship === type)) ||
                    ((person_relation.person_B_id === this.person.id) && (person_relation.person_A_relationship === type))
                )
            })
        },
        edit_related_contact (person_relation) {
            const relation = this.relation(person_relation)
            if(relation) this.$emit("view", relation.id)
        }
    },
    computed: {
        ...mapGetters(["entity_records", "entity_record"]),
        relationship_types () {
            if(!this.person) return []
            const relationship_types = []
            this.relationships.forEach(relationship => {
                const type = (relationship.person_A_id === this.person.id) ? relationship.person_B_relationship : relationship.person_A_relationship
                if(relationship_types.indexOf(type) < 0) relationship_types.push(type)
            })
            const type_definitions = this.constant_options("person_relationship_types")
            return relationship_types.map(relationship_type => {
                return { id: relationship_type, title: type_definitions.find(type_definition => type_definition.id === relationship_type).name }
            })
        },
        relationships () {
            if(!this.person) return []
            const data_source = this.entity_records("person_relationship").concat(this.person._person_relationship || [])
            return data_source.filter(relationship => {
                return (relationship.person_A_id === this.person.id) || (relationship.person_B_id === this.person.id)
            })
        }
    },
    props: {
        person: {
            type: Object,
            required: true
        },
        reviewing: {
            type: Boolean,
            default: false
        },
        editable: {
            type: Boolean,
            default: true
        }
    }
}
</script>
