<template>
    <modal-dialog
        id="ad-hoc-letter-dialog"
        :show.sync="shown"
    >
        <template v-slot:title>
            <span class="h5">{{ translate("Compose Letter To {name}", { name: topic.computed_owner_names || topic.name }) }}</span>
            <div v-if="addressOneLiner" class="h6 text=gray">{{ addressOneLiner }}</div>
        </template>
        <form-template-input
            id="ad-hoc-letter-message"
            :data-version="topic.id"
            name="message"
            topic-type="App\\Outreach"
            v-model="message"
            size="lg"
            :required="true"
        >
            <template v-slot:label>{{ translate("Message") }}</template>
        </form-template-input>

        <template v-slot:footer>
            <default-button
                :disabled="sending"
                class="mx-1"
                @click.prevent="shown = false"
            >
                {{ translate("Cancel") }}
            </default-button>
            <default-button
                :disabled="sending"
                :waiting="sending"
                class="mx-1"
                @click.prevent.stop="send"
            >
                {{ translate("Send") }}
            </default-button>
        </template>
    </modal-dialog>
</template>

<script>
import handles_errors from "@/nibnut/mixins/HandlesErrors"

import ModalDialog from "@/nibnut/components/ModalDialog/ModalDialog"
import DefaultButton from "@/nibnut/components/Buttons/DefaultButton"
import FormTemplateInput from "@/custom/components/FormTemplateInput"

export default {
    name: "AdHocLetterDialog",
    mixins: [handles_errors],
    components: {
        ModalDialog,
        DefaultButton,
        FormTemplateInput
    },
    watch: {
        show: "reset"
    },
    methods: {
        reset () {
            if(this.show) {
                this.message = ""
            }
        },
        send () {
            this.sending = true
            const data = {
                address_id: this.addressId,
                topic_type: this.topicType,
                topic_id: this.topic.id,
                body: this.message
            }
            this.$store.dispatch("CREATE_RECORD", {
                entity: "letter",
                data
            }).then(() => {
                this.shown = false
            }).catch(error => {
                this.$error(error.message)
            })
        }
    },
    computed: {
        shown: {
            get () {
                return this.show
            },
            set (show) {
                this.$emit("update:show", show)
            }
        }
    },
    props: {
        topicType: {
            type: String,
            required: true
        },
        topic: {
            type: Object,
            required: true
        },
        addressId: {
            type: Number,
            required: true
        },
        addressOneLiner: {
            type: String,
            default: ""
        },
        show: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            message: "",
            sending: false
        }
    }
}
</script>
