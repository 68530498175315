<template>
    <div
        v-if="!loading || forceLoader"
        class="nibnut-data-table"
    >
        <div
            v-if="has_slot('controls') || searchable || exportUrl || has_slot('buttons')"
            :class="{ 'pb-8': showHead, 'pb-2': !showHead }"
            class="data-table-controls print-hide"
        >
            <slot name="controls"></slot>
            <data-table-search-box
                v-if="searchable"
                :id="`${id}-searchbox`"
                :glyph="searchGlyph"
                :placeholder="searchPlaceholder"
                v-model="query"
            />
            <div
                v-if="!!exportUrl"
                class="export-button"
            >
                <base-link
                    :href="exportUrl"
                    class="btn btn-link"
                >
                    <open-icon glyph="file-csv" size="lg" :title="translate('Export')" />
                </base-link>
            </div>
            <slot name="buttons"></slot>
        </div>
        <table
            v-if="!!total || !!shellRow"
            :class="{ 'table-striped': striped }"
            class="table"
        >
            <slot
                v-if="showHead"
                name="pre-header"
                :summarized="summarized"
                :selection-mode="selection_mode"
                :print-screen="print_screen"
            ></slot>
            <thead v-if="showHead">
                <tr>
                    <th v-if="!summarized && selection_mode" class="row-selector">
                        <form-toggle-input
                            :id="`${id}-select-all`"
                            name="select-all"
                            type="checkbox"
                            :value="all_rows_selected"
                            :required="false"
                            @click.native.stop
                            @input="toggle_selection(null)"
                        />
                    </th>
                    <th v-if="summarized && !print_screen" class="menu-sort print-hide">
                        <form-select
                            :id="`${id}-sorts`"
                            name="sorts"
                            :value="current_menu_sort"
                            :dataSource="menu_sorts"
                            :empty-label="translate('Custom...')"
                            :required="false"
                            @input="menu_sort"
                        />
                    </th>
                    <data-table-header-cell
                        v-else
                        v-for="(column, field) in columns"
                        :key="field"
                        :field="field"
                        :label="column.label || field"
                        :hint="column.hint || ''"
                        :sort="column_sort(field, column)"
                        :type="column.type"
                        :compact="compactHeaders"
                        :class="column.head_class"
                        @click="sort"
                    />
                </tr>
            </thead>
            <tbody>
                <tr v-if="loading && !forceLoader">
                    <td
                        :colspan="nb_columns"
                        class="loader-container"
                    >
                        <loader size="sm" />
                    </td>
                </tr>
                <tr
                    v-for="row in rows"
                    :key="row[rowIdField]"
                    @click="maybe_click_row(row)"
                    :class="{ clickable, active: row_is_selected(row) }"
                    class="nibnut-hoverable"
                >
                    <td
                        v-if="!summarized && !!selection_mode"
                        class="row-selector"
                    >
                        <form-toggle-input
                            :id="`${id}-select-${row[selectionIdField]}`"
                            name="select"
                            type="checkbox"
                            :value="row_is_selected(row)"
                            :required="false"
                            @click.native.stop
                            @input="toggle_selection(row)"
                        />
                    </td>
                    <td
                        v-if="summarized && !print_screen"
                    >
                        <slot
                            name="summary"
                            :row="row"
                        >
                            {{ row }}
                        </slot>
                    </td>
                    <td
                        v-else
                        v-for="(column, field) in columns"
                        :key="field"
                        :class="column_cell_class(column, row)"
                    >
                        <slot
                            name="tbody"
                            :row="row"
                            :field="field"
                        >
                            {{ row[field] }}
                        </slot>
                    </td>
                </tr>
            </tbody>
            <tfoot
                v-if="!loading && shellRow && !selection_mode"
            >
                <tr>
                    <td
                        v-if="summarized && !print_screen"
                    >
                        <slot
                            name="summary"
                            :row="shellRow"
                        >
                            {{ shellRow }}
                        </slot>
                    </td>
                    <td
                        v-else
                        v-for="(column, field) in columns"
                        :key="field"
                        :class="column_cell_class(column, shellRow)"
                    >
                        <slot
                            name="tbody"
                            :row="shellRow"
                            :field="field"
                        >
                            {{ shellRow[field] }}
                        </slot>
                    </td>
                </tr>
            </tfoot>
            <tfoot
                v-if="!loading && (canAdd || selection_mode)"
            >
                <tr>
                    <td
                        v-if="selection_mode"
                        :colspan="nb_columns"
                    >
                        <slot name="selection-tools"></slot>

                        <div
                            class="text-small text-gray text-center mt-2"
                        >
                            <span v-if="!selection.length">{{ translate("No rows selected") }}</span>
                            <span v-else>
                                <a
                                    @click.prevent="$emit('filter-by-selection')"
                                >
                                    {{ translate("One row selected:::{number} rows selected", { number: selection.length }, selection.length) }}
                                </a>
                                <span> | </span>
                                <a
                                    @click.prevent="$emit('clear-selection')"
                                >
                                    {{ translate("Clear selection") }}
                                </a>
                            </span>
                        </div>
                    </td>
                    <td
                        v-else-if="!shellRow"
                        :colspan="nb_columns"
                        class="text-center data-table-footer-add"
                    >
                        <default-button
                            flavor="link"
                            size="sm"
                            :block="true"
                            @click.prevent="$emit('add')"
                        >
                            <open-icon
                                glyph="plus"
                                class="mr-2"
                            />
                            {{ add_button_title }}
                        </default-button>
                    </td>
                </tr>
            </tfoot>
            <tfoot v-if="has_slot('tfoot')">
                <slot name="tfoot"></slot>
            </tfoot>
        </table>
        <div v-if="!loading && !!total && ((totalPages > 1) || showTotals)">
            <ul
                v-if="totalPages > 1"
                v-shortkey="{ left: ['arrowleft'], right: ['arrowright'] }"
                @shortkey="goto_page(currentPage + (($event.srcKey === 'left') ? -1 : 1))"
                class="pagination"
            >
                <li
                    :class="{ 'd-invisible': !has_prev_page }"
                    class="page-item"
                >
                    <base-link
                        href="#"
                        @click.prevent="goto_page(1)"
                    >
                        <open-icon
                            glyph="chevron-left"
                        />
                    </base-link>
                </li>
                <li
                    v-for="page in pages"
                    :key="page.number"
                    :class="{ 'active': page.number === currentPage }"
                    class="page-item"
                >
                    <base-link
                        v-if="page.delta"
                        href="#"
                        @click.prevent="goto_page(currentPage + page.delta)"
                    >
                        ...
                    </base-link>
                    <base-link
                        v-else
                        href="#"
                        @click.prevent="goto_page(page.number)"
                    >
                        {{ page.number | nibnut.number("0,0") }}
                    </base-link>
                </li>
                <li
                    :class="{ 'd-invisible': !has_next_page }"
                    class="page-item"
                >
                    <base-link
                        href="#"
                        @click.prevent="goto_page(totalPages)"
                    >
                        <open-icon
                            glyph="chevron-right"
                        />
                    </base-link>
                </li>
            </ul>
            <div
                v-if="showTotals"
                :class="{ 'mt-4': (totalPages <= 1) }"
                class="text-small text-gray text-center"
            >
                <span v-if="found !== total">{{ found_caption }} / </span>{{ total_caption }}
            </div>
        </div>
        <div
            v-if="!loading && !total && !shellRow"
            class="empty"
        >
            <slot name="empty">
                <p class="empty-title h5">
                    {{ empty_title }}
                </p>
                <div v-if="canAdd" class="empty-action">
                    <default-button
                        color="primary"
                        @click.prevent="$emit('add')"
                    >
                        <open-icon
                            glyph="plus"
                            class="mr-2"
                        />
                        {{ empty_add_button_title }}
                    </default-button>
                </div>
            </slot>
        </div>
        <div v-if="forceLoader && loading" class="loader-container">
            <loader size="sm" />
        </div>

        <data-table-multi-sort-dialog
            v-if="sort_multiple"
            :show.sync="sort_multiple"
            :columns="sortable_columns"
            :sort-by="sortFields"
            :sort-dir="sortDirections"
            @input="sort"
        />
    </div>
</template>

<script type="text/javascript">
import debounce from "lodash/debounce"
import orderBy from "lodash/orderBy"

import ui_utilities from "@/nibnut/mixins/UiUtilities"
import is_nibnut_component from "@/nibnut/mixins/IsNibnutComponent"

import DataTableSearchBox from "./DataTableSearchBox"
import DataTableHeaderCell from "./DataTableHeaderCell"
import DefaultButton from "@/nibnut/components/Buttons/DefaultButton"
import BaseLink from "@/nibnut/components/Links/BaseLink"
import FormSelect from "@/nibnut/components/Inputs/FormSelect"
import FormToggleInput from "@/nibnut/components/Inputs/FormToggleInput"
import OpenIcon from "@/nibnut/components/OpenIcon"

import Loader from "@/custom/components/Loader"

export default {
    name: "DataTable",
    mixins: [ui_utilities, is_nibnut_component],
    components: {
        DataTableSearchBox,
        DataTableHeaderCell,
        DefaultButton,
        BaseLink,
        FormSelect,
        FormToggleInput,
        Loader,
        OpenIcon,
        DataTableMultiSortDialog: () => import("./DataTableMultiSortDialog")
    },
    watch: {
        search: "update_query",
        query: "debounce_query",
        $route: "update_query"
    },
    methods: {
        update_query () {
            if(this.query !== this.search) this.query = this.search
        },
        debounce_query: debounce(function () {
            this.$emit("search", this.query)
        }, 500),
        sort (field, sort, multiple = false) {
            if(multiple) this.sort_multiple = true
            else this.$emit("sort", field, sort)
        },
        menu_sort (id, field, option) {
            if(this.current_menu_sort === id) return
            if(option.field) this.sort(option.field, option.sort)
            else this.sort(this.sortFields, this.sortDirections, true)
        },
        goto_page (page) {
            this.$emit("page", page)
        },
        maybe_click_row (row) {
            if(this.clickable) this.$emit("click", row)
        },
        row_is_selected (row) {
            if(!this.selection_mode) return false
            return this.selection.indexOf(row[this.selectionIdField]) >= 0
        },
        toggle_selection (row) {
            if(!row) {
                const select = !this.all_rows_selected
                this.rows.forEach(row => {
                    this.$emit("select", row[this.selectionIdField], select)
                })
            } else this.$emit("select", row[this.selectionIdField], !this.row_is_selected(row))
        },
        row_class (row) {
            let css_class = { clickable: this.clickable, active: this.row_is_selected(row) }
            if(this.rowClassFunction) css_class = { ...css_class, ...this.rowClassFunction(row) }
            return css_class
        },
        column_cell_class (column, row) {
            if(!!column.cell_class && (typeof column.cell_class === "function")) return column.cell_class(row)
            return column.cell_class
        },
        column_sort (field, column) {
            const index = this.sortFields.indexOf(field)
            if(index >= 0) return this.sortDirections[index]
            return column.sort // default sort
        }
    },
    computed: {
        summarized () {
            return this.has_slot("summary", { row: (this.rows.length ? this.rows[0] : {}) }) && (this.small_screen || this.compact)
        },
        nb_columns () {
            return Object.keys(this.columns).length + (this.selection_mode ? 1 : 0)
        },
        sortable_columns () {
            const columns = {}
            Object.keys(this.columns).forEach(field => {
                if(this.columns[field].sort !== false) columns[field] = this.columns[field]
            })
            return columns
        },
        current_menu_sort () {
            const sorts = this.sortFields.map((field, index) => {
                return `${field}.${this.sortDirections[index]}`
            })
            if(sorts.length) return sorts.join(">")
            return null
        },
        menu_sorts () {
            let menu_sorts = []
            Object.keys(this.columns).filter(field => {
                return !!this.columns[field].label.trim()
            }).forEach(field => {
                menu_sorts.push({ id: `${field}.asc`, field, sort: "asc", name: this.translate("{column} (low to high)", { column: this.columns[field].label }) })
                menu_sorts.push({ id: `${field}.desc`, field, sort: "desc", name: this.translate("{column} (high to low)", { column: this.columns[field].label }) })
            })
            menu_sorts = orderBy(menu_sorts, "name", "asc")
            if((this.sortFields.length > 1) || ((this.sortFields.length === 1) && !this.columns[this.sortFields[0]])) {
                const name = this.sortFields.map((field, index) => {
                    const column = this.columns[field].label
                    if(this.sortDirections[index] === "asc") return this.translate("{column} (low to high)", { column })
                    return this.translate("{column} (high to low)", { column })
                })
                menu_sorts.push({ id: this.current_menu_sort, field: null, sort: null, name: name.join(", ") })
            }
            return menu_sorts
        },
        pages () {
            const pages = []

            const visible_around = Math.ceil((this.pageRange - 1) / 2)

            let from = this.currentPage - visible_around
            if(from <= 0) from = 1

            let to = from + (this.pageRange - 1)
            if(to > this.totalPages) {
                from -= (to - this.totalPages)
                if(from <= 0) from = 1
                to = this.totalPages
            }

            if((from - 1) >= 1) pages.push({ number: null, delta: -1 })

            for(let loop = from; loop <= to; loop++) pages.push({ number: loop })

            if((this.totalPages - to) >= 1) pages.push({ number: null, delta: 1 })

            return pages
        },
        has_prev_page () {
            return this.currentPage > 1
        },
        has_next_page () {
            return this.currentPage < this.totalPages
        },
        selection_mode () {
            return this.selection !== null
        },
        all_rows_selected () {
            if(!this.selection_mode) return false
            return !this.rows.find(row => this.selection.indexOf(row[this.selectionIdField]) < 0)
        },
        found_caption () {
            return this.translate("{number} {singular_entity_name} found:::{number} {plural_entity_name} found", { number: this.nibnut_filter("nibnut.number", [this.found, "0,0"]), singular_entity_name: this.translate(this.entityName, {}, 1).toLowerCase(), plural_entity_name: this.translate(this.entityName, {}, 2).toLowerCase() }, this.found)
        },
        total_caption () {
            return this.translate("{number} {singular_entity_name}:::{number} {plural_entity_name}", { number: this.nibnut_filter("nibnut.number", [this.total, "0,0"]), singular_entity_name: this.translate(this.entityName, {}, 1).toLowerCase(), plural_entity_name: this.translate(this.entityName, {}, 2).toLowerCase() }, this.total)
        },
        empty_title () {
            return this.emptyTitle || this.translate("No {plural_entity_name} set up yet", { plural_entity_name: this.translate(this.entityName, {}, 2).toLowerCase() })
        },
        empty_add_button_title () {
            return this.emptyAddButtonTitle || this.translate("Add your first {entity_name}...", { entity_name: this.translate(this.entityName, {}, 1).toLowerCase() })
        },
        add_button_title () {
            return this.addButtonTitle || this.translate("Add a new {entity_name}...", { entity_name: this.translate(this.entityName, {}, 1).toLowerCase() })
        }
    },
    props: {
        id: {
            type: String,
            required: true
        },
        entityName: {
            type: String,
            default: "record:::records"
        },
        loading: {
            type: Boolean,
            default: false
        },
        forceLoader: {
            type: Boolean,
            default: false
        },
        columns: {
            type: Object,
            required: true
        },
        sortFields: {
            type: Array,
            required: true
        },
        sortDirections: {
            type: Array,
            required: true
        },
        rows: {
            type: Array,
            required: true
        },
        rowIdField: {
            type: String,
            default: "id"
        },
        rowClassFunction: {
            type: Function,
            default: null
        },
        shellRow: {
            type: [Object, null],
            default () {
                return null
            }
        },
        total: {
            type: Number,
            default: 0
        },
        found: {
            type: Number,
            default: 0
        },

        selection: {
            type: Array,
            default () {
                return null
            }
        },
        selectionIdField: {
            type: String,
            default: "id"
        },

        currentPage: {
            type: Number,
            default: 1
        },
        perPage: {
            type: Number,
            default: 10
        },
        totalPages: {
            type: Number,
            default: 1
        },
        pageRange: {
            type: Number,
            default: 3
        },

        canAdd: {
            type: Boolean,
            default: false
        },
        clickable: {
            type: Boolean,
            default: true
        },
        compact: {
            type: Boolean,
            default: false
        },
        compactHeaders: {
            type: Boolean,
            default: false
        },

        search: {
            type: String,
            default: ""
        },
        searchable: {
            type: Boolean,
            default: true
        },
        searchGlyph: {
            type: String,
            default: "search"
        },
        searchPlaceholder: {
            type: String,
            default: ""
        },
        showHead: {
            type: Boolean,
            default: true
        },
        showTotals: {
            type: Boolean,
            default: true
        },
        striped: {
            type: Boolean,
            default: false
        },

        emptyTitle: {
            type: String,
            default: ""
        },
        emptyAddButtonTitle: {
            type: String,
            default: ""
        },
        addButtonTitle: {
            type: String,
            default: ""
        },

        exportUrl: {
            type: String,
            default: ""
        }
    },
    data () {
        return {
            query: this.search || "", // local version; "search" prop is parent's version, and the one that triggers actual search
            sort_multiple: false
        }
    }
}
</script>

<style lang="scss">
@import "../../../assets/sass/variables";

.nibnut-data-table {
    .data-table-controls {
        position: sticky;
        top: 0;
        display: flex;
        background-color: $body-bg;
        z-index: 50;

        & > div {
            flex: 0 0 auto;
            margin: 0 $layout-spacing;

            &:first-child {
                flex: 1 1 auto;
                margin-left: 0;
            }
            &:last-child {
                margin-right: 0;
            }
            &.data-table-search-box {
                flex: 1 0 1px;
            }
        }

        .data-table-search-box {
            width: 100%;
        }
    }
    .row-selector {
        &, .form-group, .form-checkbox { width: 20px; }

        .form-checkbox {
            padding: 0;
        }
    }
    thead {
        &, & > tr, & > tr th {
            background-color: $body-bg;
        }

        th {
            position: sticky;
            top: 0;
            white-space: nowrap;
            padding: 0;
            z-index: $zindex-1;

            .btn {
                font-weight: bold;

                &:focus,
                &:active,
                &.active {
                    border-color: transparent;
                }
                @include hover-supported {
                    border-color: transparent;
                }
            }

            &.menu-sort {
                padding-left: 0;
                padding-right: 0;
            }
        }
    }
    tbody, tfoot {
        tr {
            &:last-child {
                td { border-bottom: 0; }
            }
        }
    }
    tbody {
        tr {
            td.loader-container {
                padding: $control-padding-y $control-padding-x;
                text-align: center;

                & > .loader {
                    margin: 0 auto;
                }
            }
            td.cell-buttons {
                width: 0;
            }
        }
    }
    tfoot {
        td.data-table-footer-add {
            border-bottom: 0;
            padding: $unit-1 $unit-2;
        }
    }

    .pagination {
        justify-content: center;
    }

    &.non-sticky {
        thead {
            th {
                position: static;
                top: auto;
            }
        }
    }

    .loader-container {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba($bg-color, .75);
        z-index: $zindex-2;

        & > .loader {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
}
@media (max-width: $size-sm) {
    .nibnut-data-table {
        .data-table-controls {
            flex-wrap: wrap;

            & > div {
                flex: 1 1 100%;
                margin: 0;

                &.data-table-search-box {
                    margin-top: $layout-spacing;
                    flex: 1 0 auto;
                    width: auto;
                }
                &.export-button {
                    margin-top: $layout-spacing;
                    flex: 0 0 auto;
                }
            }
        }
    }
}
@media print {
    .nibnut-data-table {
        font-size: 0.8em;

        thead {
            th {
                white-space: normal;
            }
        }
    }
}
</style>
