<template>
    <ul
        role="listbox"
        class="menu"
    >
        <li
            v-for="(item, index) in items"
            :key="index"
            role="option"
            tabindex="-1"
            class="menu-item"
        >
            <slot
                name="suggestion"
                :item="item"
                :handler="select_choice"
            >
                <a
                    href
                    :class="{ active: index === active_choice_index }"
                    tabindex="-1"
                    @mousedown="select_choice(item)"
                    @click.prevent.stop
                >
                    {{ item.label || item }}
                </a>
            </slot>
        </li>
        <slot v-if="!items.length">
            <li class="divider" :data-content="translate('No matches')"></li>
        </slot>
    </ul>
</template>

<script>
export default {
    watch: {
        items () {
            this.active_choice_index = 0
        }
    },
    methods: {
        onKeyDown ({ event }) {
            if(event.key === "ArrowUp") return this.navigate_up()
            if(event.key === "ArrowDown") return this.navigate_down()
            if(event.key === "Enter") return this.select_active_choice()
            return false
        },
        navigate_up () {
            this.active_choice_index = ((this.active_choice_index + this.items.length) - 1) % this.items.length
            return true
        },
        navigate_down () {
            this.active_choice_index = (this.active_choice_index + 1) % this.items.length
            return true
        },
        select_active_choice () {
            const item = this.items[this.active_choice_index]
            this.select_choice(item)
            return !!item
        },
        select_choice (choice) {
            if(choice) {
                if(!choice.id) choice = { id: choice }
                this.command(choice)
            }
        }
    },
    props: {
        items: {
            type: Array,
            required: true
        },
        command: {
            type: Function,
            required: true
        }
    },
    data () {
        return {
            active_choice_index: 0
        }
    }
}
</script>
