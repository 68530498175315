import { mapState } from "vuex"

const ui_utilities = {
    methods: {
        scroll_to (target, delta = 0) {
            if(!target) return
            const scroll_margin = (parseInt(getComputedStyle(target)["scroll-margin-top"]) || 0) + delta
            window.scrollTo({ top: target.getBoundingClientRect().y + window.scrollY - scroll_margin, left: 0, behavior: "smooth" })
        },
        is_dark_color (hex_color) {
            if(!hex_color) return false
            // adapted from https://github.com/jamiebrittain/colourBrightness.js/blob/master/jquery.colourbrightness.js
            let red = 0
            let green = 0
            let blue = 0
            if(hex_color.length === 4) {
                red = parseInt(hex_color[1] + hex_color[1], 16)
                green = parseInt(hex_color[2] + hex_color[2], 16)
                blue = parseInt(hex_color[3] + hex_color[3], 16)
            } else {
                red = parseInt(hex_color.slice(1, 3), 16)
                green = parseInt(hex_color.slice(3, 5), 16)
                blue = parseInt(hex_color.slice(5, 7), 16)
            }
            const brightness = ((red * 299) + (green * 587) + (blue * 114)) / 1000

            return (brightness < 200)
        },
        color_for_background (hex_color, light_color = "white", dark_color = "black") {
            if(this.is_dark_color(hex_color)) return light_color
            return dark_color
        }
    },
    computed: {
        ...mapState(["app_context"]),
        print_screen () {
            const mq = window.matchMedia("print")
            return !!mq && !!mq.matches
        },
        small_screen () {
            return !!this.$mq.match(/^(xs|sm|md|lg)$/i)
        },
        is_public_page () {
            return !this.$route.meta || (!this.$route.meta.login_required && !this.$route.meta.admin_route)
        }
    }
}

export default ui_utilities
