<template>
    <div class="entity-assignments-input">
        <default-button
            v-if="is_visible && !state.found"
            flavor="link"
            size="sm"
            :block="true"
            :disabled="saving"
            :waiting="saving"
            @click.stop.prevent="assign_or_toggle"
        >
            <open-icon glyph="plus-circle" />
            <span v-if="!is_team_leader()">{{ translate("Assign to me") }}</span>
            <span v-else>{{ translate("Assign") }}...</span>
        </default-button>
        <div
            v-else-if="is_visible"
            :class="{ clickable: editable }"
            class="entity-assignments tooltip tooltip-bottom"
            :data-tooltip="tooltip_text"
            @click.stop.prevent="assign_or_toggle"
        >
            <figure
                v-for="row in rows"
                :key="row.id"
                :class="{ 'me': row.user_id === profile.id }"
                class="avatar avatar-sm"
            >
                <loader
                    v-if="saving && (row.user_id === profile.id)"
                    size="xs"
                    color="light"
                />
                <img v-else :src="row.avatar" :alt="row.name">
                <open-icon
                    v-if="!saving || (row.user_id !== profile.id)"
                    glyph="minus-circle"
                    size="2x"
                />
            </figure>
            <figure
                v-if="!assigned_to_me"
                class="avatar avatar-sm bg-primary"
            >
                <loader
                    v-if="saving"
                    size="xs"
                    color="light"
                />
                <open-icon v-else glyph="plus-circle" size="2x" />
            </figure>
        </div>
        <modal-dialog
            v-if="!!topic"
            id="assignment-editor"
            :show.sync="editing"
            @click.native.stop
        >
            <template v-slot:title>
                <span class="h5">{{ translate("Assignments for {name}", { name: topicName || row.name }) }}</span>
            </template>
            <table class="table nibnut-data-table">
                <tbody>
                    <tr
                        v-for="row in rows"
                        :key="row.id"
                    >
                        <td>
                            <figure
                                class="avatar mr-2"
                            >
                                <img :src="row.avatar" :alt="row.name">
                            </figure>
                            {{ row.name }}
                        </td>
                        <td class="text-right no-wrap">
                            <default-button
                                flavor="link"
                                color="error"
                                :disabled="confirming === row.id"
                                :waiting="confirming === row.id"
                                @click.prevent.stop="confirm_delete(row)"
                            >
                                <open-icon glyph="trash" />
                            </default-button>
                        </td>
                    </tr>
                    <tr v-if="assigning">
                        <td>
                            <assignment-input
                                v-model="new_assignment"
                                morph-to="assign_to"
                                :required="false"
                                :option-filter="available_assignee_filter"
                                @click.native.stop
                            />
                        </td>
                        <td class="text-right no-wrap">
                            <default-button
                                color="error"
                                :disabled="creating || !new_assignment.assign_to_id"
                                @click.prevent.stop="assigning = false"
                            >
                                <open-icon glyph="times" />
                            </default-button>
                            <default-button
                                color="success"
                                :waiting="creating"
                                :disabled="creating || !new_assignment.assign_to_id"
                                @click.prevent.stop="create_assignment"
                            >
                                <open-icon glyph="check" />
                            </default-button>
                        </td>
                    </tr>
                    <tr v-else>
                        <td colspan="2">
                            <default-button
                                flavor="link"
                                :block="true"
                                @click.prevent.stop="assign"
                            >
                                <open-icon glyph="plus" />
                                {{ translate("Assign a new user...") }}
                            </default-button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </modal-dialog>
        <confirmation
            v-if="confirming"
            v-bind="confirmation_props"
            @cancel="done_confirming"
            @confirmed="confirmed"
        />
    </div>
</template>

<script>
import orderBy from "lodash/orderBy"

import {
    is_remote_data_table_source,
    confirms
} from "@/nibnut/mixins"
import {
    addl_profile_utilities
} from "@/custom/mixins"

import {
    DefaultButton,
    OpenIcon
} from "@/nibnut/components"
import {
    AssignmentInput,
    Loader
} from "@/custom/components"

export default {
    name: "EntityAssignments",
    mixins: [is_remote_data_table_source, confirms, addl_profile_utilities],
    components: {
        DefaultButton,
        OpenIcon,
        ModalDialog: () => import("@/nibnut/components/ModalDialog/ModalDialog"),
        AssignmentInput,
        Loader
    },
    watch: {
        "topic.id": "reset"
    },
    methods: {
        pre_load () {
            this.set_state_values({
                filter_on: this.topicClass,
                filter: this.topic.id
            })
        },
        sort_rows (rows) {
            const user_id = this.profile.id
            return orderBy(rows, row => {
                if(row.user_id === user_id) return "ZZZZZZZZZZZZZZZZZZ"
                return row.name
            }, "asc")
        },
        assign_or_toggle () {
            if(!this.editable) return
            if(!this.is_team_leader()) {
                const user_id = this.profile.id
                const assignment = this.rows.find(assignment => {
                    return (assignment.user_id === user_id) && (assignment.topic_type === `App\\${this.topicClass}`) && (assignment.topic_id === this.topic.id)
                })
                this.saving = true
                if(assignment) {
                    this.$store.dispatch(
                        "RECORD_DELETE",
                        {
                            entity: this.entity,
                            id: assignment.id
                        }
                    ).then(record => {
                        if(!!record && !!record.uuid) {
                            let index = this.current_records_ids.indexOf(record.uuid)
                            if(index < 0) index = this.current_records_ids.indexOf(record.id)
                            if(index >= 0) {
                                this.current_records_ids.splice(index, 1)
                                this.set_state_values({
                                    total: this.state.total - 1,
                                    found: this.state.found - 1
                                })
                            }
                        }
                    }).catch(error => {
                        this.$error(error.message)
                    }).then(() => {
                        this.saving = false
                    })
                } else {
                    this.$store.dispatch("CREATE_RECORD", {
                        entity: this.entity,
                        data: {
                            user_id,
                            topic_type: `App\\${this.topicClass}`,
                            topic_id: this.topic.id,
                            fields: this.fields
                        }
                    }).then(record => {
                        if(record.id) {
                            this.current_records_ids.push(record.id)
                            this.set_state_values({
                                total: this.state.total + 1,
                                found: this.state.found + 1
                            })
                        }
                    }).catch(error => {
                        this.$error(error.message)
                    }).then(() => {
                        this.saving = false
                    })
                }
            } else {
                this.editing = true
            }
        },
        assign () {
            this.new_assignment = {
                assign_to_type: "",
                assign_to_id: 0
            }
            this.assigning = true
        },
        create_assignment () {
            this.creating = true
            this.$store.dispatch("CREATE_RECORD", {
                entity: this.entity,
                data: {
                    topic_type: `App\\${this.topicClass}`,
                    topic_id: this.topic.id,
                    assign_to: this.new_assignment,
                    fields: this.fields,
                    relation_ids: this.relation_ids
                }
            }).then(assignment => {
                this.$success(this.translate("Assigned to {name}", { name: assignment.name }))
                if(assignment.id) this.current_records_ids.push(assignment.id)
                this.assigning = false
            }).catch(error => {
                this.$error(error.message)
            }).then(() => {
                this.creating = false
            })
        },
        confirm_delete (row) {
            this.confirm(
                {
                    type: "error",
                    title: this.translate("Unassign {name}", { name: row.name }),
                    message: this.translate("Do you really want to unassign this user? There is no undo..."),
                    cancel: this.translate("Keep"),
                    ok: this.translate("Unassign"),
                    delete_data: {
                        entity: this.entity,
                        id: row.id
                    }
                },
                row.id
            )
        },
        confirmed () {
            if(this.confirmation_props.delete_data) {
                this.$store.dispatch(
                    "RECORD_DELETE",
                    this.confirmation_props.delete_data
                ).then(() => {
                    this.done_confirming()
                }).catch(this.receive_error)
            } else this.done_confirming()
        },
        available_assignee_filter (entity, assignee) {
            if(entity === "user") {
                return (!assignee.active_beta_tests || this.is_beta_testing(assignee, "ASSIGNMENTS")) && !this.rows.find(row => row.user_id === assignee.id)
            }
            return true
        }
    },
    computed: {
        can_reload () {
            return !!this.profile_id && !!this.topic && !!this.topic.id
        },
        state_identifier () {
            return `${this.topicClass}-${this.topic.id}-assignments`
        },
        fields () {
            return ["fieldset::assignments"]
        },
        tooltip_text () {
            return this.rows.map(row => row.name).join("\n")
        },
        is_visible () {
            return (this.editable || !!this.state.found) && this.is_beta_testing(this.profile, "ASSIGNMENTS")
        },
        assigned_to_me () {
            const user_id = this.profile.id
            return !!this.rows.find(row => row.user_id === user_id)
        }
    },
    props: {
        topicClass: {
            type: String,
            required: true
        },
        topic: {
            type: Object,
            required: true
        },
        topicName: {
            type: String,
            default: ""
        },
        editable: {
            type: Boolean,
            default: true
        }
    },
    data () {
        return {
            entity: "assignment",
            default_state: {
                per_page: 0,
                page: 1,
                sort_by: null,
                sort_dir: null,
                filter_on: null, // this.topicEntity
                filter: null, // this.topic.id
                archived: false,
                search: "",
                total: 0,
                found: 0
            },
            saving: false,
            editing: false,
            new_assignment: {
                assign_to_type: "",
                assign_to_id: 0
            },
            assigning: false,
            creating: false
        }
    }
}
</script>

<style lang="scss">
.entity-assignments-input {
    .entity-assignments {
        width: 100%;
        display: inline-flex;
        flex-direction: row-reverse;
        justify-content: center;

        & > figure {
            margin-left: -36px;
            z-index: 1;

            &.avatar.bg-primary {
                color: white;
            }

            .la-minus-circle {
                position: absolute;
                left: 0;
                top: 0;
                display: none;
            }
            .loader {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
            }
        }

        &:hover {
            & > figure.avatar.me {
                .la-minus-circle {
                    display: block;
                }
            }
        }
    }

    table td.text-right.no-wrap {
        width: 80px;
    }
}
</style>
