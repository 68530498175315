<template>
    <modal-dialog
        id="bug-reporter"
        :show.sync="shown"
        :dismissable="true"
        class="modal-lg bug-reporter hide-on-screenshots"
    >
        <template v-slot:title>
            <span class="h5">
                <open-icon glyph="bug" />
                {{ $root.translate("Report a Bug or Issue") }}
            </span>
        </template>
        <base-form
            :has-required-fields="true"
            @submit.prevent.stop="submit"
        >
            <form-input
                id="title"
                name="title"
                v-model="report.title"
                :required="true"
                :disabled="sent"
                :placeholder="$root.translate('Name the fiend...')"
            >
                <template v-slot:label>{{ $root.translate("Title the issue") }}</template>
            </form-input>

            <div class="columns">
                <div class="column col-8 col-sm-12 mb-2">
                    <form-textbox
                        id="message"
                        name="message"
                        v-model="report.message"
                        size="lg"
                        :required="true"
                        :disabled="sent"
                        :placeholder="$root.translate('What happened? What were you expecting to happen? Don\'t be shy: the more details, the better!')"
                    >
                        <template v-slot:label>{{ $root.translate("Describe the issue") }}</template>
                    </form-textbox>
                </div>
                <div class="column col-4 col-sm-12 mb-2">
                    <div class="card text-small text-gray mt-1">
                        <div class="card-body">
                            <h6>{{ $root.translate("The following context information will be sent along with your report") }}</h6>
                            <div>
                                <strong>{{ $root.translate("Url") }}: </strong>{{ report.url }}
                            </div>
                            <div>
                                <strong>{{ $root.translate("Browser") }}: </strong>{{ report.user_agent }}
                            </div>
                            <div v-if="!!report.last_system_message">
                                <strong>{{ $root.translate("Last Displayed Error") }}: </strong>{{ report.last_system_message }}
                            </div>
                            <div>
                                <strong>{{ $root.translate("Screen Size") }}: </strong>{{ report.screen_size }}
                            </div>
                            <div>
                                <strong>{{ $root.translate("App Version") }}: </strong>{{ report.version }}
                            </div>
                        </div>
                    </div>
                </div>

                <div v-if="!is_at_least_administrator" class="column col-8 col-sm-12 mb-2">
                    <form-toggle-input
                        id="contact_allowed"
                        name="contact_allowed"
                        type="checkbox"
                        v-model="report.contact_allowed"
                        :disabled="sent"
                        @input="toggle_email"
                    >
                        {{ $root.translate('You can contact me for additional information or questions') }}<span v-if="report.contact_allowed"> {{ $root.translate('at') }}: </span>
                    </form-toggle-input>
                </div>

                <div v-if="!is_at_least_administrator && report.contact_allowed" class="column col-4 col-sm-12 mb-2">
                    <form-input
                        id="email"
                        name="email"
                        v-model="report.email"
                        :required="report.contact_allowed"
                        :placeholder="$root.translate('Contact Email')"
                        :disabled="sent"
                    />
                </div>

                <div v-if="!!report.screenshot" class="column col-12 mb-2">
                    <form-toggle-input
                        id="include_screenshot"
                        name="include_screenshot"
                        type="checkbox"
                        v-model="report.include_screenshot"
                        :disabled="sent"
                    >
                        {{ $root.translate("Include a screenshot") }}<span v-if="report.include_screenshot"> ({{ $root.translate('See below') }})</span>
                    </form-toggle-input>
                </div>
            </div>

            <feedback-button
                type="submit"
                :status="feedback_status"
                :waiting="sending"
                :single-use="false"
                :block="true"
                color="primary"
            >
                <template v-slot:message>
                    <div
                        :class="{ 'text-success': (feedback_status === true), 'text-error': (feedback_status === false) }"
                        class="text-center"
                    >
                        <small>{{ feedback_message }}</small>
                    </div>
                </template>
                {{ $root.translate("Send Report") }}
            </feedback-button>

            <img
                v-if="!sent && report.include_screenshot"
                :src="report.screenshot"
                class="mt-2 bug-reporter-screenshot"
            />
        </base-form>
    </modal-dialog>
</template>

<script>
import { mapState } from "vuex"

import profile_utilities from "@/nibnut/mixins/ProfileUtilities"
import string_utilities from "@/nibnut/mixins/StringUtilities"
import handles_errors from "@/nibnut/mixins/HandlesErrors"

import ModalDialog from "@/nibnut/components/ModalDialog/ModalDialog"
import BaseForm from "@/nibnut/components/BaseForm"
import FormInput from "@/nibnut/components/Inputs/FormInput"
import FormTextbox from "@/nibnut/components/Inputs/FormTextbox"
import FormToggleInput from "@/nibnut/components/Inputs/FormToggleInput"
import FeedbackButton from "@/nibnut/components/Buttons/FeedbackButton"
import OpenIcon from "@/nibnut/components/OpenIcon"

import { toJpeg } from "html-to-image"

export default {
    mixins: [profile_utilities, string_utilities, handles_errors],
    components: {
        ModalDialog,
        BaseForm,
        FormInput,
        FormTextbox,
        FormToggleInput,
        FeedbackButton,
        OpenIcon
    },
    mounted () {
        this.reset()
    },
    watch: {
        show: "reset"
    },
    methods: {
        reset () {
            if(this.show) {
                this.report.title = ""
                this.report.message = ""
                this.report.contact_allowed = false
                this.report.email = ""
                if(this.is_at_least_administrator) {
                    this.report.contact_allowed = true
                    this.report.email = this.profile.email
                }

                this.report.url = window.location.href
                this.report.user_agent = navigator.userAgent
                this.report.screen_size = `${window.innerWidth}x${window.innerHeight}`
                this.report.version = this.app_version
                this.report.user_id = this.profile_id
                this.report.last_system_message = this.last_system_message

                this.report.include_screenshot = true
                this.report.screenshot = null

                toJpeg(
                    document.querySelector(".nibnut"), {
                        backgroundColor: "white",
                        quality: 0.9,
                        cacheBust: true,
                        filter: node => {
                            return !!node && (!node.classList || (!node.classList.contains("hide-on-screenshots") && !node.classList.contains("app-header") && !node.classList.contains("app-footer")))
                        }
                    }
                ).then(data_url => {
                    this.report.screenshot = data_url
                })
            }
        },
        toggle_email () {
            if(!this.report.contact_allowed) this.report.email = ""
            else if(!this.report.email && this.profile) {
                this.report.email = this.profile.email
            }
        },
        submit () {
            if(this.sent) this.shown = false
            else {
                this.sending = true
                const data = { ...this.report }
                data.last_system_message = data.last_system_message || ""
                if(!data.include_screenshot) delete data.screenshot
                delete data.include_screenshot
                this.$store.dispatch("RECORDS_ACTION", {
                    entity: "bug",
                    action: "report",
                    data,
                    method: "post"
                }).then(() => {
                    this.feedback_status = true
                    this.feedback_message = this.$root.translate("Thank you!!")
                }).catch(this.receive_error).then(() => {
                    this.sending = false
                })
            }
        }
    },
    computed: {
        ...mapState(["last_system_message"]),
        shown: {
            get () {
                return this.show && !!this.app_context.settings.bug_reporter_active
            },
            set (show) {
                this.$emit("update:show", show)
            }
        },
        sent () {
            return this.feedback_status === true
        }
    },
    props: {
        show: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            report: {
                title: "",
                message: "",
                contact_allowed: false,
                email: "",
                url: "",
                user_agent: "",
                screen_size: "",
                version: 0,
                user_id: 0,
                last_system_message: "",
                include_screenshot: true,
                screenshot: null
            },
            sending: false
        }
    }
}
</script>

<style lang="scss">
.bug-reporter {
    .bug-reporter-screenshot {
        max-width: 100%;
    }
}
</style>
