<template>
    <div class="card">
        <div class="card-header">
            <div class="card-title h6 text-gray text-uppercase">{{ translate("Assignments") }}</div>
        </div>
        <div class="card-body">
            <properties-data-table
                id="user-dashboard-properties"
                :user-id="userId"
                :is-root="false"
                :exportable="false"
                :can-add="false"
            />
        </div>
    </div>
</template>

<script>
import PropertiesDataTable from "./PropertiesDataTable"

export default {
    name: "UserDashboardPropertiesList",
    components: {
        PropertiesDataTable
    },
    props: {
        userId: {
            type: Number,
            default: -1
        }
    }
}
</script>
