import { mapGetters } from "vuex"

import data_table_source from "./IsDataTableSource"
import ui_utilities from "./UiUtilities"

const is_remote_data_table_source = {
    mixins: [data_table_source, ui_utilities],
    methods: {
        pre_load () {},
        post_load (response) {
            // Anything to do after the current page's data has been loaded?
            if(this.page_autoscroll) this.page_autoscroll()
        },
        refresh () {
            if(!this.entity) console.error(this.$root.translate("IsRemoteDataTableSource has no entity defined."))

            this.pre_load()

            if(this.can_refresh) {
                const query = {
                    ...this.state,
                    fields: this.fields,
                    relation_ids: this.relation_ids
                }

                this.loading = true
                return this.$store.dispatch(
                    "FETCH_RECORDS",
                    {
                        entity: this.entity,
                        query
                    }
                ).then(response => {
                    this.current_records_ids = response.record_ids

                    this.set_state_values({
                        total: response.total || 0,
                        found: response.found || 0
                    })

                    this.post_load(response)
                }).catch(error => {
                    this.$error(error.message)
                }).then(() => {
                    this.loading = false
                })
            }
            return Promise.resolve()
        },
        reset () {
            this.reset_state()
            /* this.$store.dispatch("JANITOR", { entities: [this.entity, ...this.relation_ids] }) * NO: a remote table source is not high enough in the hierarchy to assume they own all the store data ; Janitor really should only be called on route change - in the Page component */
            this.refresh()
        },
        page_rows (rows) {
            return rows // remote call already returns record ids in correct configuration
        },
        sort_rows (rows) {
            return rows // remote call already returns record ids in correct configuration
        },
        filter_rows (rows) {
            return rows // remote call already returns record ids in correct configuration
        },
        search_rows (rows) {
            return rows // remote call already returns record ids in correct configuration
        },
        filter_by_selection () {
            this.filtered_by_selection = true
            this.current_records_ids = [...this.selected_row_ids]
            this.reset_state(true)
            this.set_state_values({
                page: 1,
                found: this.selected_row_ids.length
            })
        }
    },
    computed: {
        ...mapGetters(["entity_records"]),
        can_refresh () {
            return this.is_public_page || this.profile_id
        },
        rows () {
            return this.entity_records(this.entity, this.current_records_ids)
        },
        fields () {
            return Object.keys(this.columns)
        },
        export_url () {
            const url = new URL(`https://${window.document.location.hostname}/export/${this.entity}`)

            const search = []
            Object.keys(this.state).forEach((key) => {
                if((!key.match(/^(?:per_?)page$/i)) && this.state[key]) {
                    let value = this.state[key]
                    if(typeof value !== "string") value = JSON.stringify(value)
                    search.push(`${key}=${encodeURIComponent(value)}`)
                }
            })

            url.search = search.join("&")
            return url.href
        }
    },
    data () {
        return {
            // NEEDS TO BE OVERRIDEN
            entity: "",
            relation_ids: [],

            current_records_ids: []
        }
    }
}

export default is_remote_data_table_source
