import { mapGetters } from "vuex"

import profile_utilities from "@/nibnut/mixins/ProfileUtilities"

const addl_profile_utilities = {
    mixins: [profile_utilities],
    methods: {
        preferences (id = null) {
            const profile = this.profile
            if(!profile || !profile.preferences) return null
            if(id) return profile.preferences[id]
            return profile.preferences
        },
        save_preferences (value, id = null) {
            const profile = this.profile
            if(profile) {
                let preferences = null
                if(id) {
                    preferences = { ...(profile.preferences || {}) }
                    preferences[id] = value
                } else {
                    preferences = value
                }
                this.$store.dispatch("RECORD_SAVE", {
                    entity: "user",
                    id: profile.id,
                    data: {
                        preferences,
                        fields: ["fieldset::profile"]
                    }
                }).catch(error => {
                    console.log(error.message)
                })
            }
        },
        property_status_by_id (id) {
            return this.entity_record("property_status", id) || {}
        },
        profile_is_team_leader (profile, team_id = null) {
            if(!profile || !profile.lead_team_ids) return false
            if(team_id) return profile.lead_team_ids.indexOf(team_id) >= 0
            return !!profile.lead_team_ids.length
        },
        is_team_leader (team_id = null) {
            if(!this.profile_id) return false
            return this.profile_is_team_leader(this.profile, team_id)
        }
    },
    computed: {
        ...mapGetters(["entity_record"]),
        property_statuses () {
            return this.entity_records("property_status")
        }
    }
}

export default addl_profile_utilities
