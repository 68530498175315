import string_utilities from "@/nibnut/mixins/StringUtilities"

const addl_string_utilities = {
    mixins: [string_utilities],
    methods: {
        parse_person_name (name) {
            const components = {
                first_name: "",
                middle_name: "",
                last_name: "",
                name_suffix: ""
            }
            let matches = name ? name.match(/^(.+?)(?:,\s+(.+?))?$/i).filter(match => match !== undefined) : null
            if(matches) {
                if(matches.length >= 3) components.name_suffix = matches[2]
                name = matches[1] || ""
                matches = name.match(/^([^\s]+)(?:\s+?(.+?))?(?:\s+?(.+?))?$/i).filter(match => match !== undefined)
                if(matches) {
                    components.first_name = matches[1] || ""
                    if(matches.length >= 4) components.middle_name = matches[2] || ""
                    components.last_name = matches[matches.length - 1] || ""
                }
            }
            return components
        }
    }
}

export default addl_string_utilities
