<template>
    <form-input
        v-bind="$props"
        class="form-contact-number-input"
        v-on="$listeners"
    >
        <template v-slot:read_only><slot name="read_only"></slot></template>
        <template v-slot:empty-value>
            <slot name="empty-value">
                <mailto-link
                    v-if="(valueType === constants('contact_number_types', 'TYPE_PHONE').id) || (valueType === constants('contact_number_types', 'TYPE_EMAIL').id)"
                    :value="value"
                    :filter="(valueType === constants('contact_number_types', 'TYPE_PHONE').id) ? 'telto' : 'mailto'"
                    @click.stop="$emit('click', $event)"
                >
                    {{ value }}
                </mailto-link>
                <base-link
                    v-if="(valueType === constants('contact_number_types', 'TYPE_URL').id)"
                    :href="value"
                    target="_blank"
                    @click.stop="$emit('click', $event)"
                >
                    {{ value }}
                </base-link>
            </slot>
        </template>
        <template v-slot:label><slot name="label"></slot></template>
        <template v-slot:hint><slot name="hint"></slot></template>
        <template v-slot:left_addon>
            <base-dropdown
                :id="`${id}-type`"
                :name="`${id}-type`"
                :value="valueType"
                :size="size"
                :options="types"
                :disabled="!editable || disabled"
                :required="true"
                class="flex-static"
                @input="change_type"
            />
        </template>
        <template v-slot:right_addon>
            <slot name="right_addon">
                <default-button
                    color="error"
                    flavor="link"
                    :disabled="!editable || disabled"
                    class="input-group-btn"
                    @click.prevent.stop="$emit('delete')"
                >
                    <open-icon glyph="times" />
                </default-button>
            </slot>
        </template>
    </form-input>
</template>

<script>
import misc_utilities from "@/nibnut/mixins/MiscUtilities"

import FormInput from "@/nibnut/components/Inputs/FormInput"
import BaseDropdown from "@/nibnut/components/Inputs/BaseDropdown"
import BaseLink from "@/nibnut/components/Links/BaseLink"
import MailtoLink from "@/nibnut/components/Links/MailtoLink"
import DefaultButton from "@/nibnut/components/Buttons/DefaultButton"
import OpenIcon from "@/nibnut/components/OpenIcon"

export default {
    name: "FormContactNumberInput",
    mixins: [misc_utilities],
    components: {
        FormInput,
        BaseDropdown,
        BaseLink,
        MailtoLink,
        DefaultButton,
        OpenIcon
    },
    methods: {
        change_type (event) {
            const option = this.types.find(option => (option.id === parseInt(event.target.value)))
            this.$emit("input", option ? option.id : 0, "type")
        }
    },
    computed: {
        types () {
            const constants = this.constants("contact_number_types")
            return [
                constants.TYPE_EMAIL,
                constants.TYPE_PHONE,
                constants.TYPE_URL
            ]
        }
    },
    props: {
        ...FormInput.props,
        valueType: {
            type: Number,
            default: 0
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.form-contact-number-input {
}
</style>
