<template>
    <div class="card card-chart">
        <div v-if="!!title" class="card-header">
            <div class="card-title h6 text-gray text-uppercase">{{ title }}</div>
        </div>
        <div class="card-body">
            <apexchart
                v-if="has_data"
                :type="type"
                :height="180"
                :options="chart_options"
                :series="series"
            />
            <div v-else class="empty">
                <p class="empty-title h5">{{ translate("No data available yet...") }}</p>
            </div>
        </div>
    </div>
</template>

<script>
import displays_chart from "@/nibnut/mixins/DisplaysChart"

export default {
    name: "ChartCard",
    mixins: [displays_chart],
    mounted () {
        const options = {
            ...this.CHART_BASE_OPTIONS,
            legend: {
                show: true,
                markers: {
                    fillColors: this.CHART_COLORS
                }
            }
        }
        options.chart = {
            ...options.chart,
            id: this.id,
            type: this.type,
            zoom: {
                autoScaleYaxis: true
            }
        }
        options.plotOptions = {
            bar: {
                columnWidth: "80%"
            }
        }
        options.grid = {
            xaxis: {
                lines: {
                    show: false
                }
            }
        }
        options.xaxis = {
            labels: {
                formatter: (value) => {
                    return this.nibnut_filter("nibnut.date", [value, this.dateFormat])
                }
            },
            categories: this.categories
        }
        options.yaxis = {
            ...options.yaxis,
            min: 0,
            max: max => {
                return Math.min(20, max)
            },
            labels: {
                formatter: (value) => {
                    return this.nibnut_filter("nibnut.number", [value, "0,0"])
                }
            }
        }
        options.stroke.show = false
        // options.tooltip.shared = true
        options.tooltip.y = {
            formatter: (value) => {
                return this.nibnut_filter("nibnut.number", [value, "0,0"])
            }
        }
        options.legend = {
            show: false,
            position: "bottom"
        }
        this.chart_options = options
    },
    watch: {
        categories: "refresh_chart"
    },
    methods: {
        refresh_chart () {
            const chart_options = { ...this.chart_options }
            chart_options.xaxis.categories = this.categories
            this.chart_options = chart_options
        }
    },
    computed: {
        color_palette () {
            return [
                this.color_name_to_hex("primary"),
                "#B13F51",
                "#54353C",
                this.color_name_to_hex("tertiary"),
                "#7E765B",
                "#86A088",
                "#ff00cb",
                "#38419b",
                this.color_name_to_hex("warning"),
                this.color_name_to_hex("error")
            ]
        },
        has_data () {
            return !!this.series && !!this.series.find(serie => !!serie._total)
        }
    },
    props: {
        id: {
            type: String,
            required: true
        },
        title: {
            type: String,
            default: null
        },
        type: {
            type: String,
            validator: prop => ["line", "area", "bar", "radar", "histogram", "pie", "donut", "radialBar", "scatter", "bubble", "heatmap", "candlestick"].indexOf(prop) >= 0,
            required: true
        },
        categories: {
            type: Array,
            default () {
                return []
            }
        },
        series: {
            type: Array,
            default () {
                return []
            }
        },
        dateFormat: {
            type: String,
            default: "MMM DD"
        }
    },
    data () {
        return {
            chart_options: {}
        }
    }
}
</script>

<style lang="scss">
.card.card-chart {
    height: 100%;

    & > .card-body > .empty {
        display: flex;
        align-items: center;
        height: 100%;
        padding: 0;
    }
}
</style>
