<template>
    <textarea
        v-bind="$props"
        v-on="$listeners"
        :class="{ 'show-on-ready': showOnReady, ready }"
        class="form-input"
    />
</template>

<script>
export default {
    name: "BaseTextarea",
    mounted () {
        setTimeout(() => {
            // prevent weird "bug" sometimes will make page scroll to textarea on load...
            this.ready = true
        }, 500)
    },
    props: {
        id: {
            type: String,
            validator: prop => !!prop
        },
        name: {
            type: String,
            validator: prop => !!prop,
            required: true
        },
        value: {
            default: ""
        },
        placeholder: {
            type: String,
            default: ""
        },
        required: {
            type: Boolean,
            required: true
        },
        rows: {
            type: Number,
            default: 3
        },
        disabled: {
            type: Boolean,
            default: false
        },
        showOnReady: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            ready: false
        }
    }
}
</script>

<style lang="scss">
textarea.form-input.show-on-ready {
    display: none;
    &.ready {
        display: block;
    }
}
</style>
