<template>
    <form-group
        v-bind="form_group_props"
    >
        <template v-slot:read_only>
            <slot name="read_only">
                <label
                    v-if="has_label"
                    :class="{ [`label-${size}`]: true }"
                    class="form-label"
                    :for="identifier"
                >
                    <slot name="label"></slot>
                </label>
                <label
                    :class="{ [`label-${size}`]: true }"
                    class="form-label disabled-field"
                >
                    <slot name="empty-value">{{ display_value }}</slot>
                </label>
            </slot>
        </template>
        <template v-slot:label><slot name="label"></slot></template>
        <template v-slot:hint><slot name="hint"></slot></template>

        <base-date-input
            v-bind="input_props"
            :disabled="!editable || disabled"
            v-on="$listeners"
        >
            <template v-slot:left_addon><slot name="left_addon"></slot></template>
            <template v-slot:right_addon><slot name="right_addon"></slot></template>
        </base-date-input>
    </form-group>
</template>

<script>
import is_nibnut_component from "@/nibnut/mixins/IsNibnutComponent"

import FormGroup from "./FormGroup"
import BaseDateInput from "./BaseDateInput"

export default {
    name: "FormDateInput",
    mixins: [is_nibnut_component],
    components: {
        FormGroup,
        BaseDateInput
    },
    computed: {
        input_props () {
            const { editable, ...input_props } = this.$props
            return input_props
        },
        has_label () {
            return this.has_slot("label")
        },
        form_group_props () {
            return {
                id: this.id,
                name: this.name,
                value: this.value,
                size: this.size,
                required: this.required,
                editable: this.editable,
                error: this.error,
                waiting: this.saving,
                horizontal: this.horizontal
            }
        },
        display_value () {
            if(!this.value) return ""
            return this.$dayjs(this.value).format(this.dateFormat)
        }
    },
    props: {
        ...BaseDateInput.props,
        disabled: { // disable input field
            type: Boolean,
            default: false
        },
        editable: { // read-only
            type: Boolean,
            default: true
        },
        horizontal: {
            type: Boolean,
            default: false
        }
    }
}
</script>
