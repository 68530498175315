<template>
    <div class="property-status-button" @click.stop>
        <default-button
            flavor="link"
            color="text"
            :size="size"
            class="property-status-button"
            :disabled="!topic_editable(property)"
            @click.stop="edit"
        >
            {{ property_status_by_id(property.status_id).name }}
            <open-icon v-if="topic_editable(property)" glyph="pen" class="ml-2" />
        </default-button>
        <help-button
            v-if="!!latestActionLog && !!latestActionLog.description"
            class="popover-bottom"
        >
            {{ latestActionLog.description }}
            <div class="columns text-small text-gray">
                <div class="column col-6">
                    {{ latestActionLog.computed_actor_name }}
                </div>
                <div class="column col-6 text-right">
                    {{ latestActionLog.acted_at | nibnut.date }} @ {{ latestActionLog.acted_at | nibnut.date("hh:mm A") }}
                </div>
            </div>
        </help-button>

        <property-status-dialog
            v-if="editing"
            :show.sync="editing"
            :title="propertyAddress"
            :property="property"
            :waiting="!!saving_field_ids.length"
            @input="save"
        />
    </div>
</template>

<script>
import handles_saving from "@/nibnut/mixins/HandlesSaving"
import misc_utilities from "@/nibnut/mixins/MiscUtilities"
import assignment_utilities from "@/custom/mixins/AssignmentUtilities"

import DefaultButton from "@/nibnut/components/Buttons/DefaultButton"
import HelpButton from "@/nibnut/components/Buttons/HelpButton"
import OpenIcon from "@/nibnut/components/OpenIcon"

export default {
    name: "PropertyStatusButton",
    mixins: [handles_saving, misc_utilities, assignment_utilities],
    components: {
        DefaultButton,
        HelpButton,
        OpenIcon,
        PropertyStatusDialog: () => import("@/custom/dialogs/PropertyStatusDialog")
    },
    methods: {
        edit () {
            this.record_id = this.property.id
            this.editing = true
        },
        save (data) {
            if(!this.record_id) return
            this.save_data_for_record_id(
                this.entity,
                this.record_id,
                data
            ).then(record => {
                if(record) this.editing = false
                this.$emit("changed")
            })
        }
    },
    props: {
        property: {
            type: Object,
            required: true
        },
        propertyAddress: {
            type: String,
            required: true
        },
        latestActionLog: {
            type: Object,
            default () {
                return {}
            }
        },
        size: {
            type: String,
            validator: prop => !prop || prop.match(/^(xs|sm|md|lg)$/i),
            default: "md"
        }
    },
    data () {
        return {
            entity: "property",
            editing: false
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";
.property-status-button {
    display: flex;

    & > .btn {
        flex: 1 0 1px;
        text-align: left;

        &.btn-primary {
            padding: 0.25rem 0.275rem;
            .las {
                font-size: 1.33333em;
                line-height: 0.75em;
                vertical-align: -0.0667em;
            }
        }
        &.btn-light {
            opacity: 0.5;
            &:hover {
                opacity: 1;
            }
        }
    }
    & > .nibnut-help-button {
        flex: 0 0 auto;
    }

    &.text-right {
        & > .btn {
            text-align: right;
        }
    }
}
</style>
