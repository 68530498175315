<template>
    <div>
        <address-input
            v-bind="$props"
            v-on="$listeners"
        >
            <template v-slot:read_only><slot name="read_only"></slot></template>
            <template v-slot:label><slot name="label"></slot></template>
            <template v-slot:hint><slot name="hint"></slot></template>
        </address-input>
        <form-select
            :id="`${id}-county_id`"
            name="county_id"
            v-model="value.county_id"
            data-source="county"
            :editable="editable"
            :required="required"
            :error="has_error('county_id')"
            @input="set_county_id"
        >
            <template slot="label">
                <small>{{ $root.translate("County") }}</small>
            </template>
        </form-select>
    </div>
</template>

<script>
import AddressInput from "@/nibnut/components/Inputs/AddressInput/AddressInput"
import FormSelect from "@/nibnut/components/Inputs/FormSelect"

export default {
    name: "CountyAddressInput",
    components: {
        AddressInput,
        FormSelect
    },
    methods: {
        set_county_id (county_id) {
            this.value.county_id = county_id
            this.$emit("input", this.value, this.name)
        },
        has_error (error_id) {
            if(error_id && this.errors) return this.errors[error_id]
            return false
        }
    },
    props: {
        ...AddressInput.props
    }
}
</script>
