<template>
    <form-dropdown
        v-bind="dropdown_props"
        :options="sorted_property_statuses"
        v-on="$listeners"
    >
        <template v-slot:read_only><slot name="read_only"></slot></template>
        <template v-slot:empty-value><slot name="empty-value"></slot></template>
        <template v-slot:label><slot name="label"></slot></template>
        <template v-slot:hint><slot name="hint"></slot></template>
        <template v-slot:option="{ option }">
            <span v-if="!!option.parent_id">&nbsp;&nbsp;&nbsp;&nbsp;</span>
            {{ option.name }}
        </template>
    </form-dropdown>
</template>

<script>
import orderBy from "lodash/orderBy"

import addl_profile_utilities from "@/custom/mixins/AddlProfileUtilities"

import FormDropdown from "@/nibnut/components/Inputs/FormDropdown"

export default {
    name: "PropertyStatusDropdown",
    mixins: [addl_profile_utilities],
    components: {
        FormDropdown
    },
    computed: {
        dropdown_props () {
            const { options, ...dropdown_props } = this.$props
            return dropdown_props
        },
        sorted_property_statuses () {
            const property_statuses = orderBy(this.property_statuses, ["sort_order", "name"], "asc")
            const sorted_property_statuses = property_statuses.filter(property_status => !property_status.parent_id)
            const max = sorted_property_statuses.length - 1
            for(let loop = max; loop >= 0; loop--) {
                const parent_id = sorted_property_statuses[loop].id
                const children = property_statuses.filter(property_status => property_status.parent_id === parent_id)
                sorted_property_statuses.splice(loop + 1, 0, ...children)
            }
            return sorted_property_statuses
        }
    },
    props: {
        ...FormDropdown.props
    }
}
</script>
