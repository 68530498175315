<template>
    <div class="query-builder">
        <default-button
            flavor="link"
            @click.stop="edit"
        >
            <open-icon glyph="search" />
            {{ state_description }}
        </default-button>
        <slot></slot>

        <modal-dialog
            id="query-builder"
            :show.sync="editing"
            :dismissable="true"
            class="modal-lg"
        >
            <template v-slot:title>
                <span class="h5">{{ translate("Advanced Search") }}</span>
            </template>

            <div
                v-for="(criterion, index) in local_criteria"
                :key="index"
                :class="{ 'picking-date': picking_date }"
                class="query-builder-criterion-container"
            >
                <div class="query-builder-criterion">
                    <form-dropdown
                        :id="`field-${index}`"
                        name="field"
                        v-model="criterion.field"
                        :options="available_fields_for_criterion(criterion)"
                        label-field="label"
                        :required="true"
                        class="query-builder-criterion-field mr-1"
                        @input="field_changed(criterion)"
                    />
                    <form-dropdown
                        :id="`operator-${index}`"
                        name="operator"
                        v-model="criterion.operator"
                        :options="available_operators_for_criterion(criterion)"
                        :required="true"
                        class="query-builder-criterion-operator mx-1"
                        @input="operator_changed(criterion)"
                    />
                    <query-builder-value
                        :field="field_for_criterion(criterion)"
                        :criterion="criterion"
                        :index="index"
                        class="mx-1"
                        @focus="picking_date = true"
                        @blur="picking_date = false"
                        @input="criterion.value = $event"
                    />
                    <div
                        v-if="local_criteria.length > 1"
                        class="query-builder-criterion-remove ml-1"
                    >
                        <default-button
                            @click.prevent="remove_criterion(index)"
                        >
                            <open-icon glyph="minus-circle" />
                        </default-button>
                    </div>
                </div>
                <div
                    :data-content="divider_text_for_criterion(index, criterion)"
                    class="divider clickable"
                    @click="relink_or_add(criterion, (index >= (local_criteria.length - 1)))"
                ></div>
            </div>

            <template v-slot:footer>
                <default-button
                    class="mr-2"
                    @click.prevent="editing = false"
                >
                    {{ translate("Cancel") }}
                </default-button>
                <default-button
                    class="ml-2"
                    @click.prevent="apply"
                >
                    {{ translate("Apply") }}
                </default-button>
            </template>
        </modal-dialog>
    </div>
</template>

<script>
import ModalDialog from "@/nibnut/components/ModalDialog/ModalDialog"
import FormDropdown from "@/nibnut/components/Inputs/FormDropdown"
import DefaultButton from "@/nibnut/components/Buttons/DefaultButton"
import OpenIcon from "@/nibnut/components/OpenIcon"
import QueryBuilderValue from "./QueryBuilderValue"

/*
fields = {
    "name": {
        id: "name",
        label: "...",
        type: "text|number|float|stripe|email|tel|checkbox|radio|dropdown|bool|date",
        choices: [...], // For checkbox|radio|dropdown
        operators: [=|!=|<|<=|>=|>|<>|^|$|*|!*], // "<>" between, "^" starts with, "$" ends with, "*" contains, "!*" does not contain
        min: 0, // For number|float|stripe
        max: 0, // For text|number|float|stripe
        step: 1, // For float|stripe
        single_use: T|F // If true, user can only add ONE criteria using this field (good for checkboxes for instance)
    },
    ...
}

ex: (status = 1) AND (type = "type_1") OR (type = "type_2")
criteria = [
    { link: "&", field: "status", operator: "=", value: 1 },
    { link: "&", field: "type", operator: "=", value: "type_1" },
    { link: "|", field: "type", operator: "=", value: "type_2" }
]
*/

const all_operators = [
    { id: "=", name: "Is" },
    { id: "!=", name: "Is Not" },
    { id: "<", name: "Less Than" },
    { id: "<=", name: "Less Than Or Equal" },
    { id: ">", name: "Greater Than" },
    { id: ">=", name: "Greater Than Or Equal" },
    { id: "<>", name: "Between" },
    { id: "^", name: "Starts With" },
    { id: "$", name: "Ends With" },
    { id: "*", name: "Contains" },
    { id: "!*", name: "Doe Not Contain" },
    { id: "~", name: "Contains" }, // fuzzy match
    { id: "T", name: "Is True" }, // for type = "bool" only
    { id: "F", name: "Is False" } // for type = "bool" only
]

export default {
    name: "QueryBuilder",
    components: {
        ModalDialog,
        FormDropdown,
        DefaultButton,
        OpenIcon,
        QueryBuilderValue
    },
    watch: {
        openQueryBuilderRequest: "edit",
        editing: function () { this.$emit("toggled", this.editing) }
    },
    methods: {
        edit () {
            this.local_criteria = JSON.parse(JSON.stringify(this.criteria)) || [] // deep copy
            if(!this.local_criteria.length) {
                const field = Object.values(this.fields)[0]
                this.local_criteria.push({ link: "&", field: field.id, operator: field.operators[0], value: null })
            }
            this.editing = true
        },
        field_for_criterion (criterion) {
            return this.fields[criterion.field]
        },
        available_fields_for_criterion (criterion) {
            return Object.values(this.fields).filter(field => {
                return (field.id === criterion.field) || !field.single_use || !this.local_criteria.find(local_criterion => {
                    return (field.id === local_criterion.field)
                })
            })
        },
        available_operators_for_criterion (criterion) {
            const field = this.field_for_criterion(criterion)
            const operators = (field.type === "bool") ? ["T", "F"] : field.operators
            return all_operators.filter(operator => {
                return operators.indexOf(operator.id) >= 0
            })
        },
        divider_text_for_criterion (index, criterion) {
            if(index >= (this.local_criteria.length - 1)) return "ADD"
            if(criterion.link === "|") return "OR"
            return "AND"
        },
        field_changed (criterion) {
            const field = this.field_for_criterion(criterion)
            const operators = this.available_operators_for_criterion(criterion)
            if(!!field.default_operator || !operators.find(operator => operator.id === criterion.operator)) {
                criterion.operator = field.default_operator || operators[0].id
                this.operator_changed(criterion)
            }
        },
        operator_changed (criterion) {
            if((criterion.operator === "<>") && !Array.isArray(criterion.value)) criterion.value = [criterion.value]
            else if((criterion.operator !== "<>")) {
                if(Array.isArray(criterion.value)) criterion.value = criterion.value.length ? criterion.value[0] : null
                else if(criterion.operator === "T") criterion.value = 1
                else if(criterion.operator === "F") criterion.value = 0
            }
        },
        remove_criterion (index) {
            this.local_criteria.splice(index, 1)
        },
        relink_or_add (criterion, add) {
            if(add) {
                const new_criterion = { link: "&", field: "", operator: "", value: null }
                const fields = this.available_fields_for_criterion(new_criterion)
                if(fields.length) {
                    new_criterion.field = fields[0].id
                    new_criterion.operator = fields[0].operators[0]
                    this.local_criteria.push(new_criterion)
                }
            } else if(criterion.link === "&") criterion.link = "|"
            else criterion.link = "&"
        },
        apply () {
            this.$emit("input", this.local_criteria)
            this.editing = false
        }
    },
    computed: {
        state_description () {
            if(!this.criteria || !this.criteria.length) return this.emptySearchDescription
            const description = []

            this.criteria.forEach(criterion => {
                const field = this.field_for_criterion(criterion)
                description.push(field.label || field.id)

                const operator = all_operators.find(operator => operator.id === criterion.operator)
                if(operator) description.push(operator.name)

                if(field.type !== "bool") {
                    const criterion_value = Array.isArray(criterion.value) ? criterion.value : [criterion.value]
                    const values = []
                    criterion_value.forEach(value => {
                        switch (field.type) {
                        case "number":
                            value = this.nibnut_filter("nibnut.number", [value, "0,0"])
                            break
                        case "float":
                            value = this.nibnut_filter("nibnut.number", [value])
                            break
                        case "stripe":
                            value = this.nibnut_filter("nibnut.currency", [value / 100])
                            break

                        case "date":
                            value = this.nibnut_filter("nibnut.date", [value])
                            break

                        case "radio":
                        case "dropdown": {
                            if(field.choices) {
                                const choice = field.choices.find(choice => choice.id === value)
                                if(choice) value = choice.name
                            }
                            break
                        }
                        }
                        values.push(value)
                    })
                    description.push(values.join(" and "))
                }

                description.push((criterion.link === "|") ? "OR" : "AND")
            })

            description.pop() // remove last link value
            return description.join(" ")
        }
    },
    props: {
        fields: {
            type: Object,
            required: true
        },
        criteria: {
            type: Array,
            required: true
        },
        emptySearchDescription: {
            type: String,
            default: "All records, unfiltered (click here to apply a filter)"
        },
        openQueryBuilderRequest: {
            type: Number,
            default: 0
        }
    },
    data () {
        return {
            local_criteria: [],
            editing: false,
            picking_date: false
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.query-builder {
    .query-builder-criterion-container {
        .query-builder-criterion {
            display: flex;

            .form-group {
                margin-bottom: 0;
            }
            .query-builder-criterion-field {
                flex: 1 0 15%;
            }
            .query-builder-criterion-operator {
                flex: 2 0 15%;
            }
            .query-builder-criterion-value {
                flex: 1 1 auto;

                & > div {
                    width: 100%;
                }
            }
            .query-builder-criterion-remove {
                flex: 0 0 auto;
            }
        }
        &.picking-date {
            margin-top: 100px;
            margin-bottom: 100px;
        }
    }
}
</style>
