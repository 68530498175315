<template>
    <form-group
        v-bind="form_group_props"
        class="form-template-input"
    >
        <template v-slot:read_only>
            <div class="card"><div class="card-body" v-html="value"></div></div>
        </template>
        <template v-slot:empty-value><slot name="empty-value"></slot></template>
        <template v-slot:label>
            <div class="d-inline-flex">
                <div class="column"><slot name="label"></slot></div>
                <div
                    v-if="!!state.found && editable"
                    class="column col-auto"
                >
                    <form-dropdown
                        :id="`${id}-text-snippets`"
                        name="text_snippet_id"
                        v-model="text_snippet_id"
                        :options="filtered_rows"
                        :empty-label="translate('Custom')"
                        :editable="true"
                        :required="false"
                        size="sm"
                    />
                </div>
            </div>
        </template>
        <template v-slot:hint><slot name="hint"></slot></template>

        <base-editor
            v-bind="editor_props"
            :tools="[ ['bold', 'italic', 'strike'], ['h3', 'h4', 'h5'], ['orderedList', 'bulletList'], ['left', 'center', 'right', 'justify'], ['link', 'hr'], ['tokens'], ['undo', 'redo'] ]"
            :tool-configurations="editor_configuration"
            :editable="editable && !text_snippet_id"
            v-on="$listeners"
        />
    </form-group>
</template>

<script>
import is_nibnut_component from "@/nibnut/mixins/IsNibnutComponent"
import is_remote_data_table_source from "@/nibnut/mixins/IsRemoteDataTableSource"

import FormGroup from "@/nibnut/components/Inputs/FormGroup"
import FormDropdown from "@/nibnut/components/Inputs/FormDropdown"
import BaseEditor from "@/nibnut/components/Inputs/BaseEditor"

export default {
    name: "FormTemplateInput",
    mixins: [is_nibnut_component, is_remote_data_table_source],
    components: {
        FormGroup,
        FormDropdown,
        BaseEditor
    },
    watch: {
        text_snippet_id: "apply_text_snippet"
    },
    methods: {
        pre_load () {
            this.set_state_value("filter", this.constants("text_snippet_contexts", "TYPE_LETTERS").id)
        },
        post_load () {
            if(this.editable && !this.text_snippet_id && !!this.state.found) this.text_snippet_id = this.filtered_rows[0].id
        },
        apply_text_snippet () {
            if(this.editable) {
                const message = this.text_snippet_id ? this.rows.find(row => row.id === this.text_snippet_id).content : null
                this.$emit("input", message, this.name)
            }
        }
    },
    computed: {
        state_identifier () {
            return `form-template-input-${this.id}`
        },
        form_group_props () {
            return {
                id: this.id,
                name: this.name,
                value: this.value,
                required: this.required,
                editable: this.editable,
                error: this.error,
                waiting: this.saving
            }
        },
        editor_props () {
            const { tools, toolConfigurations, required, editable, saving, error, ...editor_props } = this.$props
            return editor_props
        },
        editor_configuration () {
            const options = []
            switch (this.topicType) {
            case "App\\Property":
                options.push({ id: "display_name", label: "Name" }, { id: "computed_owner_names", label: "Owner Name(s)" })
                break
            case "App\\Person":
                options.push({ id: "name", label: "Full Name" }, { id: "first_name", label: "First Name" }, { id: "middle_name", label: "Middle Name" }, { id: "last_name", label: "Last Name" }, { id: "name_suffix", label: "Suffix" })
                break
            default:
                options.push(
                    { id: "name", label: "Full Name" },
                    { id: "first_name", label: "First Name" },
                    { id: "middle_name", label: "Middle Name" },
                    { id: "last_name", label: "Last Name" },
                    { id: "name_suffix", label: "Suffix" },

                    { id: "property.display_name", label: "Property" },
                    { id: "property.computed_owner_names", label: "Property's Owner Names" },

                    { id: "sender.name", label: "Sender's Name" }
                )
                break
            }
            return options.length ? { tokens: { options } } : {}
        },
        rows () {
            return this.entity_records(this.entity, this.current_records_ids)
        }
    },
    props: {
        topicType: {
            type: String,
            default: ""
        },
        ...BaseEditor.props,
        required: {
            type: Boolean,
            required: true
        },
        saving: {
            type: Boolean,
            default: false
        },
        error: {
            type: String,
            default: ""
        }
    },
    data () {
        return {
            entity: "text_snippet",
            default_state: {
                per_page: 0,
                page: 1,
                sort_by: ["name"],
                sort_dir: ["asc"],
                filter_on: "context",
                filter: null,
                archived: false,
                search: "",
                total: 0,
                found: 0
            },
            text_snippet_id: 0
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.form-dod-input {
    & > .form-label,
    & > .form-label.label-sm {
        padding: 0;
    }
}
</style>
