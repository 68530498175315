<template>
    <div class="header app-header">
        <header class="navbar">
            <section class="navbar-section">
                <base-link
                    :href="{ name: 'home' }"
                    @click.native="$emit('navigate', false)"
                >
                    <app-logo />
                </base-link>
                <default-button
                    v-if="!!profile_id||isPublicPage"
                    flavor="link"
                    class="show-sm"
                    @click.prevent="bug_report"
                >
                    <open-icon glyph="bug" />
                </default-button>
            </section>
            <section class="navbar-center text-left">
                <span class="h4 hide-sm">{{ setting("application_full_name") }} ({{ setting("application_name") }})</span>
            </section>
            <section class="navbar-section">
                <master-search-box
                    v-if="is_at_least_administrator"
                    :class="{ 'mr-4': !$mq.match(/^(xs|sm|md)$/i) }"
                />
                <default-button
                    v-if="!!app_context && !!app_context.settings && app_context.settings.bug_reporter_active && (!!profile_id || isPublicPage)"
                    flavor="link"
                    class="mr-4 hide-sm"
                    @click.prevent="bug_report"
                >
                    <open-icon glyph="bug" />
                </default-button>
                <default-button
                    v-if="!!profile_id"
                    flavor="link"
                    :title="$root.translate('Logout')"
                    @click.prevent="logout"
                >
                    <open-icon glyph="sign-out-alt" />
                </default-button>
                <default-button
                    v-else-if="!profile_id"
                    flavor="link"
                    :title="$root.translate('Login/Signup')"
                    @click.prevent="login"
                >
                    <open-icon glyph="sign-in-alt" />
                </default-button>
                <base-link
                    :href="{ name: 'profile' }"
                    class="btn btn-link"
                >
                    <figure class="avatar avatar-sm">
                        <open-icon v-if="!profile_id||(!!profile&&!profile.avatar)" glyph="user" size="2x" />
                        <img v-else :src="profile.avatar" :alt="profile.name ? profile.name[0] : ''">
                    </figure>
                </base-link>
            </section>
        </header>
    </div>
</template>

<script>
import { profile_utilities } from "@/nibnut/mixins"

import { AppLogo, DefaultButton, BaseLink, OpenIcon } from "@/nibnut/components"
import { MasterSearchBox } from "@/custom/components"

export default {
    mixins: [profile_utilities],
    components: {
        AppLogo,
        DefaultButton,
        BaseLink,
        OpenIcon,
        MasterSearchBox
    },
    methods: {
        bug_report () {
            this.$emit("bug-report")
        },
        login () {
            this.$store.dispatch("REQUEST_LOGIN", { panel_id: true })
        },
        logout () {
            this.$store.dispatch("LOGOUT")
        }
    },
    props: {
        isPublicPage: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.header {
    position: sticky;
    flex: 0 0 auto;
    top: 0;
    padding: $layout-spacing-lg;
    background-color: $brand-color;
    z-index: $zindex-2 + 1;

    .h4 {
        color: white;
        font-family: $mono-font-family;
        font-weight: normal;
    }

    img.app-logo {
        height: 1.6rem;
    }
    .avatar {
        margin-top: -0.4rem;
    }
    .btn.btn-link {
        background: transparent;
        border: 0;
        color: $light-color;
        &:focus,
        &:active,
        &.active {
            color: $primary-color;
        }
        @include hover-supported {
            color: $primary-color;
        }
        i {
            font-size: 1rem;
        }
    }
}
</style>
