import { mapGetters } from "vuex"

import { addl_misc_utilities } from "@/custom/mixins"

const handles_person_data = {
    mixins: [addl_misc_utilities],
    methods: {
        contact_numbers_slugify (type, value) {
            if(!value) return value
            if(type === "TYPE_PHONE") return value.replace(/[^0-9]+/g, "")
            return value.trim()
        },
        contact_numbers_by_type (person, type) {
            const type_id = this.constants("contact_number_types", type).id
            const data_source = this.entity_records("contact_number").concat(person._contact_number || [])
            return data_source.filter(details_record => {
                return (details_record.type === type_id) && (details_record.owner_type === "App\\Person") && (details_record.owner_id === person.id)
            })
        },
        person_addresses (person) {
            if(!person) return []
            const data_source = this.entity_records("person_address").concat(person._person_address || [])
            return data_source.filter(address => {
                return (address.person_id === person.id) || ((address.owner_type === "App\\Person") && (address.owner_id === person.id))
            })
        },
        person_relationships (person) {
            if(!person) return []
            const data_source = this.entity_records("person_relationship").concat(person._person_relationship || [])
            return data_source.filter(relationship => {
                return (relationship.person_A_id === person.id) || (relationship.person_B_id === person.id)
            })
        },
        relation_to_as_prefix (person_relation, person) {
            return (person_relation.person_A_id === person.id) ? "person_B" : "person_A"
        },
        relation_to (person_relation, person) {
            const prefix = this.relation_to_as_prefix(person_relation, person)
            const field = `${prefix}_id`
            return this.entity_record("person", person_relation[field]) || {}
        },
        is_record_of_type (record, type) {
            return !!record && (record.type === this.constants("contact_number_types", type).id)
        }
    },
    computed: {
        ...mapGetters(["entity_records", "entity_record"])
    }
}

export default handles_person_data
