<template>
    <th
        :class="{ 'tooltip': !!hint, 'tooltip-top': !!hint}"
        :data-tooltip="hint"
        @mouseover="hovered=true"
        @mouseout="hovered=false"
    >
        <default-button
            flavor="link"
            :title="translate('Click for single-column sort, Shift-click for multi-column sort')"
            @click.exact.stop="click(false)"
            @click.shift.exact.prevent.stop="click(true)"
        >
            {{ label }}
            <open-icon
                v-if="sort !== false"
                :glyph="glyph"
                :class="{ 'active': !!sort, 'd-invisible': !sort && !hovered, 'text-gray': !sort && !hovered }"
                class="print-hide"
            />
        </default-button>
        <label v-if="!!hint" class="form-label d-inline-block">
            <open-icon
                glyph="question-circle"
            />
        </label>
    </th>
</template>

<script type="text/javascript">
import DefaultButton from "@/nibnut/components/Buttons/DefaultButton"
import OpenIcon from "@/nibnut/components/OpenIcon"

export default {
    components: {
        DefaultButton,
        OpenIcon
    },
    methods: {
        click (multiple = false) {
            this.$emit("click", this.field, (this.sort === "desc") ? "asc" : "desc", multiple)
        }
    },
    computed: {
        glyph () {
            if(this.hovered) {
                if((this.type === "amount") && this.sort && this.sort.match(/^desc/i)) return `sort-${this.type}-down-alt`
                if(this.type === "amount") return `sort-${this.type}-down`
                if(this.sort && this.sort.match(/^desc/i)) return `sort-${this.type}-down`
                return `sort-${this.type}-down-alt`
            }
            if((this.type === "amount") && this.sort && this.sort.match(/^desc/i)) return `sort-${this.type}-down`
            if(this.type === "amount") return `sort-${this.type}-down-alt`
            if(this.sort && this.sort.match(/^desc/i)) return `sort-${this.type}-down-alt`
            return `sort-${this.type}-down`
        }
    },
    props: {
        field: {
            type: String,
            required: true
        },
        label: {
            type: String,
            required: true
        },
        hint: {
            type: String,
            default: ""
        },
        sort: {
            validator: prop => !prop || prop.match(/^(asc|desc)$/),
            required: true
        },
        type: {
            type: String,
            validator: prop => !!prop && prop.match(/^(amount|numeric|alpha)$/),
            deafult: "amount"
        },
        compact: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            hovered: false
        }
    }
}
</script>
