<template>
    <div
        class="card card-task"
        @click.prevent.stop
    >
        <div class="card-header">
            <div class="card-title px-2 pt-1">
                <div class="columns d-flex-no-wrap py-0">
                    <form-toggle-input
                        :id="`deleted_at-${task.id}`"
                        name="deleted_at"
                        type="checkbox"
                        :value="!!task.deleted_at"
                        :required="false"
                        size="sm"
                        class="column text-bold"
                        @input="toggle"
                        @click.native.stop
                    >
                        {{ task.description || task_action_by_id(task.task_action_id).name }}
                    </form-toggle-input>
                    <div class="column col-auto text-center">
                        <help-button
                            v-if="!!task_action_by_id(task.task_action_id).name"
                            size="sm"
                            class="popover-bottom float-right"
                        >
                            <div class="p-2">{{ task_action_by_id(task.task_action_id).name }}</div>
                        </help-button>
                        <div v-if="task.is_crucial">
                            <open-icon glyph="exclamation" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-subtitle text-small px-2 pb-2">
                <div class="columns">
                    <div class="column">
                        <open-icon glyph="clock" class="mr-1" />
                        {{ (task.deleted_at ? task.deleted_at : task.scheduled_at) | nibnut.date(dateFormat, "", true) }}
                    </div>
                    <div v-if="!!notes.length" class="column col-auto">
                        <default-button
                            flavor="link"
                            size="sm"
                            :title="translate('Has notes - click to view notes...')"
                            @click.prevent.stop="$emit('edit', task, 'notes')"
                        >
                            <open-icon
                                glyph="sticky-note"
                            />
                        </default-button>
                    </div>
                    <div class="column col-auto">
                        <default-button
                            flavor="link"
                            size="sm"
                            @click.prevent.stop="$emit('edit', task)"
                        >
                            <open-icon glyph="pencil-alt" />
                        </default-button>
                    </div>
                </div>
            </div>
            <div v-if="displayProperty" class="card-subtitle text-small px-2 pb-2">
                <base-link
                    :href="{ name: 'property.edit', params: { id: task.property_uuid } }"
                    @click.native.stop
                >
                    {{ task.property_name }}
                </base-link>
            </div>
            <div v-if="!!task.contact_name" class="card-subtitle text-small px-2 pb-2">
                <base-link
                    v-if="$route.name === 'property.edit'"
                    :href="{ name: 'property.edit', params: { id: task.property_uuid, person_id: task.contact_id } }"
                    @click.native.stop="$emit('contact', task.contact_id)"
                >
                    {{ task.contact_name }}
                </base-link>
                <base-link
                    v-else
                    :href="{ name: 'property.edit', params: { id: task.property_uuid, person_id: task.contact_id } }"
                    @click.native.stop
                >
                    {{ task.contact_name }}
                </base-link>
            </div>
        </div>
        <div
            v-if="deletable"
            class="card-footer"
        >
            <figure
                v-if="displayUser && !confirming"
                :title="task.name"
                :data-initial="task.computed_initials"
                :style="{ borderColor: task.color, backgroundColor: is_assigned_to_team ? 'white' : task.color, color: is_assigned_to_team ? task.color : color_for_background(task.color) }"
                class="avatar"
            ></figure>
            <div
                v-if="confirming"
                class="columns"
            >
                <div class="column">
                    <label class="form-label label-sm text-error text-right">
                        {{ translate("Are you sure?") }}
                    </label>
                </div>
                <div class="column col-auto">
                    <default-button
                        color="success"
                        size="sm"
                        @click.prevent.stop="confirming = false"
                    >
                        {{ translate("No") }}
                    </default-button>
                    <default-button
                        color="error"
                        size="sm"
                        @click.prevent.stop="remove"
                    >
                        {{ translate("Yes") }}
                    </default-button>
                </div>
            </div>
            <default-button
                v-else
                flavor="link"
                color="error"
                size="sm"
                :block="true"
                @click.prevent.stop="confirm_remove"
            >
                {{ translate("Delete") }}
            </default-button>
        </div>
    </div>
</template>

<script>
import profile_utilities from "@/nibnut/mixins/ProfileUtilities"
import ui_utilities from "@/nibnut/mixins/UiUtilities"
import addl_misc_utilities from "@/custom/mixins/AddlMiscUtilities"

import FormToggleInput from "@/nibnut/components/Inputs/FormToggleInput"
import DefaultButton from "@/nibnut/components/Buttons/DefaultButton"
import HelpButton from "@/nibnut/components/Buttons/HelpButton"
import BaseLink from "@/nibnut/components/Links/BaseLink"
import OpenIcon from "@/nibnut/components/OpenIcon"

export default {
    name: "TaskCard",
    mixins: [profile_utilities, ui_utilities, addl_misc_utilities],
    components: {
        FormToggleInput,
        DefaultButton,
        HelpButton,
        BaseLink,
        OpenIcon
    },
    methods: {
        toggle () {
            if(!this.task) return
            if(this.task.deleted_at) this.$emit("unarchive", this.task)
            else this.$emit("archive", this.task)
        },
        confirm_remove () {
            this.confirming = true
        },
        remove () {
            this.$emit("delete", this.task)
        }
    },
    computed: {
        is_assigned_to_team () {
            return !!this.task && (this.task.assigned_to_type === "App\\Team")
        },
        notes () {
            if(!this.task || !this.task.id) return []
            const notes = this.entity_records("note").filter(note => {
                return (note.topic_type === "App\\Task") && (note.topic_id === this.task.id)
            })
            return notes
        },
        deletable () {
            if(!this.task || !this.profile_id) return false
            if(this.is_at_least_administrator) return true
            return ((this.task.assigned_to_type === "App\\User") && (this.profile.id === this.task.assigned_to_id)) || ((this.task.assigned_to_type === "App\\Team") && (this.profile.team_ids.indexOf(this.task.assigned_to_id) >= 0))
        }
    },
    props: {
        task: {
            type: Object,
            required: true
        },
        displayProperty: {
            type: Boolean,
            default: true
        },
        displayUser: {
            type: Boolean,
            default: true
        },
        dateFormat: {
            type: String,
            default: "h:mm A"
        }
    },
    data () {
        return {
            confirming: false
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.card.card-task {
    border-top: 0;
    border-left: 0;
    border-right: 0;

    .card-header,
    .card-body,
    .card-footer {
        &, &:last-child {
            padding: 0;
        }

        .btn.btn-link {
            height: auto;
            padding-top: 0;
            padding-bottom: 0;
            line-height: normal;
            border: 0;
        }
    }
    .card-footer {
        position: relative;
        margin-top: $unit-3;
        .avatar {
            position: absolute;
            left: 0;
            bottom: 0;
            border-top: 1px solid transparent;
            border-right: 1px solid transparent;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            font-weight: bold;
        }
    }
}
</style>
