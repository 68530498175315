const is_alpha_numerical_input = {
    methods: {
        touchstart () {
            if(this.alphanumeric && this.iOS) this.touching = true
        },
        keydown () {
            if(this.alphanumeric && this.iOS) this.touching = false
        },
        blur () {
            if(this.alphanumeric && this.iOS) this.touching = false
        }
    },
    computed: {
        iOS () {
            return !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform)
        },
        is_safari () {
            return !!navigator.vendor && /Apple/.test(navigator.vendor)
        },
        alphanumeric () {
            return false
        },
        live_type () {
            if(this.alphanumeric && this.iOS) return "number"
            return this.type
        }
    },
    data () {
        return {
            touching: false
        }
    }
}

export default is_alpha_numerical_input
