import Vue from "vue"
import VueRouter from "vue-router"
import routes from "./routes"

Vue.use(VueRouter)

// Setup vue-router
const router = new VueRouter({
    mode: "history",
    base: "/",
    routes,
    scrollBehavior: (to, from, savedPosition) => {
        if(!!to && !!to.meta && !!to.meta.scroll_position) {
            to.meta.scroll_position = savedPosition
            return savedPosition
        }
        return { x: 0, y: 0 }
    }
})

router._router_history = []
router._popping = false
window.addEventListener("popstate", () => {
    router._popping = true
})
router.beforeEach((to, from, next) => {
    // console.log(router._popping, from.path, to, router._router_history, router._router_history.findIndex(entry => entry.path === to.path))

    // router._router_history[0] is always the LATEST page
    /*
    const index = router._router_history.findIndex(entry => entry.path === to.path)
    if(index >= 0) router._router_history.splice(index + 1)
    else router._router_history.push({ title: to.name, path: to.path })
    */
    /*
    /properties -> /properties/slug-1 -> <back> -> /properties/slug-1
    ['/properties'] -> ['/properties', '/properties/slug-1'] -> ['/properties'] -> ['/properties', '/properties/slug-1']

    /properties/slug-1 -> /properties -> /properties/slug-1
    ['/properties/slug-1'] -> ['/properties/slug-1', '/properties'] -> ['/properties/slug-1', '/properties', '/properties/slug-1']

    /properties/slug-1 -> /properties -> <back> -> /properties
    ['/properties/slug-1'] -> ['/properties/slug-1', '/properties'] -> ['/properties/slug-1'] -> ['/properties/slug-1', '/properties']
    */
    if(router._popping) {
        const index = router._router_history.findIndex(entry => entry.path === to.path)
        if(index >= 0) router._router_history.splice(index + 1)
    } else if((router._router_history.length <= 1) || (router._router_history[router._router_history.length - 2].path !== to.path)) router._router_history.push({ title: to.name, path: to.path })

    // console.log("==>", router._router_history)
    router._popping = false
    next()
})

export default router
