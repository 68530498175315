<template>
    <modal-dialog
        v-if="record"
        id="task-editor-dialog"
        :show.sync="shown"
    >
        <template v-slot:title>
            <div class="d-flex align-items-center h5">
                <div class="flex-static">
                    <span v-if="taskListName">{{ taskListName }}&nbsp;-&nbsp;</span>{{ translate("Task for") }}
                </div>
                <assignment-input
                    :value="record"
                    :required="true"
                    :editable="is_at_least_administrator"
                    size="sm"
                    class="flex-variable ml-4"
                    @input="save"
                    @loaded="set_initial_assignment"
                />
                <form-toggle-input
                    v-if="!record.id && ((record.assigned_to_type !== 'App\\User') || (record.assigned_to_id !== profile.id))"
                    id="_no_creation_notification"
                    name="_no_creation_notification"
                    type="checkbox"
                    :value="record._no_creation_notification"
                    :required="false"
                    size="sm"
                    class="flex-static ml-4"
                    @input="save"
                >
                    {{ translate("Notify") }}
                </form-toggle-input>
            </div>
        </template>
        <div class="columns">
            <form-input
                id="description"
                name="description"
                :value="record.description"
                :required="!!taskListName"
                class="column"
                @input="save"
            >
                <template v-slot:label>
                    {{ translate("Description") }}
                </template>
            </form-input>
            <form-toggle-input
                id="is_crucial"
                name="is_crucial"
                type="checkbox"
                :value="record.is_crucial"
                :required="false"
                :horizontal="true"
                class="column col-auto"
                @input="save"
            >
                <open-icon glyph="exclamation" class="mr-2" />
                {{ translate("Important") }}
            </form-toggle-input>
        </div>
        <div class="columns">
            <div v-if="taskListName" class="column">
                <div class="columns d-flex-no-wrap">
                    <form-input
                        id="delta_days"
                        name="delta_days"
                        type="number"
                        :value="record.delta_days"
                        :min="0"
                        :required="false"
                        class="column flex-variable"
                        @input="save"
                    >
                        <template v-slot:label>
                            {{ translate("Due Date") }}
                        </template>
                        <template v-slot:right_addon>
                            <span class="input-group-addon">{{ translate("day(s) after") }}</span>
                            <base-dropdown
                                id="starting_date_field"
                                name="starting_date_field"
                                :value="record.starting_date_field"
                                :options="starting_date_fields"
                                :required="false"
                                @change="save($event.target.value, 'starting_date_field')"
                            />
                        </template>
                    </form-input>
                    <form-input
                        id="repeat_count"
                        name="repeat_count"
                        type="number"
                        :value="record.repeat_count"
                        :min="0"
                        :required="false"
                        class="column flex-variable"
                        @input="save"
                    >
                        <template v-slot:label>
                            {{ translate("Repeat") }}
                        </template>
                        <template v-slot:right_addon>
                            <span class="input-group-addon">{{ translate("times") }}</span>
                        </template>
                    </form-input>
                    <form-toggle-input
                        id="unmovable"
                        name="unmovable"
                        type="checkbox"
                        :value="record.unmovable"
                        :required="false"
                        :horizontal="true"
                        class="column flex-static"
                        @input="save"
                    >
                        {{ translate("No Re-Sched.") }}
                    </form-toggle-input>
                </div>
            </div>
            <form-date-time-input
                v-else
                id="scheduled_at"
                name="scheduled_at"
                :value="record.scheduled_at"
                position="bottom"
                calendar-size="sm"
                align="right"
                :required="true"
                :disabled="!is_at_least_administrator && record.unmovable"
                class="column"
                @input="save"
            >
                <template v-slot:label>
                    {{ translate("Due Date") }}
                </template>
            </form-date-time-input>
        </div>
        <div class="columns">
            <form-dropdown
                v-if="taskListName"
                id="topic"
                name="topic"
                :value="record.topic"
                :options="topics"
                :required="true"
                class="column col-auto"
                @input="save"
            >
                <template v-slot:label>
                    {{ translate("Topic") }}
                </template>
            </form-dropdown>
            <div class="column">
                <task-type-input
                    id="task"
                    :task="record"
                    @input="save"
                />
            </div>
        </div>
        <div v-if="!taskListName" class="columns">
            <form-select
                id="property_id"
                name="property_id"
                :value="record.property_id"
                data-source="property"
                :show-all="false"
                :ad-hoc="false"
                :required="false"
                :disabled="!!record.id || !!propertyId"
                class="column"
                @input="save"
            >
                <template v-slot:label>{{ translate("Property") }}</template>
            </form-select>
            <form-dropdown
                v-if="!!contact_ids.length"
                id="contact_id"
                name="contact_id"
                :value="record.contact_id"
                :options="contacts"
                :empty-label="translate('None')"
                :required="false"
                class="column col-auto"
                @input="save"
            >
                <template v-slot:label>{{ translate("Contact") }}</template>
            </form-dropdown>
        </div>
        <div
            v-if="!taskListName && (!has_notes || !has_urls)"
            class="divider hr text-left text-small my-4"
        >
            {{ translate("Add") }}:
            <default-button
                v-if="!has_notes"
                flavor="link"
                size="sm"
                @click.prevent="add_note"
            >
                {{ translate("Notes") }}
            </default-button>
            <default-button
                v-if="!has_urls"
                flavor="link"
                size="sm"
                @click.prevent="add_url"
            >
                {{ translate("URLs") }}
            </default-button>
        </div>
        <div v-if="has_notes">
            <h6
                id="task-editor-notes"
            >
                {{ translate("Notes") }}
                <default-button
                    v-if="!record.id"
                    flavor="link"
                    color="error"
                    @click.prevent.stop="shell_note = null"
                >
                    <open-icon glyph="times" />
                </default-button>
                <default-button
                    v-else
                    flavor="link"
                    @click.prevent.stop="add_note"
                >
                    <open-icon glyph="plus-circle" />
                </default-button>
            </h6>
            <ul>
                <li
                    v-for="note in notes"
                    :key="note.id"
                >
                    <note-input
                        :note="note"
                        :size="note.id ? 'sm' : 'md'"
                        :hide-controls="!record.id"
                    />
                </li>
            </ul>
        </div>
        <div v-if="has_urls">
            <h6>
                {{ translate("URLs") }}
                <default-button
                    flavor="link"
                    @click.prevent.stop="add_url"
                >
                    <open-icon glyph="plus-circle" />
                </default-button>
            </h6>
            <ul>
                <li
                    v-for="(url, index) in record.urls"
                    :key="index"
                >
                    <form-input
                        :id="`url-${index}`"
                        name="url"
                        :value="url"
                        :required="false"
                        @save="$set(record.urls, index, $event)"
                    >
                        <template v-slot:right_addon>
                            <default-button
                                color="error"
                                class="input-group-btn"
                                @click.prevent.stop="remove_url_at_index(index)"
                            >
                                <open-icon glyph="times" />
                            </default-button>
                        </template>
                    </form-input>
                </li>
            </ul>
        </div>
        <task-reminders-input
            :value="record.reminders"
            :root-date="record.scheduled_at"
            class="mt-4"
            @input="save"
        />

        <template v-slot:footer>
            <default-button
                v-if="!record.id"
                :disabled="creating"
                class="mx-1"
                @click.prevent="shown = false"
            >
                {{ translate("Cancel") }}
            </default-button>
            <default-button
                v-if="!record.id"
                :disabled="creating"
                :waiting="creating"
                class="mx-1"
                @click.prevent.stop="create"
            >
                {{ translate("Create") }}
            </default-button>
        </template>
    </modal-dialog>
</template>

<script>
import orderBy from "lodash/orderBy"

import is_record_editor from "@/nibnut/mixins/IsRecordEditor"

import ModalDialog from "@/nibnut/components/ModalDialog/ModalDialog"
import FormDateTimeInput from "@/nibnut/components/Inputs/FormDateTimeInput"
import FormToggleInput from "@/nibnut/components/Inputs/FormToggleInput"
import FormSelect from "@/nibnut/components/Inputs/FormSelect"
import FormDropdown from "@/nibnut/components/Inputs/FormDropdown"
import FormInput from "@/nibnut/components/Inputs/FormInput"
import BaseDropdown from "@/nibnut/components/Inputs/BaseDropdown"
import DefaultButton from "@/nibnut/components/Buttons/DefaultButton"
import OpenIcon from "@/nibnut/components/OpenIcon"
import TaskTypeInput from "@/custom/components/TaskTypeInput"
import TaskRemindersInput from "@/custom/components/TaskRemindersInput"
import AssignmentInput from "@/custom/components/AssignmentInput"
import NoteInput from "@/custom/components/NoteInput"

const starting_date_fields = [
    { id: "created_at", name: "File Creation" },
    { id: "now", name: "Assignment" }
]
const topics = [
    { id: "property_id", name: "File" },
    { id: "contact_id", name: "Contact" }
]

export default {
    name: "TaskEditorDialog",
    mixins: [is_record_editor],
    components: {
        ModalDialog,
        FormDateTimeInput,
        FormToggleInput,
        FormSelect,
        FormDropdown,
        FormInput,
        BaseDropdown,
        DefaultButton,
        OpenIcon,
        TaskTypeInput,
        TaskRemindersInput,
        AssignmentInput,
        NoteInput
    },
    watch: {
        show: "reload",
        "record.property_id": "load_property_contacts"
    },
    methods: {
        grab_record_id () {
            this.load_property_contacts()
            this.record_id = this.record ? this.record.id : 0
            this.creating = false
        },
        post_load () {
            if(this.scrollTo) {
                const node = this.$el.querySelector(`#task-editor-${this.scrollTo}`)
                if(node) node.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" })
            }
        },
        load_property_contacts () {
            if(!!this.record && !!this.record.property_id) {
                this.$store.dispatch(
                    "FETCH_RECORDS",
                    {
                        entity: "person",
                        query: { property_id: this.record.property_id, fields: ["fieldset::picker"] }
                    }
                ).then(response => {
                    this.contact_ids = response.record_ids
                })
            }
        },
        add_note () {
            this.$store.dispatch(
                "FETCH_RECORD_SHELL",
                { entity: "note" }
            ).then(note => {
                note.topic_type = "App\\Task"
                note.topic_id = this.record.id
                this.shell_note = note
                setTimeout(() => {
                    const node = document.getElementById("content-null")
                    if(node) {
                        node.focus()
                        node.select()
                    }
                }, 250)
            }).catch(this.receive_error)
        },
        add_url () {
            if(!this.record.urls) this.$set(this.record, "urls", [])
            const index = this.record.urls.length
            this.$set(this.record.urls, index, "")
            setTimeout(() => {
                const node = document.getElementById(`url-${index}`)
                if(node) {
                    node.focus()
                    node.select()
                }
            }, 250)
        },
        remove_url_at_index (index) {
            const urls = [...this.record.urls]
            urls.splice(index, 1)
            this.save(urls, "urls")
        },
        save (value, field) {
            if(this.record) {
                if(this.taskListName) {
                    this.$emit("input", value, field)
                    return
                }
                if(field === "assigned_to") {
                    Object.keys(value).forEach(field => {
                        if(this.record[field] !== value[field]) this.$set(this.record, field, value[field])
                    })
                    if(this.record.id) {
                        const data = {
                            ...this.record,
                            fields: ["ns::task;fieldset::list"]
                        }
                        return this.save_data_for_record_id(this.entity, this.record.id, data)
                    }
                } else if(this.record[field] !== value) {
                    this.$set(this.record, field, value)
                    if(this.record.id) {
                        const data = {
                            [field]: this.record[field],
                            fields: ["ns::task;fieldset::list"]
                        }
                        return this.save_data_for_record_id(this.entity, this.record.id, data)
                    }
                }
            }
            return Promise.resolve()
        },
        create () {
            if(this.record && !this.record.id) {
                if(this.taskListName) {
                    this.$emit("create", this.record)
                } else {
                    const wrapup = (task) => {
                        this.$emit("created", task)
                        this.shown = false
                    }
                    const data = {
                        ...this.record,
                        fields: ["ns::task;fieldset::list"]
                    }
                    data.scheduled_at = this.standardize_field_save_value(data.scheduled_at, "scheduled_at")
                    return this.save_data_for_record_id(this.entity, 0, data).then(task => {
                        if(!this.shell_note) wrapup(task)
                        else {
                            this.shell_note.topic_id = task.id
                            this.save_data_for_record_id("note", 0, this.shell_note).then(() => {
                                wrapup(task)
                            })
                        }
                    })
                }
            }
        },
        set_initial_assignment (option) {
            if(!this.record.id && (!this.record.assigned_to_type || !this.record.assigned_to_id)) {
                this.$set(this.record, "assigned_to_type", option.data.assigned_to_type)
                this.$set(this.record, "assigned_to_id", option.data.assigned_to_id)
            }
        }
    },
    computed: {
        shown: {
            get () {
                return this.show
            },
            set (show) {
                this.$emit("update:show", show)
            }
        },
        can_reload () {
            return !!this.show && !!this.record && !!this.record.id && !this.taskListName
        },
        cleanup_before_reload () {
            return false
        },
        holding_mode () {
            return !this.record || !this.record.id
        },
        starting_date_fields () {
            return starting_date_fields
        },
        topics () {
            return topics
        },
        contacts () {
            if(!this.record || !this.record.property_id) return []
            const contacts = this.entity_records("person", this.contact_ids)
            return [
                {
                    name: this.translate("Main Contacts"),
                    options: orderBy(contacts.filter(contact => !!contact.is_of_interest), "name", "asc")
                },
                {
                    name: this.translate("Other Contacts"),
                    options: orderBy(contacts.filter(contact => !contact.is_of_interest), "name", "asc")
                }
            ]
        },
        notes () {
            if(!this.record) return []
            if(!this.record.id) return this.shell_note ? [this.shell_note] : []
            const notes = this.entity_records("note").filter(note => {
                return (note.topic_type === "App\\Task") && (note.topic_id === this.record.id)
            })
            if(this.shell_note) notes.unshift(this.shell_note)
            return notes
        },
        has_notes () {
            return !!this.notes.length
        },
        has_urls () {
            return !!this.record && !!this.record.urls && !!this.record.urls.length
        }
    },
    props: {
        record: {
            thpe: Object,
            required: true
        },
        propertyId: {
            type: Number,
            default: 0
        },
        scrollTo: {
            type: String,
            default: ""
        },
        show: {
            type: Boolean,
            default: false
        },
        taskListName: {
            type: String,
            default: null
        }
    },
    data () {
        return {
            entity: "task",
            relation_ids: ["note"],
            creating: false,
            contact_ids: [],
            shell_note: null
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

#task-editor-dialog {
    z-index: $zindex-5 + 50;
    .modal-header .btn.btn-clear.float-right {
        margin-top: 0.2rem;
    }
    .reminders {
        & > div + div {
            margin-top: $unit-2;
        }
    }
    input#repeat_count {
        width: 50px;
    }
}
</style>
