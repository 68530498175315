<template>
    <verte
        v-bind="$props"
        v-on="$listeners"
    ></verte>
</template>

<script>
import Verte from "verte"
import "verte/dist/verte.css"

export default {
    name: "ColorPicker",
    components: {
        Verte
    },
    props: {
        ...Verte.props
    }
}
</script>
