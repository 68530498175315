<template>
    <div
        :id="id"
        class="tasks"
    >
        <div class="columns">
            <h5 class="column">
                {{ translate('Tasks') }}
                <default-button
                    v-if="showAddButton"
                    size="xs"
                    class="s-circle ml-2"
                    @click.prevent.stop="add_for_date($dayjs())"
                >
                    <open-icon glyph="plus" size="1x" />
                </default-button>
            </h5>
        </div>
        <div class="columns">
            <assignment-input
                :value="state"
                :required="false"
                size="sm"
                class="column col-auto"
                @input="filter_by_assignement"
            />
            <form-dropdown
                id="type"
                name="type"
                :value="state.type"
                :options="task_types"
                :empty-label="translate('All Types')"
                :required="false"
                size="sm"
                class="column col-auto"
                @input="filter_by_type"
            />
            <form-dropdown
                v-if="!!state.type"
                id="action"
                name="action"
                :value="state.action"
                :options="task_actions_for_type(state.type)"
                :empty-label="translate('All Actions')"
                :required="false"
                size="sm"
                class="column col-auto"
                @input="filter_by('action', $event)"
            />
            <form-toggle-input
                id="archived"
                name="archived"
                type="checkbox"
                :value="state.archived"
                :required="false"
                size="sm"
                class="column"
                @input="toggle_archived"
            >
                {{ $root.translate('Show Completed Tasks') }}
            </form-toggle-input>
            <div class="column col-auto col-ml-auto">
                <div class="btn-group">
                    <default-button
                        size="sm"
                        :active="state.view === VIEW_MONTH"
                        @click.prevent="set_view(VIEW_MONTH)"
                    >
                        {{ translate('Month') }}
                    </default-button>
                    <default-button
                        size="sm"
                        :active="state.view === VIEW_WEEK"
                        @click.prevent="set_view(VIEW_WEEK)"
                    >
                        {{ translate('Week') }}
                    </default-button>
                    <default-button
                        size="sm"
                        :active="state.view === VIEW_LIST"
                        @click.prevent="set_view(VIEW_LIST)"
                    >
                        {{ translate('List') }}
                    </default-button>
                </div>
            </div>
        </div>
        <ul
            v-if="state.view === VIEW_WEEK"
            class="week-view"
        >
            <li
                v-for="(date_container, index) in date_containers"
                :key="date_container.id"
                @click.prevent="add_for_date(date_container.date)"
            >
                <h6 class="bg-secondary">
                    <default-button
                        v-if="!index"
                        color="light"
                        class="view-prev"
                        @click.prevent.stop="previous"
                    >
                        <open-icon glyph="caret-left" />
                    </default-button>
                    <div class="text-center">
                        {{ date_container.date | nibnut.date("ddd")}}<br />
                        <small>{{ date_container.date | nibnut.date("MM/DD")}}</small>
                    </div>
                    <default-button
                        v-if="index === (date_containers.length - 1)"
                        color="light"
                        class="view-next"
                        @click.prevent.stop="next"
                    >
                        <open-icon glyph="caret-right" />
                    </default-button>
                </h6>
                <div>
                    <task-card
                        v-for="task in tasks_for_date(date_container.date)"
                        :key="task.id"
                        :task="task"
                        :display-property="!propertyId"
                        :display-user="!userId"
                        @edit="edit_task"
                        @archive="archive_task"
                        @unarchive="unarchive_task"
                        @delete="delete_task"
                        @contact="$emit('contact', task.contact_id)"
                    />
                </div>
            </li>
        </ul>
        <agenda
            v-else-if="state.view === VIEW_MONTH"
            :items="rows"
            class="mt-4"
            @input="set_agenda_range"
            @click="add_for_date"
        >
            <template v-slot:item="{ record }">
                <div
                    class="chip"
                    @click.prevent.stop="zoom_in(record.scheduled_at)"
                >
                    <figure
                        :title="record.name"
                        :data-initial="record.computed_initials"
                        :style="{ backgroundColor: record.color, color: color_for_background(record.color) }"
                        class="avatar"
                    ></figure>
                    <abbr :title="record.description || task_action_by_id(record.task_action_id).name" class="text-truncated">{{ record.description || task_action_by_id(record.task_action_id).name }}</abbr><span class="ml-1">({{ record.scheduled_at | nibnut.date("h:mm A", "", true) }})</span>
                </div>
            </template>
        </agenda>
        <task-list
            v-else-if="state.view === VIEW_LIST"
            :provided-state="state"
            :tasks="rows"
            :show-property="false"
            class="mt-4"
            @add="add_for_date($dayjs())"
            @edit="edit_task"
            @sort="sort_by"
        />
        <task-editor-dialog
            :record="edited_record"
            :property-id="propertyId"
            :scroll-to="editor_scroll_to"
            :show.sync="editing"
            @created="refresh"
        />
    </div>
</template>

<script>
import is_remote_data_table_source from "@/nibnut/mixins/IsRemoteDataTableSource"
import handles_dates from "@/nibnut/mixins/HandlesDates"
import ui_utilities from "@/nibnut/mixins/UiUtilities"
import addl_misc_utilities from "@/custom/mixins/AddlMiscUtilities"

import FormDropdown from "@/nibnut/components/Inputs/FormDropdown"
import FormToggleInput from "@/nibnut/components/Inputs/FormToggleInput"
import DefaultButton from "@/nibnut/components/Buttons/DefaultButton"
import OpenIcon from "@/nibnut/components/OpenIcon"
import Agenda from "@/custom/components/Agenda"
import AssignmentInput from "@/custom/components/AssignmentInput"
import TaskEditorDialog from "@/custom/dialogs/TaskEditorDialog"
import TaskList from "./TaskList"
import TaskCard from "./TaskCard"

const VIEW_LIST = 0
const VIEW_WEEK = 1
const VIEW_MONTH = 2

export default {
    name: "Tasks",
    mixins: [is_remote_data_table_source, handles_dates, ui_utilities, addl_misc_utilities],
    components: {
        FormDropdown,
        FormToggleInput,
        DefaultButton,
        OpenIcon,
        Agenda,
        AssignmentInput,
        TaskEditorDialog,
        TaskList,
        TaskCard
    },
    created () {
        this.VIEW_LIST = VIEW_LIST
        this.VIEW_WEEK = VIEW_WEEK
        this.VIEW_MONTH = VIEW_MONTH
    },
    watch: {
        "createWithData.property_id": "add_for_data",
        "createWithData.contact_id": "add_for_data",
        dataVersion: "refresh"
    },
    methods: {
        set_view (view, refresh = true) {
            if(this.state.view !== view) {
                const today = this.$dayjs()
                let from = ""
                let to = ""
                switch (view) {
                case VIEW_WEEK:
                    from = today.startOf("week")
                    to = today.endOf("week")
                    break
                case VIEW_MONTH:
                    from = today.startOf("month")
                    to = today.endOf("month")
                    break
                }
                this.set_state_values({
                    view,
                    from: from ? from.format("YYYY-MM-DD") : "",
                    to: to ? to.format("YYYY-MM-DD") : ""
                })
                if(refresh) this.refresh()
            }
        },
        pre_load () {
            if(!this.users) {
                this.$store.dispatch(
                    "FETCH_RECORDS",
                    {
                        entity: "user",
                        query: { fields: ["fieldset::picker"] }
                    }
                ).then(response => {
                    this.users = this.entity_records("user", response.record_ids)
                }).catch(error => {
                    this.$error(error.message)
                })
            }
            if(this.propertyId) this.set_state_value("property_id", this.propertyId)
            if(this.userId) this.set_state_values({ assigned_to_type: "App\\User", assigned_to_id: this.userId })
            if(this.state.view < 0) this.set_view(this.defaultView, false)
        },
        filter_by_assignement (assignement) {
            if(!assignement) {
                if(this.userId) assignement = { assigned_to_type: "App\\User", assigned_to_id: this.userId }
                else assignement = { assigned_to_type: "", assigned_to_id: 0 }
            }
            this.set_state_values({
                ...assignement,
                page: 1
            })
            this.refresh()
        },
        filter_by_type (type_id) {
            this.filtered_by_selection = false
            if(this.state.type !== type_id) {
                this.set_state_values({
                    page: 1,
                    type: type_id,
                    action: 0
                })
                this.refresh()
            }
        },
        sort_by (sort_by, sort_dir) {
            this.set_state_values({
                sort_by,
                sort_dir
            })
            this.refresh()
        },
        move_range (operation) {
            const delta = (this.state.view === VIEW_MONTH) ? "month" : "week"
            this.set_state_values({
                from: this.$dayjs(this.state.from)[operation](1, delta).startOf(delta).format("YYYY-MM-DD"),
                to: this.$dayjs(this.state.to)[operation](1, delta).endOf(delta).format("YYYY-MM-DD")
            })
            this.refresh()
        },
        previous () {
            this.move_range("subtract")
        },
        next () {
            this.move_range("add")
        },
        tasks_for_date (date) {
            return this.rows.filter(task => {
                return this.$dayjs(task.scheduled_at).isSame(date, "day")
            })
        },
        set_agenda_range (range) {
            this.set_state_values(range)
            this.refresh()
        },
        edit_task (task, scroll_to = "") {
            this.editor_scroll_to = scroll_to
            this.edited_record = task
            this.editing = true
        },
        add_task (date, property_id = 0, contact_id = 0) {
            this.$store.dispatch(
                "FETCH_RECORD_SHELL",
                { entity: this.entity }
            ).then(shell_record => {
                shell_record.property_id = property_id || this.propertyId
                shell_record.contact_id = contact_id || 0
                shell_record.assigned_to_type = "App\\User"
                shell_record.assigned_to_id = this.userId || this.profile.id
                const now = this.$dayjs()
                shell_record.scheduled_at = this.ceil_date(date.hour(now.hour()).minute(now.minute()), 15)
                this.edit_task(shell_record)
            }).catch(error => {
                this.$error(error.message)
            })
        },
        add_for_date (date) {
            this.add_task(date)
        },
        add_for_data () {
            if(!this.createWithData.property_id) return
            this.add_task(this.$dayjs(), this.createWithData.property_id, this.createWithData.contact_id)
        },
        archive_task (task) {
            if(!task.deleted_at) {
                this.$store.dispatch(
                    "RECORD_DELETE",
                    {
                        entity: "task",
                        id: task.id
                    }
                ).then(() => {
                    this.refresh()
                }).catch(this.receive_error)
            }
        },
        unarchive_task (task) {
            if(task.deleted_at) {
                this.$store.dispatch(
                    "RECORD_RESTORE",
                    {
                        entity: "task",
                        id: task.id
                    }
                ).then(() => {
                    this.refresh()
                }).catch(this.receive_error)
            }
        },
        delete_task (task) {
            this.$store.dispatch(
                "RECORD_DELETE",
                {
                    entity: "task",
                    id: task.id,
                    data: { force: true }
                }
            ).then(() => {
                this.refresh()
            }).catch(this.receive_error)
        },
        zoom_in (date) {
            const from = this.$dayjs(date).startOf("week")
            this.set_state_values({
                from,
                to: from.endOf("week"),
                view: VIEW_WEEK
            })
            this.refresh()
        }
    },
    computed: {
        state_identifier () {
            return `${this.id}-tasks`
        },
        fields () {
            return ["fieldset::list"]
        },
        date_containers () {
            const date_containers = []

            const to = this.$dayjs(this.state.to)
            let date = this.$dayjs(this.state.from)
            while(date.isSameOrBefore(to)) {
                date_containers.push({
                    id: date.valueOf(),
                    date
                })
                date = date.add(1, "day")
            }

            return date_containers
        }
    },
    props: {
        id: {
            type: String,
            required: true
        },
        propertyId: {
            type: Number,
            default: 0
        },
        userId: {
            type: Number,
            default: 0
        },
        defaultView: {
            type: Number,
            default: VIEW_WEEK
        },
        createWithData: {
            type: Object,
            default () {
                return {}
            }
        },
        showAddButton: {
            type: Boolean,
            default: false
        },
        dataVersion: {
            type: Number,
            default: 0
        }
    },
    data () {
        return {
            entity: "task",
            default_state: {
                per_page: 0,
                page: 1,
                sort_by: ["scheduled_at"],
                sort_dir: ["asc"],
                filter_on: null,
                filter: null,
                archived: false,
                search: "",
                total: 0,
                found: 0,
                property_id: 0,
                assigned_to_type: "",
                assigned_to_id: 0,
                type: 0,
                action: 0,
                from: "",
                to: "",
                view: -1
            },
            users: [],
            editor_scroll_to: "",
            edited_record: {},
            editing: false
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.tasks {
    display: flex;
    flex-direction: column;

    ul.week-view {
        flex: 1 1 auto;
        display: flex;
        margin-bottom: 0;
        & > li {
            flex: 0 0 14.285714285714286%;
            border-right: $border-width solid $border-color-dark;

            &:last-child {
                border-right: 0;
            }

            & > h6 {
                position: relative;
                margin-bottom: 0;
                .btn {
                    position: absolute;
                    top: 0;
                    height: 100%;

                    &.view-prev {
                        left: 0;
                    }
                    &.view-next {
                        right: 0;
                    }
                }
            }
            & > div {
                max-height: 300px;
                overflow: auto;
            }
        }
    }
}
</style>
