import { mapGetters } from "vuex"

import addl_profile_utilities from "@/custom/mixins/AddlProfileUtilities"

const assignment_utilities = {
    mixins: [addl_profile_utilities],
    methods: {
        user_for_assignment (assignment) {
            return this.entity_record("user", assignment.user_id) || {}
        },
        assignment_topic_type_for_entity (entity) {
            // if(entity === "property") return "App\\Property"
            return `App\\${entity[0].toUpperCase() + entity.substring(1)}`
        },
        assignments_by_topic_type_and_id (topic_type, topic_id) {
            if(!topic_type || !topic_id) return []
            return this.entity_records("assignment").filter(assignment => {
                return (assignment.topic_type === topic_type) && (assignment.topic_id === topic_id)
            })
        },
        current_assignments_by_topic_type_and_id (topic_type, topic_id) {
            return this.assignments_by_topic_type_and_id(topic_type, topic_id).filter(assignment => !assignment.completed_at)
        },
        topic_assigned_to_me (topic) {
            return topic && !!topic.my_assignment
        },
        topic_editable (topic) {
            return !!topic && (this.topic_assigned_to_me(topic) || this.is_at_least_administrator)
        }
    },
    computed: {
        ...mapGetters(["entity_records", "entity_record"])
    }
}

export default assignment_utilities
