<template>
    <div class="columns">
        <form-dropdown
            :id="`${id}-task_type_id`"
            name="task_type_id"
            :value="task.task_type_id"
            :options="sorted_task_types"
            :editable="true"
            :required="true"
            class="column"
            @input="save"
        >
            <template v-slot:label>
                {{ translate("Type") }}
            </template>
        </form-dropdown>
        <form-dropdown
            v-if="task.task_type_id"
            :id="`${id}-task_action_id`"
            name="task_action_id"
            :value="task.task_action_id"
            :options="task_actions_for_type(task.task_type_id)"
            :editable="true"
            :required="true"
            class="column"
            @input="save"
        >
            <template v-slot:label>
                {{ translate("Action") }}
            </template>
        </form-dropdown>
    </div>
</template>

<script>
import addl_misc_utilities from "@/custom/mixins/AddlMiscUtilities"

import FormDropdown from "@/nibnut/components/Inputs/FormDropdown"

export default {
    name: "TaskTypeInput",
    mixins: [addl_misc_utilities],
    components: {
        FormDropdown
    },
    methods: {
        save (value, field) {
            this.$emit("input", value, field)
            if(field === "task_type_id") {
                const actions = this.task_actions_for_type(this.task.task_type_id)
                if(!actions.find(action => action.id === this.task.task_action_id)) this.$emit("input", actions[0].id, "task_action_id")
            }
        }
    },
    props: {
        id: {
            type: String,
            required: true
        },
        task: {
            type: Object,
            required: true
        }
    }
}
</script>
