<template>
    <div class="user-dashboard">
        <div class="columns align-items-center user-dashboard-header">
            <div class="column col-auto h1">
                <slot name="title">&nbsp;</slot>
            </div>
            <div class="column">
                <slot name="controls"></slot>
            </div>
            <div class="column col-auto no-wrap">
                <default-button
                    v-for="preset in presets"
                    :key="preset.days"
                    :flavor="(preset.days === state.days) ? 'normal' : 'link'"
                    :color="(preset.days === state.days) ? 'primary' : 'regular'"
                    size="sm"
                    class="ml-2"
                    @click.prevent.stop="set_range(preset.days)"
                >
                    {{ preset.title }}
                </default-button>
            </div>
        </div>

        <div v-if="!!statistics" class="columns justify-content-center">
            <div class="column col-3 col-md-4 col-sm-12">
                <kpi-card
                    :title="translate('Opportunities')"
                    :kpi="{ ...statistics.opportunities, categories: statistics._categories }"
                    :show-sparkline="has_sparkline_data"
                />
            </div>
            <div class="column col-3 col-md-4 col-sm-12">
                <kpi-card
                    :title="translate('Customers')"
                    :kpi="{ ...statistics.customers, categories: statistics._categories }"
                    :show-sparkline="has_sparkline_data"
                />
            </div>
            <div class="column col-3 col-md-4 col-sm-12">
                <kpi-card
                    :title="translate('Recovered')"
                    :kpi="{ ...statistics.recovered, categories: statistics._categories }"
                    :show-sparkline="has_sparkline_data"
                />
            </div>
        </div>

        <user-dashboard-properties-list
            v-if="is_beta_testing(profile, 'ASSIGNMENTS')"
            :user-id="userId"
            class="mt-8"
        />

        <slot name="message-board"></slot>

        <table v-if="!!statistics" class="table table-striped table-hover table-statistics mt-8">
            <thead>
                <tr>
                    <th class="statistic-cell-title">{{ translate("Property Status") }}</th>
                    <th
                        v-for="(header, index) in statistics._headers"
                        :key="header"
                        :class="{ [`statistic-cell-${slugify(header)}`]: true }"
                        @click.prevent="maybe_filter_stats(header, index)"
                    >
                        <a
                            v-if="header.match(/^week/i)"
                            href="#"
                            :class="{ active: (statistics._dates[index] === state.stats_date) }"
                            @click.prevent.stop="filter_stats_by_date(statistics._dates[index])"
                        >
                            {{ header }}
                        </a>
                        <form-dropdown
                            v-else-if="header === 'Month'"
                            id="month-picker"
                            name="month"
                            :value="$dayjs(statistics._dates[index]).month()"
                            :options="months"
                            :required="true"
                            @input="filter_stats_by_delta_date(statistics._dates[index], ...arguments)"
                        />
                        <form-dropdown
                            v-else-if="header === 'YTD'"
                            id="year-picker"
                            name="year"
                            :value="$dayjs(statistics._dates[index]).year()"
                            :options="years"
                            :required="true"
                            @input="filter_stats_by_delta_date(statistics._dates[index], ...arguments)"
                        />
                        <span v-else>{{ header }}</span>
                        <div v-if="!!statistics._dates[index] && !header.match(/^(month|ytd)/i)" class="text-small text-gray">{{ statistics._dates[index] | nibnut.date("MMM D", "", true) }}</div>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr
                    v-for="row in statistics.status.rows"
                    :key="row[0]"
                >
                    <td
                        v-for="(cell, index) in row"
                        :key="index"
                        :class="{ 'statistic-cell-title': !index, [`statistic-cell-${slugify(statistics._headers[index - 1])}`]: !!index, 'text-large': !!cell, 'text-gray': !cell }"
                    >
                        <span v-if="!index">{{ cell }}</span>
                        <span v-else>{{ cell | nibnut.number("0,0") }}</span>
                    </td>
                </tr>
            </tbody>
            <thead>
                <tr>
                    <th class="statistic-cell-title">{{ translate("Customer Touches") }}</th>
                    <th
                        v-for="header in statistics._headers"
                        :key="header"
                        :class="{ [`statistic-cell-${slugify(header)}`]: true }"
                    >
                        &nbsp;
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr
                    v-for="row in statistics.outreach.rows"
                    :key="row[0]"
                >
                    <td
                        v-for="(cell, index) in row"
                        :key="index"
                        :class="{ 'statistic-cell-title': !index, [`statistic-cell-${slugify(statistics._headers[index - 1])}`]: !!index, 'text-large': !!cell, 'text-gray': !cell }"
                    >
                        <span v-if="!index">{{ cell }}</span>
                        <span v-else>{{ cell | nibnut.number("0,0") }}</span>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script type="text/javascript">
import handles_stored_state from "@/nibnut/mixins/HandlesStoredState"
import profile_utilities from "@/nibnut/mixins/ProfileUtilities"
import misc_utilities from "@/nibnut/mixins/MiscUtilities"

import FormDropdown from "@/nibnut/components/Inputs/FormDropdown"
import DefaultButton from "@/nibnut/components/Buttons/DefaultButton"
import KpiCard from "@/custom/components/KpiCard"
import UserDashboardPropertiesList from "@/custom/components/UserDashboardPropertiesList"

export default {
    name: "UserDashboard",
    mixins: [handles_stored_state, profile_utilities, misc_utilities],
    components: {
        FormDropdown,
        DefaultButton,
        KpiCard,
        UserDashboardPropertiesList
    },
    mounted () {
        this.reload()
    },
    watch: {
        userId: "reload"
    },
    methods: {
        reload () {
            if(!this.state.stats_date) this.set_state_value("stats_date", this.$dayjs().startOf("week").weekday(1).format("YYYY-MM-DD", "", true))
            this.set_state_value("user_id", this.userId)
            this.$store.dispatch(
                "RECORDS_ACTION",
                {
                    entity: "user",
                    action: "statistics",
                    data: {
                        ...this.state
                    },
                    passthru: true
                }
            ).then(statistics => {
                this.statistics = statistics
            }).catch(this.receive_error)
        },
        set_range (days) {
            this.set_state_value("days", days)
            this.reload()
        },
        filter_stats_by_date (date) {
            this.set_state_value("stats_date", date)
            this.reload()
        },
        filter_stats_by_delta_date (date, value, field) {
            this.filter_stats_by_date(this.$dayjs(date)[field](value).format("YYYY-MM-DD", "", true))
        },
        maybe_filter_stats (header, index) {
            if(header.match(/^(week)/i)) this.filter_stats_by_date(this.statistics._dates[index])
        },
        slugify (value) {
            return this.nibnut_filter("nibnut.slugify", [value])
        }
    },
    computed: {
        state_identifier () {
            return `${this.$route.name}-user-dashboard`
        },
        date_format () {
            if(this.state.days === 7) return "ddd"
            return "MMM DD"
        },
        has_sparkline_data () {
            return !!this.statistics && (
                (!!this.statistics.opportunities && !!this.statistics.opportunities.series && !!this.statistics.opportunities.series.find(serie => !!serie._total)) ||
                (!!this.statistics.customers && !!this.statistics.customers.series && !!this.statistics.customers.series.find(serie => !!serie._total)) ||
                (!!this.statistics.recovered && !!this.statistics.recovered.series && !!this.statistics.recovered.series.find(serie => !!serie._total))
            )
        },
        months () {
            return [
                { id: 0, name: "Jan" },
                { id: 1, name: "Feb" },
                { id: 2, name: "Mar" },
                { id: 3, name: "Apr" },
                { id: 4, name: "May" },
                { id: 5, name: "Jun" },
                { id: 6, name: "Jul" },
                { id: 7, name: "Aug" },
                { id: 8, name: "Sep" },
                { id: 9, name: "Oct" },
                { id: 10, name: "Nov" },
                { id: 11, name: "Dec" }
            ]
        },
        years () {
            const max = this.$dayjs().year()
            const years = []
            for(let year = (max - 4); year <= max; year++) years.push({ id: year, name: year })
            return years
        }
    },
    props: {
        userId: {
            type: Number,
            default: 0
        }
    },
    data () {
        return {
            default_state: {
                user_id: 0,
                days: 7,
                stats_date: ""
            },
            presets: [
                { days: 7, title: this.translate("7 days") },
                { days: 30, title: this.translate("30 days") },
                { days: 180, title: this.translate("6 months") },
                { days: 365, title: this.translate("1 year") }
            ],
            statistics: null
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.user-dashboard {
    .user-dashboard-header {
        .h1 {
            margin: 0;
        }
        select {
            display: inline-block;
        }
    }
    .table-statistics {
        th, td {
            text-align: center;

            &.statistic-cell-title {
                text-align: left;
            }
            &.statistic-cell-monday, &.statistic-cell-week-1 {
                border-left: $border-width solid $border-color;
            }
        }
    }
}
</style>
