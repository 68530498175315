import { mapState } from "vuex"
import orderBy from "lodash/orderBy"

const misc_utilities = {
    methods: {
        setting (id) {
            // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Optional_chaining#browser_compatibility
            if(this.app_context && this.app_context.settings) return this.app_context.settings[id]
            return null
        },
        constants (group, id = null) {
            // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Optional_chaining#browser_compatibility
            if(!!this.app_context && !!this.app_context.constants && !!this.app_context.constants[group]) {
                if((id === null) || (id === undefined)) return this.app_context.constants[group] || []
                if(this.app_context.constants[group][id]) return this.app_context.constants[group][id]
                return Object.values(this.app_context.constants[group]).find(constant => constant.id === id) || {}
            }
            return {}
        },
        constant_type (group, id) {
            return Object.keys(this.app_context.constants[group]).find(type => this.app_context.constants[group][type].id === id)
        },
        constant_value (group, id) {
            return Object.values(this.app_context.constants[group]).find(value => value.id === id)
        },
        constant_options (group, sort_by = "", sort_dir = "asc") {
            let constant_options = Object.values(this.constants(group))
            if(sort_by) constant_options = orderBy(constant_options, sort_by, sort_dir || "asc")
            return constant_options
        },
        is_beta_testing (record_or_active_beta_tests, beta_test_id) {
            const source = record_or_active_beta_tests ? (record_or_active_beta_tests.active_beta_tests || record_or_active_beta_tests) : []
            return !!source && Array.isArray(source) && (source.indexOf(beta_test_id) >= 0)
        }
    },
    computed: {
        ...mapState(["app_context"])
    }
}

export default misc_utilities
