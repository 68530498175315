<template>
    <div class="card card-kpi">
        <div v-if="!!title" class="card-header">
            <div class="card-title h6 text-gray text-uppercase">{{ title }}</div>
        </div>
        <div class="card-body h2">
            {{ kpi.total / 100 | nibnut.currency }}
        </div>
        <div v-if="showSparkline" class="card-footer">
            <apexchart
                type="area"
                :height="70"
                :options="chart_options"
                :series="kpi.series"
            />
        </div>
    </div>
</template>

<script>
import displays_chart from "@/nibnut/mixins/DisplaysChart"

export default {
    name: "KpiCard",
    mixins: [displays_chart],
    mounted () {
        const options = {
            ...this.CHART_BASE_OPTIONS
        }
        options.chart = {
            ...options.chart,
            id: this.id,
            type: "area",
            sparkline: {
                enabled: true
            }
        }
        options.grid = {
            show: false
        }
        options.xaxis = {
            categories: this.kpi ? this.kpi.categories : []
        }
        options.yaxis = {
            show: false
        }
        options.tooltip.y = {
            formatter: (value) => {
                return this.nibnut_filter("nibnut.currency", [value / 100])
            }
        }
        options.legend = {
            show: false
        }
        this.chart_options = options
    },
    watch: {
        categories: "refresh_chart"
    },
    methods: {
        refresh_chart () {
            const chart_options = { ...this.chart_options }
            chart_options.xaxis.categories = this.kpi.categories
            this.chart_options = chart_options
        }
    },
    props: {
        title: {
            type: String,
            default: null
        },
        kpi: {
            type: Object, // { total: 0, categories: [], series: [] }
            required: true
        },
        showSparkline: {
            type: Boolean,
            default: true
        }
    },
    data () {
        return {
            chart_options: {}
        }
    }
}
</script>

<style lang="scss">
.card.card-kpi {
    .card-footer {
        &, &:last-child {
            padding-left: 0;
            padding-right: 0;
            padding-bottom: 0;
        }
    }
}
</style>
